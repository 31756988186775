import React, { useRef } from "react";

import { Table } from "reactstrap";
import { NavLink } from "react-router-dom";
import Pagination from "./shared/pagination";
import UserService from "../services/UserService";
import createChannel from "../utils/createChannel";

export default function ErrorLogsTable() {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;
  // console.log('channel', channel.current.request)

  const [errorLogs, setErrorLogs] = React.useState([]);
  const [page, setPage] = React.useState(1);

  const service = new UserService(apiReq);

  function getErrorLogs(e) {
    // console.log('tansel ->',e)
    if (e) {
      e.preventDefault();
    }

    service.getErrorLogs(page).then((res) => {
      // console.log(res.data.data);
      setErrorLogs(res.data.data);
    });
  }

  React.useEffect(() => {
    getErrorLogs();
  }, [page]);
  return (
    <div>
      <Table striped>
        <thead>
          <tr>
            <th>Id</th>
            <th>Email</th>
            <th>Ad-Soyad</th>
            <th>Status Code</th>
            <th>Location</th>
            <th>Name</th>
            <th>Message</th>
            <th>Tarih</th>
          </tr>
        </thead>
        <tbody>
          {errorLogs.map((i) => {
            return (
              <tr>
                <th>{i.id}</th>
                <td>
                  {i.email ? (
                    <NavLink to={`/user-details/${i.user_info_id}`}>
                      <span style={{ color: "blue" }}>{i.email}</span>
                    </NavLink>
                  ) : (
                    "-"
                  )}
                </td>
                <td>{i.email ? `${i.isim} ${i.soyad}` : "-"}</td>
                <td>{i.status_code}</td>
                <td>{i.location}</td>
                <td>{i.error_name}</td>
                <td>{i.message}</td>
                <td>{new Date(i.created_at).toLocaleString()}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Pagination page={page} setPage={setPage} />
    </div>
  );
}
