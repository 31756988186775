import React from "react";
import AdminDashboardLayout from "../layouts/adminDashboard";
import Breadcrumb from "../components/shared/breadcrumb";

// import { Switch, Route, useRouteMatch } from "react-router-dom";

export default function Tags(props) {
  // React.useEffect(() => {
  //   if (localStorage.getItem("token") != null) {
  //     props.checkAdminAccess();
  //   }
  // }, []);
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Kullanıcı Hareketleri" />
        <div className="m-5 p-5 text-center">
          <h2>Abonelik İzleri</h2>
          <h2>Kullanıcı İzleri</h2>
          <h2>Kayıt İzleri</h2>
        </div>
      </AdminDashboardLayout>
    </div>
  );
}
