import { Button, Card, CardBody, Col, Form, FormGroup, Input, Row } from "reactstrap";

const CouponSearch = ({searchInput, handleChange, handleSubmit}) => {
    return (
        <Card>
            <CardBody>
                <div className="container my-3 pt-1 filtreler">
                    <div className="text-center">
                        <div className="px-3 mt-3">
                        <Form form onSubmit={handleSubmit} className="container mx-auto formMedia asinForm d-flex justify-content-center">
                            <Row form id="asinForm" className='text-white keyWordForm w-75'>
                                <Col className="inputForm col-md-8">
                                    <FormGroup>
                                        <Input
                                            onChange={handleChange}
                                            id="searchCoupon"
                                            className="form-control loginInput"
                                            type="search"
                                            placeholder="Kupon araması yapabilirsiniz.."
                                            name="search"
                                            value={searchInput}
                                            style={{width: '100%'}}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col className="inputForm col-md-2">
                                    <Button id="" type="submit" className="btn p-2 text-align-center register">
                                        Ara
                                    </Button>
                                </Col>
                                <Col className="inputForm col-md-2">
                                    <Button id="" type="submit" className="btn p-2 text-align-center register" onClick={handleSubmit}>
                                        Yenile
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}
export default CouponSearch;
