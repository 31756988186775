import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../components/header/header";
import AuthContext from "../context/AuthContext";

import {
  Input,
  Form,
  Button,
  FormGroup,
  Alert,
  FormFeedback,
  Label,
} from "reactstrap";

//import GoogleAuth from "../components/auth/GoogleAuth";

function Login() {
  const { loginLoading, login, error, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
    remember: false,
  });
  const [errorMsg, setErrorMsg] = useState({ email: "", password: "" });

  const handleBlur = (field) => (evt) => {
    setForm({ ...form, touched: { ...form.touched, [field]: true } });
  };
  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setForm({ ...form, [name]: value });
    if (name === "email") {
      value.split("").filter((x) => x === "@").length !== 1
        ? setErrorMsg({ ...errorMsg, email: "Hatalı mail" })
        : setErrorMsg({ ...errorMsg, email: "" });
    } else if (name === "password") {
      value.length < 5
        ? setErrorMsg({ ...errorMsg, password: "Hatalı şifre!" })
        : setErrorMsg({ ...errorMsg, password: "" });
    } else {
      setErrorMsg({ email: "", password: "" });
    }
  };

  const handle = () => {
    setForm({ ...form, remember: !form.remember });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    login(form);
  };

  useEffect(() => {
    console.log("login", user);
  }, []);
  return (
    <div className="loginPage">
      <Header isLoggedIn={false} theme="white"></Header>
      <div className="page m-auto text-center">
        <h5 className="opacity">Merhaba</h5>
        <p className="none opacity mb-2">
          Emparazon.com ile yeni ürün gruplarına girmeye hazır mısınız?
        </p>
        <p className="opacity mb-4">
          E-posta adresinizi ve şifrenizi girerek sisteme giriş yapabilirsiniz.
        </p>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Input
              onChange={handleInputChange}
              value={form.email}
              //valid={errors.xemail === ''}
              invalid={errorMsg.email !== ""}
              onBlur={handleBlur("email")}
              className="loginInput"
              type="email"
              name="email"
              placeholder="E-mail"
            ></Input>
            <FormFeedback>{errorMsg.email}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              onChange={handleInputChange}
              value={form.password}
              // valid={errors.xpassword === ''}
              invalid={errorMsg.password !== ""}
              onBlur={handleBlur("password")}
              className="loginInput"
              type="password"
              name="password"
              placeholder="Şifre"
            ></Input>
            <FormFeedback>{errorMsg.password}</FormFeedback>
          </FormGroup>
          {/* <Alert isOpen={error.password} toggle={onAlertDismiss}>
            {form.errorMessage}
          </Alert> */}
          {/* <Alert color="success" isOpen={form.isInfo} toggle={onAlertDismiss}>
            {form.infoMessage}
            {/*<NavLink to={`/create-password/${form.tokenPass}`}>  
    </NavLink>*/}
          {/* </Alert> */}
          <Form className="mb-2 ml-3 text-left" id="Form">
            <Input
              type="checkbox"
              style={{ zoom: "0.6" }}
              id="remember"
              name="remember"
              onChange={handle}
              checked={form.remember}
            />
            <Label style={{ color: "white" }} for="remember">
              Beni Hatırla
            </Label>
          </Form>
          <Button
            disabled={
              form.email === "" ||
              form.password === "" ||
              errorMsg.email.length > 0 ||
              errorMsg.password.length > 0
            }
            type="submit"
            className="btn register opacity mb-3 p-2"
          >
            Giriş
          </Button>
        </Form>
        {/* <GoogleAuth /> */}
        <NavLink
          className="d-block opacity underline mb-3"
          to="/forget-password"
        >
          Şifremi Unuttum
        </NavLink>
        <p className="d-block opacity mb-2">Henüz Kayıt Olmadnız mı?</p>
        <NavLink className="underline" to="/kayitol">
          <Button className="btn register opacity p-2">
            Yeni Hesap Oluştur
          </Button>
        </NavLink>
        <small className="d-block opacity mt-1">emparazon.com 2021</small>
      </div>
    </div>
  );
}

export default Login;
