import React, { useRef, useState } from "react";

import { Table } from "reactstrap";
import { NavLink } from "react-router-dom";
import Pagination from "./shared/pagination";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import UserService from "../services/UserService";
import createChannel from "../utils/createChannel";

export default function PaymentLog() {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;

  const [paymentLogs, setPaymentLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [modal, setModal] = useState(false);
  const [isId, setIsId] = useState(null);

  const service = new UserService(apiReq);

  function getPaymentLogs(e) {
    if (e) {
      e.preventDefault();
    }

    service
      .getPaymentLogs(page)
      .then((res) => {
        console.log(res.data.data);
        setPaymentLogs(res.data.data);
      })
      .catch((err) => console.log(err));
  }

  const toggleError = () => {
    setIsError(!isError);
  };

  const toggleSuccess = () => {
    setIsSuccess(!isSuccess);
  };

  const toggle = (id) => {
    setModal(!modal);
    setIsId(id);
  };

  const refundPayment = (e) => {
    e.preventDefault();

    service
      .subscriptionRefund(isId)
      .then((res) => {
        setIsSuccess(true);
        setModal(!modal);
        getPaymentLogs();
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  React.useEffect(() => {
    getPaymentLogs();
  }, [page]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Ücret İptal İşlemi</ModalHeader>
        <ModalBody style={{ color: "red", fontSize: "1.2rem" }}>
          Ücret iade edilecektir !!! Onaylıyor musunuz?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={refundPayment}>
            Onayla
          </Button>
          <Button color="secondary" onClick={toggle}>
            İptal Et
          </Button>
        </ModalFooter>
      </Modal>
      <Alert
        className="text-center mt-1"
        style={{
          height: "65px",
          margin: "0px 45px",
          padding: "25px",
          fontSize: "15px",
          textAlign: "center",
        }}
        color="success"
        isOpen={isSuccess}
        toggle={toggleSuccess}
      >
        İade işlemi başarıyla gerçekleştirildi!
      </Alert>
      <Alert
        className="text-center mt-1"
        style={{
          height: "65px",
          margin: "0px 45px",
          padding: "25px",
          fontSize: "15px",
          textAlign: "center",
        }}
        isOpen={isError}
        toggle={toggleError}
      >
        İade işleminde sorun oluştu!
      </Alert>
      <Table striped>
        <thead>
          <tr>
            <th>Id</th>
            <th>Email</th>
            <th>Ad-Soyad</th>
            <th>Miktar</th>
            <th>Ödeme</th>
            <th>Hata</th>
            <th>Tarih</th>
            <th>İade</th>
            <th>Fiş</th>
            <th>İşlem</th>
          </tr>
        </thead>
        <tbody>
          {paymentLogs.map((i) => {
            return (
              <tr>
                <th>{i.id}</th>
                <td>
                  <NavLink to={`/user-details/${i.userInfoId}`}>
                    <span style={{ color: "blue" }}>
                      {i.user_info === null ? "-" : i.user_info.email}
                    </span>
                  </NavLink>
                </td>
                <td>
                  {i.user_info && `${i.user_info.isim} ${i.user_info.soyad}`}
                </td>
                <td>
                  {i.amount} {i.currency}{" "}
                </td>
                <td>
                  {i.completed ? (
                    <span style={{ color: "green" }}>Başarılı</span>
                  ) : (
                    <span style={{ color: "red" }}>Başarısız</span>
                  )}
                </td>
                <td>
                  {i.error_message
                    ? `${i.error_type} ${i.error_code} ${i.error_message}`
                    : "-"}
                </td>
                <td>{new Date(i.createdAt).toLocaleString()}</td>
                <td>
                  {i.refunded === true ? (
                    <span style={{ color: "red" }}>İade Edildi</span>
                  ) : (
                    <span style={{ color: "green" }}>Hayır</span>
                  )}
                </td>
                <td>
                  {i.receipt_url ? (
                    <a
                      href={i.receipt_url}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      receipt
                    </a>
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-danger"
                    style={{
                      fontSize: "0.8rem",
                      display: !i.refunded && i.completed ? "" : "none",
                    }}
                    id={i.id}
                    onClick={() => toggle(i.id)}
                  >
                    İade Et
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Pagination page={page} setPage={setPage} />
    </div>
  );
}
