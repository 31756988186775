import React, { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard"; //?????
import "font-awesome/css/font-awesome.min.css";
import {
  Input,
  Label,
  Form,
  Col,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

export default function ProductURL({
  handleCreateSalesURL,
  handleInputChange,
  userId,
  packageId,
  plan,
  channel,
  discount,
  salesURL,
}) {
  return (
    <div className="container pt-4">
      <h3 className="text-center">Kişiye Özel Satış Linki Oluştur</h3>
      <div className="col-12 d-flex justify-content-center">
        <Col xs="6">
          <Form onSubmit={handleCreateSalesURL}>
            <FormGroup>
              <Label for="userId">Kullanıcı Id</Label>
              <Input
                onChange={handleInputChange}
                value={userId}
                type="text"
                id="userId"
                name="userId"
                placeholder="Kullanıcı Id"
                required
              ></Input>
            </FormGroup>
            <FormGroup>
              <Label for="packageId">Paket</Label>
              <Input
                type="select"
                name="packageId"
                id="packageId"
                value={packageId}
                onChange={handleInputChange}
                required
              >
                <option value={2}>Web Uygulaması</option>
                <option value={4}>Extension Uygulaması</option>
                <option value={6}>Full Paket</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="plan">Plan</Label>
              <Input
                type="select"
                name="plan"
                id="plan"
                value={plan}
                onChange={handleInputChange}
                required
              >
                <option value={1}>Aylık</option>
                <option value={12}>Yıllık</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="channel">Ödeme Aracı</Label>
              <Input
                type="select"
                name="channel"
                id="channel"
                value={channel}
                onChange={handleInputChange}
                required
              >
                <option value={"Stripe"}>Stripe</option>
                <option value={"Iyzico"}>Iyzico</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="discount">İndirim Oranı</Label>
              <Input
                onChange={handleInputChange}
                value={discount}
                type="number"
                min="0"
                step="1"
                id="discount"
                name="discount"
                placeholder="İndirim %"
                required
              ></Input>
            </FormGroup>
            <Button
              type="submit"
              className="btn opacity register mb-3 mt-5 p-2"
              disabled={!userId || !packageId || !plan}
            >
              URL Üret
            </Button>
          </Form>
        </Col>
      </div>
      <div className="col-12 d-flex justify-content-center">
        <Col xs="10">
          <InputGroup>
            <Input value={salesURL} type="text"></Input>
            {/* <InputGroupAddon addonType="append"> */}
            <InputGroupText>
              <CopyToClipboard text={salesURL}>
                <i className="far fa-copy cursor icons text-dark"></i>
              </CopyToClipboard>
            </InputGroupText>
            <InputGroupText>
              <a
                className="goToKeyword"
                href={salesURL}
                target="_blank"
                style={{ fontSize: "1.25rem" }}
              >
                <i className="fas fa-external-link-square-alt cursor icons text-dark"></i>
              </a>
            </InputGroupText>
            {/* </InputGroupAddon> */}
          </InputGroup>
        </Col>
      </div>
    </div>
  );
}
