let API_URL;
export let SOCKET_URL;
if ((process.env.REACT_APP_PUBLIC_ENV) === "production") {
  API_URL =
    process.env.REACT_APP_PUBLIC_BASE_URL_PROD;
  SOCKET_URL = process.env.REACT_APP_SOCKET_URL_PROD;
}
else if ((process.env.REACT_APP_PUBLIC_ENV) === "test") {
  API_URL =
    process.env.REACT_APP_PUBLIC_BASE_URL_TEST;
} else if (
  (process.env.REACT_APP_PUBLIC_ENV) === "development"
) {
  API_URL =
    process.env.REACT_APP_PUBLIC_BASE_URL_DEV;
  SOCKET_URL = process.env.REACT_APP_SOCKET_URL_DEV;
}


console.log("apiUrl", API_URL);
export default API_URL;

// export const apiUrl = "https://api.emparazon.com/api"

// // console.log(process.env.ENV, process.env.REACT_PUBLIC_ENV);
