import React from 'react'
import {
    Card, CardBody,
    Input,
    Form,
    Row,
    Col,
    Button,
    FormGroup
} from 'reactstrap';

export default function SearchComponent(props) {
    return (
        <div>
            <Card>
                <CardBody>
                    <div className="container my-3 pt-1 filtreler">
                        <div className="text-center">
                            <div className="px-3 mt-3">
                                <Form
                                    form
                                    onSubmit={props.getData}
                                    className="container mx-auto formMedia asinForm d-flex justify-content-center"
                                >
                                    <Row
                                        form
                                        id="asinForm"
                                        className="text-white keyWordForm w-75"
                                    >
                                        <Col className="inputForm col-md-8">
                                            <FormGroup>
                                                <Input
                                                    onChange={props.handleInputChange}
                                                    id="searchData"
                                                    className="form-control loginInput"
                                                    type="search"
                                                    placeholder="Ad, soyad veya email"
                                                    name="searchData"
                                                    value={props.inputValues.searchData}
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                ></Input>
                                            </FormGroup>
                                        </Col>
                                        <Col className="inputForm col-md-4">
                                            <Button
                                                id="AsinFilterBtn"
                                                type="submit"
                                                className="btn p-2 text-align-center register"
                                            >
                                                Ara
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
};
