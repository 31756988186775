import React from "react";
import UserTagPage from "../components/userTagPage";
import Breadcrumb from "../components/shared/breadcrumb";
import AdminDashboardLayout from "../layouts/adminDashboard";

export default function UserTag(props) {
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Kullanıcı İzleri" />
        <UserTagPage />
      </AdminDashboardLayout>
    </div>
  );
}
