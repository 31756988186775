import React from "react";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useNavigate, Outlet } from "react-router-dom";
// import Login from "../pages/login";
import Loading from "../components/tools/loading";
import NotAuthorized from "../components/notAuthorized";

function PublicRoute({ children }) {
  const { user, isLoading } = useContext(AuthContext);

  const navigate = useNavigate();

  if (!isLoading && !user) {
    return navigate('/login')
  } else if (isLoading) {
    return <Loading />
  } else if ((user && user?.role === "dwtqXfOhfh") || (user && user?.role === "devRxvn") || (user && user?.role === "crmRxvn")) {
    return <Outlet />;
  } else {
    return <NotAuthorized />
  }
}

export default PublicRoute;
