import React, { useState, useRef, useEffect } from "react";
import AdminDashboardLayout from "../layouts/adminDashboard";
import createChannel from "../utils/createChannel";
import Breadcrumb from "../components/shared/breadcrumb";
import UserList from "../components/user/userList";
import SearchComponent from "../components/shared/searchComponent";
import UserService from "../services/UserService.js";
import alertNotification from "../utils/alertNotification";
export default function Users(props) {
  const channel = useRef(createChannel());
  const apiRequest = channel.current.request;
  // const apiController = channel.current.controller;

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [inputValues, setInputValues] = useState({
    searchData: "",
  });

  const service = new UserService(apiRequest);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };
  const getUsers = (e) => {
    if (e) {
      e.preventDefault();
    }

    let query = `page=${page}`;
    if (inputValues.searchData) {
      query += `&searchData=${inputValues.searchData}`;
    }
    //kullanıcıları getir

    setIsLoading(true);
    service
      .getAllUsers(query)
      .then((res) => {
        if (res.data.data.length > 0) {
          setUsers(res.data.data);
        } else if (page !== 1) {
          setPage((prev) => prev - 1);
          alertNotification("error", "Verilerin sonuna geldiniz!");
        } else {
          setUsers(res.data.data)
        }
      })
      .catch((err) => {
        alertNotification("error", "Hata oluştu!");
      })
      .finally(setIsLoading(false));
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Kullanıcılar" />
        <SearchComponent
          handleInputChange={handleInputChange}
          getData={getUsers}
          inputValues={inputValues}
        />
        {isLoading ? (
          <h1>Loading</h1>
        ) :
          !users.length ?
            <h2>Kullanıcı bulunamadı</h2>
            :
            (
              <UserList
                users={users}
                getUsers={getUsers}
                page={page}
                setPage={setPage}
              />
            )}
      </AdminDashboardLayout>
    </div>
  );
}
