import React, { useState, useRef, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Breadcrumb from "../components/shared/breadcrumb";
import AdminDashboardLayout from "../layouts/adminDashboard";

import MemberList from "../components/member/memberList";
import SearchComponent from "../components/shared/searchComponent";

import UserService from "../services/UserService";
import createChannel from "../utils/createChannel";
import alertNotification from "../utils/alertNotification";

export default function Members(props) {
  const channel = useRef(createChannel());
  const apiRequest = channel.current.request;
  // const apiController = channel.current.controller;
  const userService = new UserService(apiRequest);

  const [members, setMembers] = useState([]);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [memberId, setMemberId] = useState();
  const [page, setPage] = useState(1);
  const [inputValues, setInputValues] = useState({ searchData: null });

  const toggle = (e) => {
    setMemberId(e.target.id);
    setModal(!modal);
  };

  const toggleSecond = () => setModal(!modal);

  const toggle1 = (e) => {
    setMemberId(e.target.id);
    setModal1(!modal1);
  };

  const toggleSecond1 = () => setModal1(!modal1);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const getMembers = (e) => {
    if (e) {
      e.preventDefault();
    }

    let query = `page=${page}`;
    if (inputValues.searchData) {
      query += `&searchData=${inputValues.searchData}`;
    }
    userService
      .getMembers(query)
      .then((res) => setMembers(res.data.data))
      .catch((err) => console.log(err));
  };

  const handleRetryPayment = () => {
    //Yanlış bişeyler var
    userService
      .retryPayment(memberId)
      .then((res) => {
        getMembers();
        alertNotification("success", "Ödeme alınmıştır!");
      })
      .catch((err) => {
        alertNotification("error", "Hata oluştu!");
      });
    toggleSecond();
  };

  const handleCancelMember = () => {
    userService
      .cancelSubscription(memberId)
      .then((res) => {
        getMembers();
        alertNotification("success", "Abonelik iptal edildi!");
      })
      .catch((err) => {
        alertNotification("error", "Hata oluştu!");
      });
    toggleSecond1();
  };

  useEffect(() => {
    getMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, inputValues]);

  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Aboneler" />
        <SearchComponent
          handleInputChange={handleInputChange}
          getData={getMembers}
          inputValues={inputValues}
        />
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Tekrar Ödeme</ModalHeader>
          <ModalBody style={{ color: "red", fontSize: "1.2rem" }}>
            Tekrar ödeme talep edilecektir. Onaylıyor musunuz?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleRetryPayment}>
              Onayla
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              İptal Et
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={modal1} toggle={toggle1}>
          <ModalHeader toggle={toggle1}>Abonelik iptali</ModalHeader>
          <ModalBody style={{ color: "red", fontSize: "1.2rem" }}>
            Abonelik iptal edilecektir. Onaylıyor musunuz?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleCancelMember}>
              Onayla
            </Button>{" "}
            <Button color="secondary" onClick={toggle1}>
              İptal Et
            </Button>
          </ModalFooter>
        </Modal>
        <ToastContainer />
        {members.length > 0 && (
          <MemberList
            members={members}
            toggle={toggle}
            toggle1={toggle1}
            getMembers={getMembers}
            page={page}
            setPage={setPage}
          />
        )}
      </AdminDashboardLayout>
    </div>
  );
}
