import React from "react";
import Breadcrumb from "../components/shared/breadcrumb";
import AdminDashboardLayout from "../layouts/adminDashboard";
import ErrorLogsTable from "../components/errorLogsTable";

export default function ErrorLogs(props) {
  React.useEffect(() => {
    // if (localStorage.getItem("token") != null) {
    //   props.checkAdminAccess();
    // }
  }, []);

  return (
    <AdminDashboardLayout>
      <Breadcrumb title="Hata Logları" />
      <ErrorLogsTable />
    </AdminDashboardLayout>
  );
}
