import React, { useEffect, useState, useRef, useContext } from "react";
import StatsCard from "./shared/statsCard";

import PageStats from "./pageStats";
import UserService from "../services/UserService";
import MonthlyIncomeNew from "./monthyIncomeNew";
import createChannel from "../utils/createChannel";
import AuthContext from "../context/AuthContext";

const AdminPanel = () => {
  const [stats, setStats] = useState([]);
  const [lodaing, setLoading] = useState(false);
  const channel = useRef(createChannel());
  const apiRequest = channel.current.request;
  // const apiController = channel.current.controller;
  const { currentUser } = useContext(AuthContext);

  function getAdmin() {
    let service = new UserService(apiRequest);
    setLoading(true);
    service
      .getAdminStats()
      .then((res) => {
        setStats(res.data.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      Aktif Kullanıcı Sayısı: {currentUser}
      <MonthlyIncomeNew />
      <div className="d-flex flex-wrap">
        <StatsCard
          progressColor="primary"
          subTitle="Tüm Kullanıcılar"
          title="Toplam Kullanıcı Sayısı"
          count={stats?.allUsers}
          value={stats?.allUsers}
        />
        <StatsCard
          progressColor="primary"
          subTitle="Tüm Kullanıcılar"
          title="Doğrulama Yapanlar"
          count={stats?.verifiedUsers}
          value={Math.ceil((stats?.verifiedUsers * 100) / stats?.allUsers)}
        />
        <StatsCard
          progressColor="primary"
          subTitle="Tüm Kullanıcılar"
          title="Tel No Doğrulama Yapanlar"
          count={stats.gsmVerifiedUsers}
          value={Math.ceil((stats?.gsmVerifiedUsers * 100) / stats?.allUsers)}
        />

        <StatsCard
          progressColor="primary"
          subTitle="Tüm Aboneler"
          title="Aktif Abone Sayısı"
          count={stats?.activeSubs}
          value={(
            (stats?.activeSubs * 100) /
            (stats?.allSubs - stats?.allNonSubs)
          ).toFixed(2)}
        />
        <StatsCard
          progressColor="danger"
          subTitle="Tüm Aboneler"
          title="İptal Eden Abone Sayısı"
          count={stats?.cancelSubs}
          value={(
            (stats?.cancelSubs * 100) /
            (stats?.allSubs - stats?.allNonSubs)
          ).toFixed(2)}
        />
        <StatsCard
          progressColor="primary"
          subTitle="Tüm Abone Olmayanlar"
          title="Abone Olmayan Aktif Kullanıcı Sayısı"
          count={stats?.activeNonSubs}
          value={((stats?.activeNonSubs * 100) / stats?.allNonSubs).toFixed(2)}
        />
        <StatsCard
          progressColor="danger"
          subTitle="Tüm Abone Olmayanlar"
          title="Süresi Biten Kullanıcı Sayısı"
          count={stats?.outDatedNonSubs}
          value={((stats?.outDatedNonSubs * 100) / stats?.allNonSubs).toFixed(
            2
          )}
        />

        <StatsCard
          progressColor="primary"
          subTitle="Aktif Abone"
          title="Abone Web Paketi"
          count={stats?.webSubs}
          value={((stats?.webSubs * 100) / stats?.activeSubs).toFixed(2)}
        />
        <StatsCard
          progressColor="primary"
          subTitle="Aktif Abone"
          title="Abone Full Paket"
          count={stats.fullSubs}
          value={((stats.fullSubs * 100) / stats.activeSubs).toFixed(2)}
        />
        <StatsCard
          progressColor="primary"
          subTitle="Aktif Abone"
          title="Abone Chrome Eklentisi"
          count={stats?.extSubs}
          value={((stats?.extSubs * 100) / stats?.activeSubs).toFixed(2)}
        />

        <StatsCard
          progressColor="primary"
          subTitle="Aktif Abone Olmayanlar"
          title="Abone Olmayan Web Paketi"
          count={stats?.webNonSubs}
          value={((stats?.webNonSubs * 100) / stats?.activeNonSubs).toFixed(2)}
        />
        <StatsCard
          progressColor="primary"
          subTitle="Aktif Abone Olmayanlar"
          title="Abone Olmayan Full Paket"
          count={stats?.fullNonSubs}
          value={((stats?.fullNonSubs * 100) / stats?.activeNonSubs).toFixed(2)}
        />
        <StatsCard
          progressColor="primary"
          subTitle="Aktif Abone Olmayanlar"
          title="Abone Olmayan Chrome Eklentisi"
          count={stats?.extNonSubs}
          value={((stats?.extNonSubs * 100) / stats?.activeNonSubs).toFixed(2)}
        />

        <StatsCard
          progressColor="primary"
          subTitle="Aktif Abone"
          title="Abone Aylık Paket Alan"
          count={stats?.monthlySubs}
          value={((stats?.monthlySubs * 100) / stats?.activeSubs).toFixed(2)}
        />
        <StatsCard
          progressColor="primary"
          subTitle="Aktif Abone"
          title="Abone Yıllık Paket Alan"
          count={stats?.annualSubs}
          value={((stats?.annualSubs * 100) / stats?.activeSubs).toFixed(2)}
        />

        <StatsCard
          progressColor="primary"
          subTitle="Aktif Abone Olmayanlar"
          title="Abone Olmayan Aylık Paket Alan"
          count={stats?.monthlyNonSubs}
          value={((stats?.monthlyNonSubs * 100) / stats?.activeNonSubs).toFixed(
            2
          )}
        />
        <StatsCard
          progressColor="primary"
          subTitle="Aktif Abone Olmayanlar"
          title="Abone Olmayan Yıllık Paket Alan"
          count={stats?.annualNonSubs}
          value={((stats?.annualNonSubs * 100) / stats?.activeNonSubs).toFixed(
            2
          )}
        />

        <StatsCard
          progressColor="primary"
          subTitle=""
          title="MRR (Aylık Tekrarlanan Gelir)"
          count={stats?.MRR}
        />
        <StatsCard
          progressColor="primary"
          subTitle=""
          title="ARR (Yıllık Abonelik Geliri)"
          count={stats?.ARR}
        />
        <StatsCard
          progressColor="primary"
          subTitle=""
          title="MGR (Aylık Gelir Büyüme Yüzdesi)"
          count={stats?.monthlyIncrease}
        />
        <StatsCard
          progressColor="primary"
          subTitle=""
          title="Aylık Kullanıcı Sayısı"
          count={stats?.monthlyUser}
        />
        <StatsCard
          progressColor="primary"
          subTitle=""
          title="CMGR (Aylık Birleşik Büyüme Oranı)"
          count={stats?.CMGR}
        />
        <StatsCard
          progressColor="primary"
          subTitle=""
          title="New MRR (Yeni Müşterilerden Gelen Gelir)"
          count={stats?.newMRR}
        />
        <StatsCard
          progressColor="primary"
          subTitle="Upgrade sistemi bulunmamaktadır."
          title="Expension MMR (Mevcut Müşterilerden Gelen Ek Gelir)"
          count={stats?.expensionMRR}
        />
        <StatsCard
          progressColor="primary"
          subTitle=""
          title="Reactivation MRR (Geri Kazanılan Müşterilerden Kazanılan Ek Gelir)"
          count={stats?.reactivationMRR}
        />
        <StatsCard
          progressColor="primary"
          subTitle="Downgrade sistemi bulunmamaktadır."
          title="Contraction MRR (Mevcut Müşterilerdeki Gelir Kaybı)"
          count={stats?.contractionMRR}
        />
        <StatsCard
          progressColor="danger"
          subTitle=""
          title="Churned MRR (Kaybedilen Müşteri MRR Kaybı)"
          count={stats?.churnedMRR}
        />
        <StatsCard
          progressColor="primary"
          subTitle=""
          title="Müşteri Başına Ortalama Gelir"
          count={stats?.averageRevenuePerMember}
        />
        <PageStats />
      </div>
    </div>
  );
};

export default AdminPanel;
