import React, { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom';
import { Table } from 'reactstrap'
import UserService from '../services/UserService';
import createChannel from '../utils/createChannel';
import ErrorSearch from './errorSearch';
import Pagination from './shared/pagination';


const ExtensionErrorLogsTable = () => {  
    const [page,setPage] = useState(1)
    const [errorLogs, setErrorLogs] = useState([]);

    const channel = useRef(createChannel());
    const apiReq = channel.current.request;

    const service = new UserService(apiReq);

    useEffect(() => {
        // console.log('pageOnChange', page)
        getErrorLogs(page);
    }, [page])

    function getErrorLogs(page) {
        if(searchInput.length > 1) {
            console.log('page->', page)
            service.getExtensionErrorLogsWithToolSearch(page,searchInput).then((res) => {
                console.log(res.data.data)
                setErrorLogs(res.data.data);
            })
        } else {
            service.getExtensionErrorLogs(page).then((res) => {
                // console.log('res->', res.data.data);
                setErrorLogs(res.data.data);
            })
        }
    }

    // Search Fields
    const [searchInput, setSearchInput] = useState('')
    const handleChange = (e) => {
        // console.log(e.target.value);
        setSearchInput(e.target.value)
    }

    const handleSubmit =  (e) => {
        e.preventDefault();
        // console.log('submitedWith', searchInput);
        setPage(1)
        getErrorLogs(page)
    }

    return (
        <div>
            <ErrorSearch  
                searchInput={searchInput} 
                handleChange={handleChange}
                handleSubmit={handleSubmit}        
            />
            <Table striped>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Email</th>
                        <th>Fullname</th>
                        <th>Status Code</th>
                        <th>Error Message</th>
                        <th>Url</th>
                        <th>Error Tool</th>
                        <th>Error Description</th>
                        <th>Tarih</th>
                    </tr>
                </thead>
                <tbody>   
                    {errorLogs.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>
                                {item.user_info 
                                    ? (
                                        <NavLink to={`/user-details/${item.user_info_id}`}>
                                            <span style={{color: "blue"}}>{item.user_info.email}</span>
                                        </NavLink>
                                    )
                                    : (" - ")
                            }
                            </td>
                            <td>{item.user_info ? `${item.user_info.isim} ${item.user_info.soyad}` : ''}</td>
                            <td>{item.error_status}</td>
                            <td>{item.error_message}</td>
                            <td>
                                <a href={item.url} target="_blank" className='errorTable__urlAnchor' title={item.url} > 
                                    {item.url.substring(18,40)}
                                </a>
                            </td>
                            <td>{item.extension_tool_error.tool}</td>
                            <td>{item.extension_tool_error.description}</td>
                            <td>{new Date(item.createdAt).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Pagination page={page} setPage={setPage} />
        </div>
    )
}

export default ExtensionErrorLogsTable

