import React, { useState, useRef, useEffect } from "react";

import AdminDashboardLayout from "../layouts/adminDashboard";
import Breadcrumb from "../components/shared/breadcrumb";
import { Row, Col, Container, Table } from "reactstrap";
import AffiliateDetailsCouponTable from "../components/affiliateDetailsCouponTable";
import { useParams } from "react-router-dom";
import createChannel from "../utils/createChannel";
import IyzicoService from "../services/IyzicoService";

export default function AffiliateDetails(props) {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;
  const params = useParams();
  const [affiliate, setAffiliate] = useState([]);
  const [coupons, setCoupons] = useState([]);

  const service = new IyzicoService(apiReq);
  const getAffiliateDetails = async () => {
    service.getAffiliateDetails(params.id).then((res) => {
      const arr = [];
      res.data.data.coupons.forEach((element) => {
        arr.push(element.plan_name);
      });

      const uniqeArr = arr.filter((v, i, a) => a.indexOf(v) === i);
      setAffiliate(res.data.data);
    });
    // details.getAffiliateDetails(params.id).then((res) => {
    //   const arr = [];
    //   res.data.coupons.forEach((element) => {
    //     arr.push(element.plan_name);
    //   });
    //   const uniqueArr = arr.filter((v, i, a) => a.indexOf(v) === i);
    //   const getPlanShares = new UserProxy();
    //   getPlanShares.getPlanShares(uniqueArr).then((result) => {
    //     const couponTableValues = [];
    //     for (let i = 0; i < res.data.coupons.length; i++) {
    //       const newUsage = [];
    //       for (let j = 0; j < res.data.coupons[i].usage.length; j++) {
    //         for (let k = 0; k < result.data.length; k++) {
    //           if (res.data.coupons[i].plan_name === result.data[k].planName) {
    //             let price = res.data.coupons[i].usage[j].price * (1 - 0.18);
    //             let obj = {
    //               affiliateShare: Number(
    //                 (
    //                   price *
    //                   (res.data.max_share - result.data[k].share)
    //                 ).toFixed(2)
    //               ),
    //               date: res.data.coupons[i].usage[j].date,
    //             };
    //             newUsage.push(obj);
    //           }
    //         }
    //       }
    //       couponTableValues.push({
    //         code: res.data.coupons[i].code,
    //         is_valid: res.data.coupons[i].is_valid,
    //         plan_name: res.data.coupons[i].plan_name,
    //         usage: newUsage,
    //         userInfoId: res.data.coupons[i].userInfoId,
    //       });
    //     }
    //     setCoupons(couponTableValues);
    //   });
    //   setAffiliate(res.data);
    // });
  };

  useEffect(() => {
    getAffiliateDetails();
  }, []);
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Affiliates" />

        {affiliate != [] && (
          <Container fluid="lg">
            <Row className="border rounded pt-1 px-2 mt-1 bg-info">
              <Col>
                <Container
                  fluid="lg"
                  className="bg-light text-dark  border rounded px-4"
                >
                  <Row>
                    <Col>
                      <Table className="mt-3 h6 " striped bordered>
                        <thead>
                          <tr>
                            <th colSpan="12">
                              {affiliate?.name} {affiliate?.surname}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th colSpan="2">Phone:</th>
                            <td colSpan="2">{affiliate?.gsm_number}</td>
                            <th colSpan="2">Id:</th>
                            <td colSpan="2">{affiliate?.identity_number}</td>
                            <th colSpan="2">IBAN:</th>
                            <td colSpan="2">{affiliate?.iban}</td>
                          </tr>

                          <tr>
                            <th colSpan="2">Tax Number:</th>
                            <td colSpan="2">{affiliate?.tax_number}</td>
                            <th colSpan="2">Company Name:</th>
                            <td colSpan="2">{affiliate?.company_name}</td>
                            <th colSpan="2">Tax Office:</th>
                            <td colSpan="2">{affiliate?.tax_office}</td>
                          </tr>

                          <tr>
                            <th colSpan="2">Country:</th>
                            <td colSpan="2">{affiliate?.country}</td>
                            <th colSpan="2">City:</th>
                            <td colSpan="2">{affiliate?.city}</td>
                            <th colSpan="2">Zip Code:</th>
                            <td colSpan="2">{affiliate?.zipCode}</td>
                          </tr>
                          <tr>
                            <th colSpan="6">Address:</th>
                            <th colSpan="4">Email:</th>
                            <th colSpan="2">Share:</th>
                          </tr>
                          <tr>
                            <td colSpan="6" rowSpan="3">
                              {affiliate?.registration_address}
                            </td>
                            <td colSpan="4" rowSpan="3">
                              {affiliate?.email}
                            </td>
                            <td colSpan="2" rowSpan="3">
                              {affiliate?.max_share}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row className="border rounded pt-1 px-2 mt-1 bg-info">
              <Col>
                <Container
                  fluid="lg"
                  className="bg-light text-dark  border rounded px-4"
                >
                  <Row>
                    <Col>
                      <Table
                        className="mt-3 h6 table-hover"
                        style={{ overflow: "hidden" }}
                        bordered
                        striped
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>COUPON CODE</th>
                            <th>ACTIVE/PASSIVE</th>
                            <th>PLAN NAME</th>
                            <th>AMOUNT TO BE PAID</th>
                            <th>USAGE</th>
                            <th>CREATOR</th>
                            <th>APPLY</th>
                          </tr>
                        </thead>
                        <tbody>
                          {coupons.length > 0 &&
                            coupons.map((el, key) => {
                              return (
                                <AffiliateDetailsCouponTable
                                  coupon={el}
                                  no={key + 1}
                                />
                              );
                            })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        )}
      </AdminDashboardLayout>
    </div>
  );
}
