import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Table } from "reactstrap";

import Pagination from "./shared/paginationRegister";
import { Input, Label, Form, Row, Col, FormGroup } from "reactstrap";
import createChannel from "../utils/createChannel";
import UserService from "../services/UserService";

export default function RegisterTagPage() {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;

  const [page, setPage] = useState(1);
  const [register, setRegister] = useState([]);
  const [phase, setPhase] = useState(true);

  const service = new UserService(apiReq);

  function getRegisterTag(e) {
    if (e) {
      e.preventDefault();
    }

    service
      .getRegisterTags(page, phase)
      .then((res) => {
        setRegister(res.data.data);
      })
      .catch((err) => console.log(err));
  }

  const handlePhaseChange = (e) => {
    e.preventDefault();
    setPhase(!phase);
    getRegisterTag();
  };

  useEffect(() => {
    getRegisterTag();
  }, [page]);

  return (
    <div>
      <div className="container pt-2">
        <h3 className="text-center text-danger">
          İncelemek İstediğiniz Verileri Seçiniz
        </h3>
        <div className="col-12 d-flex justify-content-center">
          <Form>
            <Row>
              <Col xs="11">
                <FormGroup>
                  <Label for="plan">Kayıt Mailleri</Label>
                  <Input
                    type="select"
                    name="plan"
                    id="plan"
                    onChange={handlePhaseChange}
                    required
                  >
                    <option>Devam Eden</option>
                    <option>Biten</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div>
        <Table striped>
          <thead>
            <tr>
              <th>ID</th>
              <th>EMAIL</th>
              <th>AD-SOYAD</th>
              <th>KAYIT MAILLERİ</th>
              <th>GİDEN MAIL</th>
              <th>SON GÖRÜNME</th>
            </tr>
          </thead>
          <tbody>
            {register.map((i) => {
              return (
                <tr>
                  <td>{i.id}</td>
                  <td>{i.user_info.email}</td>
                  <td>
                    {i.user_info.isim} {i.user_info.soyad}
                  </td>
                  <td>
                    {i.register == true ? (
                      <span style={{ color: "green" }}>Devam Ediyor</span>
                    ) : (
                      <span style={{ color: "red" }}>Bitti</span>
                    )}
                  </td>
                  <td>{i.phase}</td>
                  <td>
                    {i.latest_action != null
                      ? new Date(i.latest_action).toLocaleString()
                      : "---"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Pagination page={page} setPage={setPage} />
    </div>
  );
}
