import React from "react";
import AdminDashboardLayout from "../layouts/adminDashboard";
import PaymentLog from "../components/paymentLog";
import Breadcrumb from "../components/shared/breadcrumb";

export default function PaymentLogs(props) {
  // React.useEffect(() => {
  //     if (localStorage.getItem("token") != null) {
  //         props.checkAdminAccess();
  //     }
  // }, []);
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Ödeme Kayıtları" />
        <PaymentLog />
      </AdminDashboardLayout>
    </div>
  );
}
