import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AuthService from "../services/AuthService";

import createChannel from "../utils/createChannel";

const AuthContext = createContext();

// create a new axios instance
const channel = createChannel();

// create the context provider
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  // create a new user service instance
  const authService = new AuthService(channel.request);

  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  // register user
  const register = (values, redirectPage) => {
    setIsLoading(true);
    authService
      .registerUser(values)
      .then((res) => {
        // router("/");
        console.log("registered");
        // alertNotification("success", res.data.message);
      })
      .catch((err) => {
        console.log(err);
        // alertNotification("error", err.response && err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // login user
  const login = (values, redirectPage) => {
    setLoginLoading(true);
    authService
      .loginUser(values)
      .then((res) => {
        console.log("res -------->>", res.data)
        checkAuthStatus();
        setTimeout(() => {
          navigate("/");
        }, 250);

        // check login location, return if from order summary w/o redirect
        // if (router.pathname.includes("order-summary")) return; burayı yoruma aldın
        // setTimeout(() => {
        //   router.push(redirectPage ? redirectPage : "/");
        // }, 250);
        // setTimeout(() => navigate("/"), 250);
      })
      .catch((err) => {
        setError(err.response.data.message);
        alert("Email veya Şifre Hatalı!");
      })
      .finally(() => {
        setLoginLoading(false);
      });
  };

  // logout user
  const logout = () => {
    authService
      .logoutUser()
      .then((res) => {
        setUser(null);
        // navigate("/login");
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  // check if user is logged in
  const checkAuthStatus = (from) => {
    setIsLoading(true);
    authService
      .checkUserAuth()
      .then((res) => {
        console.log("response --------->>", res)
        setUser(res.data.data);
      })
      .catch((err) => {
        setUser(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    checkAuthStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        error,
        isLoading,
        register,
        login,
        logout,
        checkAuthStatus,
        loginLoading,
        setCurrentUser,
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
