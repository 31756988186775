import { useState, useEffect, useRef } from "react";
import {  Table } from "reactstrap"
import UserService from "../services/UserService";
import createChannel from "../utils/createChannel";
import CouponSearch from "./couponSearch";
import Pagination from "./shared/pagination";



const CouponTable = () => {
    const [page, setPage] = useState(1);
    const [coupons, setCoupons] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const channel = useRef(createChannel());
    const apiReq = channel.current.request;
    const service = new UserService(apiReq);



    function getCoupons(page) {
        // console.log('getRequest will be here');
        
        if(searchInput.length > 1) {
            service.getDefinedCouponsWithCouponSearch(page, searchInput).then((res) => {
                console.log(res.data.data)
                setCoupons(res.data.data);
            })
        } else {
            service.getDefinedCoupons(page).then((res) => {
                setCoupons(res.data.data);
            })
        }
        
        
    }

    function handleChange(e) {
        setSearchInput(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();

        setPage(1);
        getCoupons(page);
    }
    
    useEffect(() => {
        getCoupons(page);
    }, [page])
    
    return (
        <div>
            <CouponSearch searchInput={searchInput} handleChange={handleChange} handleSubmit={handleSubmit} />
            <Table striped>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Kupon Adı</th>
                        <th>Kupon Yüzdesi</th>
                        <th>Plan Adı</th>
                        <th>Süre</th>
                        <th>Kullanım Limiti</th>
                        <th>Süre Ay</th>
                        <th>Bağlı Kurum</th>
                    </tr>
                </thead>
                <tbody>
                    {coupons.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.code}</td>
                            <td>{item.percent_off ? `${item.percent_off}` : '-'}</td>
                            <td>{item.plan_name}</td>
                            <td>{item.duration ? `${item.duration}` : '-'}</td>
                            <td>{item.max_redemptions ? `${item.max_redemptions}` : '-' }</td>
                            <td>{item.durationMonth ? `${item.durationMonth}` : '-' }</td>
                            <td>{item.affiliate_name ? `${item.affiliate_name}` : '-' }</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Pagination page={page} setPage={setPage} />
        </div>
    )
}
export default CouponTable;