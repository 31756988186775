import React from 'react'
import Breadcrumb from '../components/shared/breadcrumb';
import AdminDashboardLayout from '../layouts/adminDashboard';
import ExtensionErrorLogsTable from '../components/extensionErrorLogsTable';


export default function ExtensionErrorLogs() {
  return (
    <AdminDashboardLayout>
      <Breadcrumb title="Extension Hata Logları" />
      <ExtensionErrorLogsTable />
    </AdminDashboardLayout>
  )
}
