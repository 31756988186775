import React from "react";
import { NavLink } from "react-router-dom";
import { Table } from "reactstrap";
import Pagination from "../shared/pagination";

const UserList = (props) => {
  return (
    <div>
      <Table striped>
        <thead>
          <tr>
            <th>Id</th>
            <th>Email</th>
            <th>İsim Soyisim</th>
            <th>Gsm Onay</th>
            <th>Gsm Numarası</th>
            <th>Kullanım Trafiği</th>
            <th>Aktif Paketi</th>
            <th>Onay</th>
            <th>Detay</th>
          </tr>
        </thead>
        <tbody>
          {props?.users?.map((user) => {
            return (
              <tr>
                <th>{user.id}</th>
                <td>
                  <NavLink to={`/user-details/${user.id}`}>
                    <span style={{ color: "blue" }}>{user.email}</span>
                  </NavLink>
                </td>
                <td>
                  {user.isim} {user.soyad}
                </td>
                <td>{user.is_gsm_verified === true ? "Evet" : "Hayır"}</td>
                <td>{user.is_gsm_verified === true ? user.gsm_number : "-"}</td>
                <td>{user.user_stats}</td>
                <td>{user.is_active === true ? user.package_plan_id : ""}</td>
                <td>{user.verify ? "Verify" : "Not"}</td>
                <td>
                  <NavLink to={`/user-details/${user.id}`}>
                    <button className="btn btn-sm btn-info">Detay</button>
                  </NavLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="text-center">
        {/* <Pagination page={props.page} getUsers={props.getUsers} /> */}
        <Pagination page={props.page} setPage={props.setPage} />
      </div>
    </div>
  );
};
export default UserList;
