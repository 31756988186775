import React from "react";
import { Table } from "reactstrap";
import { NavLink } from "react-router-dom";
import Pagination from "../shared/pagination";

export default function MemberList({
  page,
  setPage,
  getMembers,
  members,
  toggle,
  toggle1,
}) {
  return (
    <div>
      {members.length === 0 ? (
        <h1>Aradığınız kriterlerle eşleşen öge bulunamadı!</h1>
      ) : (
        <Table striped>
          <thead>
            <tr>
              <th>Id</th>
              <th>Email</th>
              <th>İsim </th>
              <th>Soyisim</th>
              <th>Paket</th>
              <th>Plan</th>
              {/*<th>Miktar</th>*/}
              <th>Durum</th>
              <th>Iyzico</th>
              <th>Başlangıç Tarihi</th>
              <th>İptal Tarihi</th>
              <th>Bitiş Tarihi</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member) => {
              return (
                <tr>
                  <th>{member.user_info_id}</th>
                  <td>
                    <NavLink to={`/user-details/${member.user_info_id}`}>
                      <span style={{ color: "blue" }}>{member.email}</span>
                    </NavLink>
                  </td>
                  <td>{member.isim}</td>
                  <td>{member.soyad}</td>
                  <td>{member.name}</td>
                  <td>{member.duration == 1 ? "Aylık" : "Yıllık"} </td>
                  {/*<td>{member.price} TL</td>*/}
                  <td>
                    {member.is_active ? (
                      <span style={{ color: "green" }}>Aktif</span>
                    ) : (
                      <span style={{ color: "red" }}>Pasif</span>
                    )}
                  </td>
                  <td>
                    <span
                      style={{
                        color:
                          member.iyzico_subs_status === "ACTIVE" ||
                            member.iyzico_subs_status === "UPGRADED"
                            ? "green"
                            : member.iyzico_subs_status === "CANCELED"
                              ? "red"
                              : member.iyzico_subs_status === "UNPAID"
                                ? "yellow"
                                : "blue",
                      }}
                    >
                      {member.iyzico_subs_status}
                    </span>
                  </td>
                  <td>{new Date(member.created_at).toLocaleString()}</td>
                  <td>
                    {member.cancel_date
                      ? new Date(member.cancel_date).toLocaleString()
                      : "-"}
                  </td>
                  <td>
                    {member.end_date
                      ? new Date(member.end_date).toLocaleString()
                      : "-"}
                  </td>
                  <td>
                    {/*
                      <button
                      className="btn btn-sm btn-success mx-1 my-1"
                      style={{ fontSize: "0.8rem", display: !member.is_active ? "none" : "" }}
                      id={member.id}
                      onClick={toggle}
                    >
                      ödeme
                    </button>
                    */}
                    <button
                      className="btn btn-sm btn-danger mx-1 my-1"
                      style={{
                        fontSize: "0.8rem",
                        display: !member.is_active ? "none" : "",
                      }}
                      id={member.id}
                      onClick={toggle1}
                    >
                      İptal Et
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}

      <Pagination page={page} getUsers={getMembers} setPage={setPage} />
    </div>
  );
}
