import React from "react";
import breadCrumbs from "../../utils/bradCrumbs";

const Breadcrumb = (props) => {
  const { title } = props;

  if (
    title === breadCrumbs[0] ||
    title === breadCrumbs[1] ||
    title === "" ||
    title === breadCrumbs[2] ||
    title === breadCrumbs[6]
  ) {
    return (
      <div className="ht__bradcaump__area" style={{ height: "200px" }}>
        <div className="ht__bradcaump__container">
          <h2
            className="text-white"
            style={{ marginTop: "70%", fontFamily: "Josefin Sans" }}
          >
            {title}
          </h2>
        </div>
      </div>
    );
  } else if (
    title === breadCrumbs[3] ||
    title === breadCrumbs[4] ||
    title === breadCrumbs[5]
  ) {
    return (
      <div className="ht__bradcaump__area" style={{ height: "130px" }}></div>
    );
  } else {
    return (
      <div>
        <div className="ht__bradcaump__area mb-2">
          <div className="ht__bradcaump__container">
            <h2 className="text-white" style={{ fontFamily: "Josefin Sans" }}>
              {title}
            </h2>
          </div>
        </div>
      </div>
    );
  }
};

export default Breadcrumb;
