import React, { useEffect, useRef, useState } from 'react'
import Breadcrumb from '../components/shared/breadcrumb'
import SubDoughnutCharts2 from '../components/subscriptionComponents/subDonughnutCharts2';
import SubDoughnutCharts from '../components/subscriptionComponents/subDoughnutCharts';
import SubscriptionCards from '../components/subscriptionComponents/subscriptionCards';
import SubscriptionGraph from '../components/subscriptionComponents/subscriptonGraph';
import AdminDashboardLayout from '../layouts/adminDashboard'
import UserService from '../services/UserService';
import createChannel from '../utils/createChannel';

function Subscription() {

    const channel = useRef(createChannel());
    const apiRequest = channel.current.request;
    const userService = new UserService(apiRequest);

    const [subsDataEarn, setSubsDataEarn] = useState([]);
    const [subsDataLost, setSubsDataLost] = useState([]);
    const [labels, setLabels] = useState([]);
    const [dataToShow, setDataToShow] = useState([]);
    const [active, setActive] = useState([]);
    const [passive, setPassive] = useState([]);
    const [activePackage, setActivePackage] = useState([]);
    const [passivePackage, setPassivePackage] = useState([]);

    const calculateGraphValues = (data) => {
        const dataToShowInCards = [];
        const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
        const resultsEarn = {};
        const resultsLost = {};
        let totalActiveSubscriber = 0;
        let totalCancelledSubscriber = 0;
        let totalActiveYearlyPackages = 0;
        let totalActiveMonthlyPackages = 0;
        let totalPassiveYearlyPackages = 0;
        let totalPassiveMonthlyPackages = 0;
        let activePackages = [0, 0, 0];
        let passivePackages = [0, 0, 0];
        // calculate the actie and passive subscriber counts with their monthly and yearly info
        data?.map((el) => {
            let month = new Date(el.start_date).getMonth();
            let year = new Date(el.start_date).getFullYear();
            let cancelMonth = new Date(el.cancel_date).getMonth();
            let cancelYear = new Date(el.cancel_date).getFullYear();
            let endMonth = new Date(el.end_date).getMonth();
            let endYear = new Date(el.end_date).getFullYear();
            if (el.is_product) {
                if (new Date().getFullYear() >= endYear && endMonth > new Date().getMonth()) {
                    totalActiveSubscriber++;
                }
                if (resultsEarn[months[month] + String(year)]) {
                    resultsEarn[months[month] + String(year)]++;
                } else {
                    resultsEarn[months[month] + String(year)] = 1;
                }
                if (new Date().getFullYear() >= endYear && endMonth < new Date().getMonth()) {
                    totalCancelledSubscriber++;
                    if (resultsLost[months[endMonth] + String(endYear)]) {
                        resultsLost[months[endMonth] + String(endYear)]--;
                    } else {
                        resultsLost[months[endMonth] + String(endYear)] = -1;
                    };
                };
            } else {
                if (el.is_active) {
                    totalActiveSubscriber++;
                    if (el.duration === 12) totalActiveYearlyPackages++;
                    if (el.duration === 1) totalActiveMonthlyPackages++;
                    if (el.id === 2) activePackages[0]++;
                    if (el.id === 3) activePackages[1]++;
                    if (el.id === 4) activePackages[2]++;
                    if (resultsEarn[months[month] + String(year)]) {
                        resultsEarn[months[month] + String(year)]++;
                    } else {
                        resultsEarn[months[month] + String(year)] = 1;
                    }
                } else if (!el.is_active && el.cancel_date) {
                    totalCancelledSubscriber++;
                    if (el.duration === 12) totalPassiveYearlyPackages++;
                    if (el.duration === 1) totalPassiveMonthlyPackages++;
                    if (el.id === 2) passivePackages[0]++;
                    if (el.id === 3) passivePackages[1]++;
                    if (el.id === 4) passivePackages[2]++;
                    if (resultsLost[months[cancelMonth] + String(cancelYear)]) {
                        resultsLost[months[cancelMonth] + String(cancelYear)]--;
                    } else {
                        resultsLost[months[cancelMonth] + String(cancelYear)] = -1;
                    }
                }
            }

        });
        setActivePackage(activePackages);
        setPassivePackage(passivePackages)
        const activ = [totalActiveYearlyPackages, totalActiveMonthlyPackages];
        const cancelled = [totalPassiveYearlyPackages, totalPassiveMonthlyPackages];
        setActive(activ);
        setPassive(cancelled);
        // total earnings and cancellations
        dataToShowInCards.push({ title: 'Total Active Members', amount: totalActiveSubscriber });
        dataToShowInCards.push({ title: 'Total Cancelled Subscriptions', amount: totalCancelledSubscriber });
        // prepare the data for the graph and classify them as earn and lost
        const label = [];
        const subEarn = [];
        Object.keys(resultsEarn).map(key => {
            label.push(key);
            subEarn.push(resultsEarn[key]);
        });
        const maximumEarns = Math.max(...subEarn);
        const indexEarn = subEarn.indexOf(maximumEarns);
        const mostSubscriptionMonth = label[indexEarn]
        dataToShowInCards.push({ title: 'Most Increased Subscriptions', amount: maximumEarns, month: mostSubscriptionMonth.slice(0, mostSubscriptionMonth.length - 4) + ' ' + mostSubscriptionMonth.slice(mostSubscriptionMonth.length - 4) });
        const subLost = [];
        Object.keys(resultsLost).map(key => {
            !label.includes(key) && label.push(key)
            subLost.push(resultsLost[key]);
        });
        const maximumLosts = Math.min(...subLost);
        const indexLost = subLost.indexOf(maximumLosts);
        const mostCancelledMonth = label[indexLost]
        dataToShowInCards.push({ title: 'Most Increased Cancellations', amount: -maximumLosts, month: mostCancelledMonth.slice(0, mostCancelledMonth.length - 4) + ' ' + mostCancelledMonth.slice(mostCancelledMonth.length - 4) });
        setLabels(label);
        setSubsDataEarn(subEarn);
        setSubsDataLost(subLost);
        // calculate the increase rate
        let mostIncreased = {
            mostIncreasedRate: 0,
            mostIncreaseAmount: 0,
            mostIncreasedRateMonth: '',
            mostIncreaseAmountMonth: ''
        };
        subEarn.slice(0, subEarn.length - 1).map((el, index) => {
            if ((subEarn[index + 1] - el) / el > mostIncreased.mostIncreasedRate) {
                mostIncreased.mostIncreasedRate = (subEarn[index + 1] - el) / el
                mostIncreased.mostIncreasedRateMonth = label[index + 1]
            }
            if (subEarn[index + 1] - el > mostIncreased.mostIncreaseAmount) {
                mostIncreased.mostIncreaseAmount = subEarn[index + 1] - el
                mostIncreased.mostIncreaseAmountMonth = label[index + 1]
            }
        });
        dataToShowInCards.push({
            title: 'Most Relatively Increased Subscriptions', amount: mostIncreased.mostIncreaseAmount, month: mostIncreased.mostIncreaseAmountMonth.slice(0, mostIncreased.mostIncreaseAmountMonth.length - 4) + ' ' + mostIncreased.mostIncreaseAmountMonth.slice(mostIncreased.mostIncreaseAmountMonth.length - 4)
        });
        dataToShowInCards.push({ title: 'Most Relatively Increasing Rate Subscriptions', amount: (100 * mostIncreased.mostIncreasedRate).toFixed(2) + '%', month: mostIncreased.mostIncreasedRateMonth.slice(0, mostIncreased.mostIncreasedRateMonth.length - 4) + ' ' + mostIncreased.mostIncreasedRateMonth.slice(mostIncreased.mostIncreasedRateMonth.length - 4) });
        let mostIncreasedCancellation = {
            mostIncreasedRate: 0,
            mostIncreaseAmount: 0,
            mostIncreasedRateMonth: '',
            mostIncreaseAmountMonth: ''
        };
        subLost.slice(0, subLost.length - 1).map((el, index) => {
            if ((subLost[index + 1] - el) / el > mostIncreasedCancellation.mostIncreasedRate) {
                mostIncreasedCancellation.mostIncreasedRate = (subLost[index + 1] - el) / el
                mostIncreasedCancellation.mostIncreasedRateMonth = label[index + 1]
            }
            if (subLost[index + 1] - el < mostIncreasedCancellation.mostIncreaseAmount) {
                mostIncreasedCancellation.mostIncreaseAmount = subLost[index + 1] - el
                mostIncreasedCancellation.mostIncreaseAmountMonth = label[index + 1]
            }
        });
        dataToShowInCards.push({ title: 'Most Relatively Increased Cancellations', amount: -mostIncreasedCancellation.mostIncreaseAmount, month: mostIncreasedCancellation.mostIncreaseAmountMonth.slice(0, mostIncreasedCancellation.mostIncreaseAmountMonth.length - 4) + ' ' + mostIncreasedCancellation.mostIncreaseAmountMonth.slice(mostIncreasedCancellation.mostIncreaseAmountMonth.length - 4) });
        dataToShowInCards.push({ title: 'Most Relatively Increasing Rate Cancellations', amount: (100 * mostIncreasedCancellation.mostIncreasedRate).toFixed(2) + '%', month: mostIncreasedCancellation.mostIncreasedRateMonth.slice(0, mostIncreasedCancellation.mostIncreasedRateMonth.length - 4) + ' ' + mostIncreasedCancellation.mostIncreasedRateMonth.slice(mostIncreasedCancellation.mostIncreasedRateMonth.length - 4) });
        setDataToShow(dataToShowInCards);
    }

    useEffect(() => {
        userService
            .getSubsriptions()
            .then((res) => {
                calculateGraphValues(res.data.data);
            })
            .catch(err => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminDashboardLayout>
            <Breadcrumb title='Abonelikler' />
            <div className='subGraphWrapper'>
                <SubscriptionGraph
                    subsDataEarn={subsDataEarn}
                    subsDataLost={subsDataLost}
                    labels={labels}
                />
                <div className='doughnut'>
                    <SubDoughnutCharts active={active} passive={passive} />
                    <SubDoughnutCharts2 active={activePackage} passive={passivePackage} />
                </div>
                <SubscriptionCards data={dataToShow} />
            </div>
        </AdminDashboardLayout>
    )
}

export default Subscription