import React from "react";

export default function Loading() {
  return (
    <div
      style={{
        width: "15rem",
        margin: "auto",
        marginTop: "2rem",
        textAlign: "center",
      }}
    >
      <img src="/loaderLogo.gif" alt="loading..." />
      <h3>Yükleniyor...</h3>
    </div>
  );
}
