import React, { useRef, useState } from "react";

import { Input, Label, Form, Row, Col, Button, FormGroup } from "reactstrap";
import UserService from "../services/UserService";
import createChannel from "../utils/createChannel";


export default function DataImport() {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;

  const [inputValues, setInputValues] = useState({
    dataDate: "",
    country: "",
    importOption: "",
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleImportData = (e) => {
    e.preventDefault();
    // const domainName = window.location.href;
    setLoading(true);
    const service = new UserService(apiReq);
    service
      .importData({
        date: String(inputValues.dataDate),
        country: inputValues.country,
        importOption: inputValues.importOption,
        // domainName: domainName,
      })
      .then((res) => {
        setLoading(false);
        alert("Başarılı");
      })
      .catch((err) => {
        alert(err);
        setLoading(false);
      });
    // let importData = new UserProxy();
    // importData
    //   .importData(
    //     String(inputValues.dataDate),
    //     inputValues.country,
    //     inputValues.importOption,
    //     domainName
    //   )
    //   .then((res) => {
    //     setLoading(false);
    //     alert(res.data.message);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     alert(err.response.data.message);
    //   });
  };

  React.useEffect(() => { }, []);

  return (
    <div className="container pt-4">
      <h2 className="text-center text-danger mt-5">
        Bu bölüme izinsiz müdahale etmeyiniz!!!!
      </h2>
      <div className="col-12 d-flex justify-content-center">
        <Form onSubmit={handleImportData}>
          <Row>
            <Col xs="4">
              <FormGroup>
                <Label for="dataDate">Veri Tarihi</Label>
                <Input
                  onChange={handleInputChange}
                  value={inputValues.dataDate}
                  type="date"
                  id="dataDate"
                  name="dataDate"
                  required
                ></Input>
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup>
                <Label for="country">Ülke</Label>
                <Input
                  type="select"
                  name="country"
                  id="country"
                  value={inputValues.country}
                  onChange={handleInputChange}
                  required
                >
                  <option value={""} selected>
                    Seçiniz
                  </option>
                  <option value={"us"}>USA</option>
                  <option value={"ger"}>Germany</option>
                  <option value={"uk"}>United Kingdom</option>
                  <option value={"fr"}>France</option>
                  <option value={"ca"}>Canada</option>
                  <option value={"sp"}>Spain</option>
                  <option value={"it"}>Italy</option>
                  <option value={"au"}>Australia</option>
                  <option value={"mx"}>Mexico</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup>
                <Label for="importOption">Import</Label>
                <Input
                  type="select"
                  name="importOption"
                  id="importOption"
                  value={inputValues.importOption}
                  onChange={handleInputChange}
                  required
                >
                  <option value={""} selected>
                    Seçiniz
                  </option>
                  <option value={1}>Tüm veriler</option>
                  <option value={2}>Yalnız CSV</option>
                  <option value={3}>Yalnız JSON</option>
                  <option value={4}>Product Satış</option>
                  <option value={5}>Yalnız Date</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Button
                type="submit"
                className="btn opacity register mb-3 mt-5 p-2"
                disabled={
                  !inputValues.dataDate ||
                  !inputValues.country ||
                  !inputValues.importOption ||
                  loading === true
                }
              >
                Import
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {loading === true && (
        <div className="d-flex justify-content-center flex-column">
          <div className="d-flex justify-content-center">
            <h4>Bu işlem uzun sürecektir! Lütfen bekleyiniz...</h4>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <img
              src="/spinner.gif"
              className=""
              alt="Loading"
              style={{ width: "100px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
