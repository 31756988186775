import React, { useRef, useState } from "react";

import { Input, Label, Form, Row, Col, Button, FormGroup } from "reactstrap";
import UserService from "../services/UserService";
import createChannel from "../utils/createChannel";

export default function BackupDb() {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;

  const [inputValues, setInputValues] = useState({
    dbToBackup: "",
    format: "",
    environment: ""
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleBackupDb = (e) => {
    e.preventDefault();
    setLoading(true);
    const service = new UserService(apiReq);
    // console.log("CLIENT ---------->>", inputValues.dbToBackup, inputValues.format)
    service
      .importBackupDb({
        dbToBackup: inputValues.dbToBackup,
        format: inputValues.format,
        environment: inputValues.environment
      })
      .then((res) => {
        setLoading(false);
        alert("Başarılı!!");
      })
      .catch((error) => {
        setLoading(false)
        console.log("ERROR ---------->>", error.message)
        alert(error.message)
      })
  };

  React.useEffect(() => { }, []);

  return (
    <div className="container pt-4">
      <h2 className="text-center text-danger">Database Backup Al</h2>
      <div className="col-12 d-flex justify-content-center">
        <Form onSubmit={handleBackupDb}>
          <Row>
            <Col xs="4">
              <FormGroup>
                <Label for="environment">Environment</Label>
                <Input
                  type="select"
                  name="environment"
                  id="environment"
                  value={inputValues.environment}
                  onChange={handleInputChange}
                  defaultValue="test"
                  required
                >
                  <option value={""} selected>
                    Seçiniz
                  </option>
                  <option value={"production"}>production</option>
                  <option value={"test"}>test</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup>
                <Label for="dbToBackup">Database</Label>
                <Input
                  type="select"
                  name="dbToBackup"
                  id="dbToBackup"
                  value={inputValues.dbToBackup}
                  onChange={handleInputChange}
                  required
                >
                  <option value={""} selected>
                    Seçiniz
                  </option>
                  <option value={"primarydb"}>primarydb</option>
                  <option value={"primarydbtest"}>primarydbtest</option>
                  <option value={"usadb1"}>usadb1</option>
                  <option value={"usadb2"}>usadb2</option>
                  <option value={"alfadb1"}>alfadb1</option>
                  <option value={"alfadb2"}>alfadb2</option>
                  <option value={"betadb1"}>betadb1</option>
                  <option value={"betadb2"}>betadb2</option>
                  <option value={"gamadb1"}>gamadb1</option>
                  <option value={"gamadb2"}>gamadb2</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup>
                <Label for="format">Format</Label>
                <Input
                  type="select"
                  name="format"
                  id="format"
                  value={inputValues.format}
                  onChange={handleInputChange}
                  required
                >
                  <option value={""} selected>
                    Seçiniz
                  </option>
                  <option value={"backup"}>.backup</option>
                  <option value={"sql"}>.sql</option>
                  <option value={"dump"}>.dump</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xs="12">
              <Button
                type="submit"
                className="btn opacity register mb-3 p-2"
                disabled={
                  !inputValues.dbToBackup ||
                  !inputValues.format ||
                  loading === true
                }
              >
                Backup
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {loading === true && (
        <div className="d-flex justify-content-center flex-column">
          <div className="w-100 d-flex justify-content-center">
            <img
              src="/spinner.gif"
              className=""
              alt="Loading"
              style={{ width: "100px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
