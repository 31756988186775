import React from 'react'

import {
    Chart as ChartJS,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

function SubDoughnutCharts2({ active, passive }) {
    ChartJS.register(
        ArcElement,
        LineElement,
        BarElement,
        PointElement,
        BarController,
        BubbleController,
        DoughnutController,
        LineController,
        PieController,
        PolarAreaController,
        RadarController,
        ScatterController,
        CategoryScale,
        LinearScale,
        LogarithmicScale,
        RadialLinearScale,
        TimeScale,
        TimeSeriesScale,
        Decimation,
        Filler,
        Legend,
        Title,
        Tooltip,
        SubTitle
    );


    const dataActive = {
        labels: ['Web', 'Extension', 'Full'],
        datasets: [
            {
                label: "Subscription",
                fill: true,
                lineTension: 0.2,
                backgroundColor: ["rgb(86,203,255)", "rgb(9,44,95)", "rgb(33,111,223)"],
                borderColor: "black",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#fafafa",
                pointBackgroundColor: ["rgb(86,203,255)", "rgb(9,44,95)", "rgb(33,111,223)"],
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: ["rgb(86,203,255)", "rgb(9,44,95)", "rgb(33,111,223)"],
                pointHoverBorderColor: ["rgb(86,203,255)", "rgb(9,44,95)", "rgb(33,111,223)"],
                pointHoverBorderWidth: 1,
                pointRadius: 4,
                pointHitRadius: 10,
                data: active,
            }
        ],
    };

    const dataPassive = {
        labels: ['Web', 'Extension', 'Full'],
        datasets: [
            {
                label: "Subscription",
                fill: true,
                lineTension: 0.2,
                backgroundColor: ["rgb(86,203,255)", "rgb(9,44,95)", "rgb(33,111,223)"],
                borderColor: "black",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#fafafa",
                pointBackgroundColor: ["rgb(86,203,255)", "rgb(9,44,95)", "rgb(33,111,223)"],
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: ["rgb(86,203,255)", "rgb(9,44,95)", "rgb(33,111,223)"],
                pointHoverBorderColor: ["rgb(86,203,255)", "rgb(9,44,95)", "rgb(33,111,223)"],
                pointHoverBorderWidth: 1,
                pointRadius: 4,
                pointHitRadius: 10,
                data: passive,
            }
        ],
    };

    return (
        <>
            <div className='tile-progress inverted  subsDoughnutGraph'>
                <div className='doughnutTitles'>
                    <h3>Aktif Kullanıcıların Paket Dağılımı</h3>
                    <h5>Web:{active[0]}</h5>
                    <h5>Extension:{active[1]}</h5>
                    <h5>Full:{active[2]}</h5>
                </div>
                <Doughnut data={dataActive} />
            </div>
            <div className='tile-progress inverted  subsDoughnutGraph'>
                <div className='doughnutTitles'>
                    <h3>İptal Edilen Üyeliklerde Paket Dağılımı</h3>
                    <h5>Web:{passive[0]}</h5>
                    <h5>Extension:{passive[1]}</h5>
                    <h5>Full:{passive[2]}</h5>
                </div>
                <Doughnut data={dataPassive} />
            </div>
        </>
    )
}

export default SubDoughnutCharts2;