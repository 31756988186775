import React from "react";
import AdminDashboardLayout from "../layouts/adminDashboard";
import Category from "../components/categotyAndBSR/category";
import Breadcrumb from "../components/shared/breadcrumb";

export default function ExtensionCategory(props) {
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Kategoriler" />
        <div style={{ padding: "1rem 5rem" }}>
          <Category />
        </div>
      </AdminDashboardLayout>
    </div>
  );
}
