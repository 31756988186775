import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from "chart.js";
import { Bar } from "react-chartjs-2";

function SubscriptionGraph({
    subsDataEarn,
    subsDataLost,
    labels,
}) {
    ChartJS.register(
        ArcElement,
        LineElement,
        BarElement,
        PointElement,
        BarController,
        BubbleController,
        DoughnutController,
        LineController,
        PieController,
        PolarAreaController,
        RadarController,
        ScatterController,
        CategoryScale,
        LinearScale,
        LogarithmicScale,
        RadialLinearScale,
        TimeScale,
        TimeSeriesScale,
        Decimation,
        Filler,
        Legend,
        Title,
        Tooltip,
        SubTitle
    );




    const data312 = {
        labels: labels,
        datasets: [
            {
                label: "Subscription",
                fill: true,
                lineTension: 0.2,
                backgroundColor: "rgba(38, 166, 154,.8)",
                borderColor: "rgba(38, 166, 154,1)",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#fafafa",
                pointBackgroundColor: "rgba(38, 166, 154,1)",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "rgba(38, 166, 154,1)",
                pointHoverBorderColor: "#eeeeee",
                pointHoverBorderWidth: 1,
                pointRadius: 4,
                pointHitRadius: 10,
                data: subsDataEarn,
            },
            {
                label: "Cancellation",
                fill: true,
                lineTension: 0.2,
                backgroundColor: "red",
                borderColor: "red",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "red",
                pointBackgroundColor: "red",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "red",
                pointHoverBorderColor: "red",
                pointHoverBorderWidth: 1,
                pointRadius: 4,
                pointHitRadius: 10,
                data: subsDataLost,
            },
        ],
    };

    const options312 = {
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        tooltips: {
            bodySpacing: 4,
            mode: "nearest",
            intersect: 0,
            position: "nearest",
            xPadding: 10,
            yPadding: 10,
            caretPadding: 10,
        },
        responsive: 1,
        scales: {
            yAxis: [
                {
                    categoryPercentage: 0.8,
                    barPercentage: 0.6,
                    maxBarThickness: 12,
                    display: 1,
                    gridLines: {
                        color: "rgba(0, 0, 0, 0.01)",
                    },
                    ticks: {
                        display: true,
                    },
                },
            ],
            xAxis: [
                {
                    display: 1,
                    gridLines: {
                        color: "rgba(0, 0, 0, 0.01)",
                    },
                    ticks: {
                        display: true,
                    },
                },
            ],
        },
        layout: {
            padding: { left: 0, right: 0, top: 0, bottom: 0 },
        },
    };
    return (
        <>
            <div className='row'>
                <div className='col-12 subsGraph'>
                    <div className='tile-progress inverted '>
                        {/* <h3>Monthly Values of Active and Passive Subscribers</h3> */}

                        {labels.length && subsDataEarn.length && subsDataLost.length && (
                            <Bar data={data312} options={options312} height={400} />
                        )}
                    </div>

                </div>
                {/* <div className='col-2'>
                    <h3>Total Active Members:{totalSubs}</h3>
                    <h3>Total Cancelled Subscriptions:{totalCancelledSubs}</h3>
                    <h4>Most Earned Month: {mostEarnedMonth && mostEarnedMonth.slice(0, mostEarnedMonth.length - 4) + ' ' + mostEarnedMonth.slice(mostEarnedMonth.length - 4)} {"=>"} {maximumEarn}</h4>
                    <h4>Most Cancelled Month: {mostCancelledMonth && mostCancelledMonth.slice(0, mostCancelledMonth.length - 4) + ' ' + mostCancelledMonth.slice(mostCancelledMonth.length - 4)} {"=>"} {maximumLost}</h4>
                </div> */}
            </div>

        </>
    )
}

export default SubscriptionGraph;