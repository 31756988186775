import React, { useRef, useState } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Pagination from "./shared/pagination";
import createChannel from "../utils/createChannel";
import UserService from "../services/UserService";
import alertNotification from "../utils/alertNotification";

export default function ServiceSales() {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;

  const successAlert = useRef();
  const errorAlert = useRef();
  const updateSaleError = useRef();
  const updateSaleSuccess = useRef();

  const [sales, setSales] = useState([]);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [saleId, setSaleId] = useState(null);
  const [page, setPage] = useState(1);

  let service = new UserService(apiReq);

  const toggle = (e) => {
    setSaleId(e.target.id);
    setModal(!modal);
    // console.log("sale:>>", saleId)
  };

  const toggleSecond = () => setModal(!modal);

  const getSales = (e) => {
    if (e) {
      e.preventDefault();
    }

    service
      .getSales(page)
      .then((res) => {
        setSales(res.data.data);
      })
      .catch((err) => alertNotification("error", "Hata oluştu!"));
  };

  const handleUpdateSale = () => {
    service
      .updateSalesStatus(saleId)
      .then((res) => {
        alertNotification("success", "Satış güncelleme başarılı");
        getSales();
      })
      .catch((err) => alertNotification("error", "Hata oluştu!"));

    toggleSecond();
  };

  //BURASI ZATEN YORUMDAYDI
  // const handleCancelMember = () => {
  //   let cancelPackage = new UserProxy();
  //   cancelPackage.cancelPackageAdmin(userId).then((res) => {
  //     if (res.data.message === "success") {
  //       getMembers();
  //       var optionsSuccess = {};
  //       optionsSuccess = {
  //         place: "tc",
  //         message: (
  //           <div className="notification-msg">
  //             <div>
  //               İptal Başarıyla Sağlandı.
  //             </div>
  //           </div>
  //         ),
  //         type: "success",
  //         icon: "",
  //         autoDismiss: 7
  //       }
  //       successAlert.current.notificationAlert(optionsSuccess);
  //     }
  //   })
  //     .catch((err) => {
  //       var optionsError = {};
  //       optionsError = {
  //         place: "tc",
  //         message: (
  //           <div className="notification-msg">
  //             <div>
  //               {err.response.data.message}
  //             </div>
  //           </div>
  //         ),
  //         type: "danger",
  //         icon: "",
  //         autoDismiss: 7
  //       }
  //       errorAlert.current.notificationAlert(optionsError);
  //     })
  //   toggleSecond1()
  // }

  React.useEffect(() => {
    getSales();
  }, [modal, page]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Sipariş Durum Güncelleme</ModalHeader>
        <ModalBody style={{ color: "red", fontSize: "1.2rem" }}>
          Hizmet tamamlandı/gönderildi olarak işaretlenecektir. Onaylıyor
          musunuz?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdateSale}>
            Onayla
          </Button>
          <Button color="secondary" onClick={toggle}>
            İptal Et
          </Button>
        </ModalFooter>
      </Modal>
      {/* BURASI ZATEN YORUMDAYDI <Modal isOpen={modal1} toggle={toggle1} >
        <ModalHeader toggle={toggle1}>Abonelik iptali</ModalHeader>
        <ModalBody style={{ color: "red", fontSize: "1.2rem" }}>
          Abonelik iptal edilecektir. Onaylıyor musunuz?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCancelMember} >Onayla</Button>{' '}
          <Button color="secondary" onClick={toggle1}>İptal Et</Button>
        </ModalFooter>
  </Modal>*/}
      <ToastContainer />
      <Table striped>
        <thead>
          <tr>
            <th>Id</th>
            <th>Email</th>
            <th>İsim </th>
            <th>Soyisim</th>
            <th>Hizmet</th>
            <th>Miktar</th>
            <th>Durum</th>
            <th>Oluşturma Tarihi</th>
            <th>Gönderim Tarihi</th>
            <th>Açıklamalar</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {sales.map((sale) => {
            return (
              <tr>
                <th>{sale.userInfoId}</th>
                <td>
                  <NavLink to={`/user-details/${sale.userInfoId}`}>
                    <span style={{ color: "blue" }}>
                      {sale.user_info === null ? "-" : sale.user_info.email}
                    </span>
                  </NavLink>
                </td>
                <td>{sale.user_info === null ? "-" : sale.user_info.isim}</td>
                <td>{sale.user_info === null ? "-" : sale.user_info.soyad}</td>
                <td>{sale.service ? sale.service.name : "-"}</td>
                <td>{sale.service ? sale.service.price : "-"} TL</td>
                <td>
                  {sale.is_delivered === true ? (
                    <span style={{ color: "green" }}>Gönderildi</span>
                  ) : (
                    <span style={{ color: "red" }}>Gönderilmedi</span>
                  )}
                </td>
                <td>{new Date(sale.createdAt).toLocaleString()}</td>
                <td>
                  {sale.delivery_date
                    ? new Date(sale.delivery_date).toLocaleString()
                    : "-"}
                </td>

                <td>{sale.remarks}</td>
                <td>
                  <button
                    className="btn btn-sm btn-success mx-1 my-1"
                    style={{ display: sale.is_delivered ? "none" : "" }}
                    id={sale.id}
                    onClick={toggle}
                  >
                    Tamamla
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Pagination page={page} setPage={setPage} />
    </div>
  );
}
