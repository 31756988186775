import API_URL from "../config";

// const DATA_IMPORT_URL = process.env.REACT_APP_PUBLIC_BASE_URL_TEST;

export default class UserService {
  constructor(request) {
    this.request = request;
  }

  // Creating Coupon
  createCoupon(payload) {
    return this.request.post(`${API_URL}/admin/stripe/coupons`, payload)
  }

  // Getting Coupons with Coupon Search
  getDefinedCouponsWithCouponSearch(page, searchingWord) {
    return this.request?.get(`${API_URL}/admin/stripe/coupons?page=${page}&queryesma=${searchingWord}`)
  }

  //Getting Coupons
  getDefinedCoupons(page) {
    return this.request?.get(`${API_URL}/admin/stripe/coupons?page=${page}`)
  }

  // log client errors to db
  logClientErrors(payload) {
    return this.request.post(`${API_URL}/general/errors`, payload);
  }

  // check if user's phone number is verified
  checkGsmVerifyStatus() {
    return this.request?.get(`${API_URL}/users/gsm-numbers`);
  }

  // post user's phone number to db
  postPhoneNumber(payload) {
    return this.request?.post(`${API_URL}/users/gsm-numbers`, payload);
  }

  // check code sent to user's phone number
  verifyGsmNumber(payload) {
    return this.request?.post(`${API_URL}/users/gsm-numbers/verify`, payload);
  }

  // user profile info from db
  getUser() {
    return this.request.get(`${API_URL}/users/profile/me`);
  }

  updateUserInfo(payload) {
    return this.request.patch(`${API_URL}/users/profile`, payload);
  }

  // update password of user to db
  updatePassword(payload) {
    return this.request.post(`${API_URL}/users/profile`, payload);
  }

  //get user's subscriptions
  getPackageInfo() {
    return this.request.get(`${API_URL}/users/subscriptions`);
  }

  // cancel user's package
  cancelPackage(payload) {
    return this.request.patch(`${API_URL}/admin/users/members/${payload}`);
  }

  // retry payment
  retryPayment() {
    // return this.request.patch(`${API_URL}/admin/users/members/${payload}`)
  }

  //Cancel subscription
  cancelSubscription(payload) {
    return this.request.patch(`${API_URL}/users/subscriptions/${payload}`);
  }

  // send user's feedback about cancellation
  sendFeedback(payload) {
    return this.request.post(
      `${API_URL}/users/subscriptions/feedback`,
      payload
    );
  }

  // get user's billing info
  getBillingInfo(page) {
    return this.request.get(`${API_URL}/users/billing-addresses?page=${page}`);
  }

  // update user's billing info
  updateBillingInfo(payload) {
    return this.request.post(`${API_URL}/users/billing-addresses`, payload);
  }

  // get billing Address for current user
  getBillingAddress() {
    return this.request?.get(`${API_URL}/users/billing-addresses`);
  }

  // check coupon code for Stripe
  checkCouponCode(couponCode) {
    return this.request?.get(
      `${API_URL}/stripe/coupons?couponCode=${couponCode}`
    );
  }

  // request checkout form from stipe subs service
  requestCheckoutForm(payload) {
    return this.request?.post(`${API_URL}/stripe/subscriptions`, payload);
  }

  //update user's tag
  updateUserTags(payload) {
    return this.request?.post(`${API_URL}/users/tags`, payload);
  }

  googleAuth(token) {
    return this.request?.post(`${API_URL}/users/auth/google-login/${token}`);
  }

  createSupport(payload) {
    return this.request?.post(`${API_URL}/general/support`, payload);
  }
  getAdminStats() {
    return this.request?.get(`${API_URL}/admin/general/statistics`);
  }

  getMonthlyRevenue() {
    return this.request?.get(
      `${API_URL}/admin/general/statistics/monthly-revenue`
    );
  }

  getPageStats() {
    return this.request?.get(`${API_URL}/admin/general/statistics/page-stats`);
  }

  getSpecificPageStats(id) {
    return this.request?.get(
      `${API_URL}/admin/general/statistics/page-stats/${id}`
    );
  }

  getAllUsers(query) {
    return this.request?.get(`${API_URL}/admin/users/details?${query}`);
  }

  getUserDetails(payload) {
    return this.request?.get(`${API_URL}/admin/users/details/${payload}`);
  }
  updateUserVerification(id) {
    return this.request?.patch(`${API_URL}/admin/users/details/${id}`);
  }
  getMembers(query) {
    return this.request?.get(`${API_URL}/admin/users/members?${query}`);
  }

  getBillingInfo() {
    return this.request?.get(`${API_URL}/admin/users/bills`);
  }
  getPaymentLogs(page) {
    return this.request?.get(
      `${API_URL}/admin/users/payment-logs?page=${page}`
    );
  }

  getErrorLogs(page) {
    return this.request?.get(
      `${API_URL}/admin/general/error-logs?page=${page}`
    );
  }

  getExtensionErrorLogs(page) {
    return this.request?.get(`${API_URL}/admin/extension/errors?page=${page}`)
  }

  getExtensionErrorLogsWithToolSearch(page, searchingWord) {
    return this.request?.get(`${API_URL}/admin/extension/errors?page=${page}&tool=${searchingWord}`)
  }

  getSales(page) {
    return this.request?.get(`${API_URL}/admin/users/services?page=${page}`);
  }
  updateSalesStatus(id) {
    return this.request?.patch(`${API_URL}/admin/users/services/${id}`);
  }
  getGiftMembers(searchData, page) {
    return this.request?.get(
      `${API_URL}/admin/users/gift-members?page=${page}&searchData=${searchData}`
    );
  }
  addGiftMember(payload) {
    return this.request?.post(`${API_URL}/admin/users/gift-members`, payload);
  }

  pasifyGiftMember(payload) {
    return this.request?.patch(
      `${API_URL}/admin/users/gift-members/${payload}`
    );
  }

  getExtensionCategories() {
    return this.request?.get(`${API_URL}/admin/extension/categories`);
  }

  addExtensionCategory(payload) {
    return this.request?.post(`${API_URL}/admin/extension/categories`, payload);
  }
  updateExtensionCategory(id, name) {
    return this.request?.patch(
      `${API_URL}/admin/extension/categories/${id}`,
      name
    );
  }

  getBsr(categoryId) {
    return this.request?.get(`${API_URL}/admin/extension/bsrs/categories/${categoryId}`);
  }

  addBsr(payload) {
    return this.request?.post(`${API_URL}/admin/extension/bsrs`, payload);
  }

  updateBsr(categoryId, payload) {
    return this.request?.patch(`${API_URL}/admin/extension/bsrs/${categoryId}`, payload);

  }

  createSubCategory(id, payload) {
    return this.request?.post(
      `${API_URL}/admin/extension/categories/${id}/sub-categories`,
      payload
    );
  }
  deleteSubCategory(id) {
    return this.request?.delete(
      `${API_URL}/admin/extension/categories/sub-categories/${id}`
    );
  }

  emailSend(payload) {
    return this.request?.post(`${API_URL}/admin/general/emails`, payload);
  }

  getConnectedDb() {
    return this.request?.get(`${API_URL}/admin/developers/data`);
  }

  // changeEnvironment(payload) {
  //   return this.request?.get(`${API_URL}/admin/developers/data`, payload)
  // }

  importBackupDb(payload) {
    return this.request?.post(`${API_URL}/admin/developers/data`, payload);
  }

  importData(payload) {
    return this.request?.post(`${API_URL}/admin/developers/data/import`, payload);
  }

  deploy() {
    return this.request?.post(`${API_URL}/admin/developers/dev`);
  }

  getEssays() {
    return this.request?.get(`${API_URL}/admin/blog/essays`);
  }

  addEssay(payload) {
    return this.request?.post(`${API_URL}/admin/blog/essays`, payload);
  }

  getBlogCategories() {
    return this.request?.get(`${API_URL}/admin/blog/categories`);
  }

  getSubscriptionTags(page, date, duration) {
    return this.request?.get(
      `${API_URL}/admin/users/tags/subs-tags?page=${page}&date=${date}&duration=${duration}`
    );
  }

  getUserTags(page, type) {
    return this.request?.get(
      `${API_URL}/admin/users/tags/user-tags?page=${page}&type=${type}`
    );
  }

  getRegisterTags(page, phase) {
    return this.request?.get(
      `${API_URL}/admin/users/tags/register-tags?page=${page}&phase=${phase}`
    );
  }

  getUserOffer(page) {
    return this.request?.get(`${API_URL}/admin/general/support?page=${page}`);
  }

  updateInquiryStatus(id, status) {
    return this.request?.patch(`${API_URL}/admin/general/support/${id}`, {
      status: status,
    });
  }

  getLostMembers(page) {
    return this.request?.get(
      `${API_URL}/admin/users/lost-members?page=${page}`
    );
  }

  getSubsriptions() {
    return this.request?.get(`${API_URL}/admin/users/subscriptions`);
  }

  getUserInquiry(page) {
    return this.request?.get(
      `${API_URL}/admin/general/suggestion?page=${page}`
    );
  }

  updateSuggestionInquiryStatus(id, status) {
    return this.request?.patch(`${API_URL}/admin/general/suggestion/${id}`);
  }

  resetPasswordbyAdmin(id) {
    return this.request?.put(`${API_URL}/admin/users/details/${id}`);
  }

  subscriptionRefund(id) {
    return this.request?.patch(`
    ${API_URL}/admin/users/payment-logs/${id}`);
  }
}
