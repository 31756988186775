import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom"

import AdminDashboardLayout from "../layouts/adminDashboard";
import Bsr from "../components/categotyAndBSR/bsr";
import Breadcrumb from "../components/shared/breadcrumb";

import Loading from "../components/tools/loading";
import createChannel from "../utils/createChannel";
import UserService from "../services/UserService";

export default function ExtensionBsr(props) {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;
  const service = new UserService(apiReq);

  console.log('SERVICE', service)


  const [bsr, setBsr] = useState(null);
  const { categoryName, id } = useParams();
  console.log('ID', id, categoryName)

  //console.log("params", params);

  function getBsr() {
    service.getBsr(id)
      .then((res) => {
        console.log('DATA', res.data)
        setBsr(res.data.data)
      })

    // let bsr = new UserProxy();
    // bsr.getBsr().then((res) => {
    //   //console.log("bsr", res.data);
    //   setBsr(res.data);
    // });
  }

  React.useEffect(() => {
    getBsr();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="BSR" />
        <div style={{ padding: "1rem 5rem" }}>
          {!bsr ? <Loading /> : <Bsr
            catId={id}
            catName={categoryName}
            bsr={bsr}
          />}
        </div>
      </AdminDashboardLayout>
    </div>
  );
}
