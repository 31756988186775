import React from "react";
import { Routes, Route } from "react-router-dom";
// import   Route from "./routes/  Route";
//Public Page
import Login from "./pages/login";
//Admin pages
import Stats from "./pages/stats";
import Users from "./pages/users";
import UserDetails from "./pages/userDetails";
import Members from "./pages/members";
import BillingPanel from "./pages/billingPanel";
import Affiliates from "./pages/affiliates";
import AffiliateDetails from "./pages/affiliateDetails";
import Coupons from "./pages/coupons";
import DiscountedPlans from "./pages/discountedPlans";
import PaymentLogs from "./pages/paymentLogs";
import ErrorLogs from "./pages/errorLogs";
import ExtensionErrorLogs from "./pages/extensionErrorLogs";
import LostMembers from "./pages/lostMembers";
import ProductSales from "./pages/ProductSales";
import ServicePanel from "./pages/servicePanel";
import GiftMembers from "./pages/giftMembers";
import ExtensionCategory from "./pages/extensionCategory";
import ExtensionBsr from "./pages/extensionBsr";
import EmailSend from "./pages/emailSend";
import DataImports from "./pages/DataImports";
import Developer from "./pages/developer";
import Tags from "./pages/tags";
import SubscripTags from "./pages/subscripTag";
import UserTag from "./pages/userTag";
import RegisterTag from "./pages/registerTag";
import AdminSupport from "./pages/supportAdmin";
import SuggestionComplaints from "./pages/SuggestionComplaints";
import AdminRoute from "./routes/AdminRoute";
import DevRoute from "./routes/DevRoute";
import PublicRoute from "./routes/PublicRoute";
import Subscription from "./pages/subscription";
// import io from "socket.io-client";
// import AuthContext from "./context/AuthContext";
import SalesRoute from "./routes/SalesRoute";
// import { SOCKET_URL } from "./config";
// const socket = io.connect(SOCKET_URL);

function App() {
  // const { setCurrentUser } = useContext(AuthContext);
  // useEffect(() => {
  //   if (socket) {
  //     socket.emit('admin', {});
  //     socket.on('admin_display', ({ message }) => {
  //       setCurrentUser(message)
  //     })
  //   }
  // }, [socket]);
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {/* ADMIN ROUTES */}
      <Route element={<AdminRoute />}>
        <Route path="/" element={<Stats />} />
      </Route>
      {/* PUBLIC ROUTES */}
      <Route element={<PublicRoute />}>
        <Route path="/users" element={<Users />} />
        <Route path="/user-details/:id" element={<UserDetails />} />
        <Route path="/members" element={<Members />} />
        <Route path="/billing-panel" element={<BillingPanel />} />
        {/* <Route path="/payment-logs" element={<PaymentLogs />} /> */}
        <Route path="/lost-members" element={<LostMembers />} />
        <Route path="/product-sales" element={<ProductSales />} />
        <Route path="/service-panel" element={<ServicePanel />} />
        <Route path="/gift-members" element={<GiftMembers />} />
        <Route path="/admin-support" element={<AdminSupport />} />
        <Route path="/email-send/:email" element={<EmailSend />} />
        <Route
          path="/suggestion-complaints"
          element={<SuggestionComplaints />}
        />
      </Route>
      {/* DEVELOPER ROUTES */}
      <Route element={<DevRoute />}>
        <Route path="/affiliates" element={<Affiliates />} />
        <Route path="/affiliate-details/:id" element={<AffiliateDetails />} />
        {/* <Route path="/coupons" element={<Coupons />} /> */}
        <Route path="/add-discounted-plan" element={<DiscountedPlans />} />
        <Route path="/error-logs" element={<ErrorLogs />} />
        <Route path="/extension-error-logs" element={<ExtensionErrorLogs />} />
        <Route path="/extension-category" element={<ExtensionCategory />} />
        <Route
          path="/extension-bsr/:categoryName/:id"
          element={<ExtensionBsr />}
        />
        <Route path="/data-import" element={<DataImports />} />
        <Route path="/developer" element={<Developer />} />
        <Route path="/tags" element={<Tags />} />
        <Route path="/tags-subscription" element={<SubscripTags />} />
        <Route path="/tags-user" element={<UserTag />} />
        <Route path="/tags-register" element={<RegisterTag />} />
        <Route path="/subscription" element={<Subscription />} />
      </Route>
      {/* SALES ROUTES */}
      <Route element={<SalesRoute />}>
        <Route path="/payment-logs" element={<PaymentLogs />} />
        <Route path="/coupons" element={<Coupons />} />
      </Route>
    </Routes>
  );
}

export default App;
