import React, { Component } from "react";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";

import { withRouter } from "react-router";

export class MobileNavMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      userData: {},
    };
  }
  checkLogin = () => {
    if (localStorage.getItem("token") != null) {
      let token = localStorage.getItem("token");
      let tokenData = jwt_decode(token);
      if (tokenData.id) {
        this.setState({ isLoggedIn: true, userData: tokenData });
      }
    }
  };
  logout = () => {
    localStorage.removeItem("token");
    // const history = createBrowserHistory();
    // history.push("/");
    window.location.reload();
  };
  componentDidMount() {
    this.checkLogin();
  }
  render() {
    return (
      <nav className="offcanvasNavigation" id="offcanvas-navigation">
        <ul>
          <li className="menuItemHasChildren">
            <Link to={process.env.PUBLIC_URL + "/dashboard-home"}>
              Anasayfa
            </Link>
          </li>

          <li>
            <Link to={process.env.PUBLIC_URL + "/iletisim"}>İletişim</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/free-content"}>Ücretsiz</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/select-service"}>
              Servis Seç
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/blogs"}>Blog</Link>
          </li>
          {this.state.isLoggedIn ? (
            <li className="menuItemHasChildren">
              <Link to={process.env.PUBLIC_URL + "/hesabım"}>Hesabım</Link>
              {/* <ul className="subMenu">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/hesabım"}>PROFİLİM</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/favori-keyword"}>
                    KELİMELERİM
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/favori-asin"}>
                    ASİNLERİM
                  </Link>
                </li>
                <li>
                  <Link onClick={this.logout}>ÇIKIŞ</Link>
                </li>
              </ul> */}
            </li>
          ) : (
            <li className="menuItemHasChildren">
              <Link to={process.env.PUBLIC_URL + "/login"}>Giriş</Link>
            </li>
          )}
        </ul>
      </nav>
    );
  }
}

export default MobileNavMenu;
