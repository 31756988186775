import API_URL from "../config";
export default class IyzicoService {
  constructor(request) {
    this.request = request;
  }
  getAffiliates() {
    return this.request?.get(`${API_URL}/admin/iyzico/affiliates`);
  }
  getAffiliateDetails(payload) {
    return this.request?.get(`${API_URL}/admin/iyzico/affiliates/${payload}`);
  }
  getCoupons() {
    return this.request?.get(`${API_URL}/iyzico/coupons/all-coupons`);
  }
  getPlanDurations() {
    return this.request?.get(`${API_URL}/general/packages/plans`);
  }

  getPackages() {
    return this.request?.get(`${API_URL}/general/packages`);
  }
}
