import React from "react";
import AdminDashboardLayout from "../layouts/adminDashboard";
import ServiceSales from "../components/serviceSales";
import Breadcrumb from "../components/shared/breadcrumb";

export default function ServicePanel(props) {
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Hizmetler Paneli" />
        <ServiceSales />
      </AdminDashboardLayout>
    </div>
  );
}
