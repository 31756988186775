import React, { useState, useEffect } from "react";

import { Row, Col, Container, FormGroup, Label, Input } from "reactstrap";

export default function IyzicoCurrentPlans(props) {
  const [productForListing, setProductForListing] = useState([]);
  const [products, setProducts] = useState([]);

  const getIyzicoProducts = () => {
    // const products = new UserProxy();
    // products.getIyzicoProducts().then((res) => {
    //   setProducts(res.data.filter((el) => el.name !== "Free"));
    // });
  };
  const getCurrentPlans = (e) => {
    // const products = new UserProxy();
    // products.getIyzicoProductPlans(e.target.value).then((res) => {
    //   setProductForListing(res.data.data.items);
    // });
  };
  useEffect(() => {
    getIyzicoProducts();
  }, []);

  return (
    <Container fluid="lg" className="bg-light border">
      <Row>
        <Col className="text-center">
          <h1>İyzicoda Bulunan Mevcut Planlar</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup bsSize="sm">
            <Label for="productReferenceCode">Ürün</Label>
            <Input
              className="px-3 m-0"
              type="select"
              name="productReferenceCode"
              id="productReferenceCode"
              bsSize="sm"
              onChange={getCurrentPlans}
              required
            >
              <option label>Ürün Seçiniz...</option>
              {products.map((el, key) => (
                <option value={el.product_referance}>{el.name}</option>
              ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="1">#</Col>
        <Col xs="3">Ad</Col>
        <Col xs="2">Tür</Col>
        <Col xs="2">Ücret</Col>
        <Col xs="4">Reference</Col>
      </Row>
      <hr />
      {productForListing
        ?.sort((a, b) => {
          let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        })
        .map((el, key) => {
          return (
            <>
              <Row>
                <Col xs="1">{key + 1}</Col>
                <Col xs="3">{el.name}</Col>
                <Col xs="2">{el.paymentInterval}</Col>
                <Col xs="2">
                  {el.price} {el.currencyCode}
                </Col>
                <Col xs="4">{el.referenceCode}</Col>
              </Row>
              <hr />
            </>
          );
        })}
    </Container>
  );
}
