import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Nav,
  NavLink,
  NavItem,
  TabPane,
  TabContent,
  Row,
  Col,
  Label,
} from "reactstrap";
// import classnames from "classnames";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../tools/loading";
import createChannel from "../../utils/createChannel";
import UserService from "../../services/UserService";
import alertNotification from "../../utils/alertNotification";

const Category = () => {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState();
  const [categoryName, setCategoryName] = useState("");
  const [country, setCountry] = useState("");

  const toggleModal1 = () => {
    setIsModalOpen1(!isModalOpen1);
    setCategoryName(null);
    setSubCategoryName(null);
  };
  const toggleModal2 = () => {
    setIsModalOpen2(!isModalOpen2);
  };
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const groupBy = (arr, prop) => {
    const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
    arr.forEach((obj) => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
  };
  const getCategories = () => {
    const service = new UserService(apiReq);
    service
      .getExtensionCategories()
      .then((res) => {
        setAllCategories(res.data.data);
        setActiveTab(res.data.data[0].country);
      })
      .catch((err) => console.log(err));
  };

  const addCategory = (e) => {
    e.preventDefault();

    const service = new UserService(apiReq);
    console.log("çalışıtttttııı");
    service
      .addExtensionCategory({
        categoryName: categoryName.trim(),
        country: country.trim(),
      })
      .then((res) => {
        alertNotification("success", "Kategori başarıyla eklendi!");
      })
      .catch((err) => {
        alertNotification("error", "Kategori eklenemedi!");
      });
  };
  const updateCategory = (e) => {
    e.preventDefault();

    const service = new UserService(apiReq);
    service
      .updateExtensionCategory(selectedCategoryId, {
        newCategoryName: selectedCategoryName.trim(),
      })
      .then((res) => {
        if (res.data[0] === 1) {
          for (let i in allCategories) {
            if (allCategories[i].id === selectedCategoryId) {
              allCategories[i].category_name = selectedCategoryName.trim();
              break;
            }
          }
          setAllCategories(allCategories);
        }
        alertNotification("succes", "Kategori güncellendi");
      })
      .then(() => toggleModal2())
      .catch((err) => {
        alertNotification("error", "Hata oluştu");
      });
  };

  const addSubCategory = (e) => {
    e.preventDefault();

    console.log("selectedCountry", selectedCountry);

    const service = new UserService(apiReq);
    service
      .createSubCategory(selectedCategoryId, {
        categoryName: subCategoryName.trim(),
        country: selectedCountry,
      })
      .then((res) => {
        allCategories.map((item) => {
          if (
            item.country === selectedCountry &&
            item.category_name === selectedCategoryName
          ) {
            return item.category_subs.push(res?.data?.data);
          } else return;
        });

        alertNotification("success", "Alt kategori oluşturuldu");
      })
      .then(() => {
        setSubCategoryName(null);
        toggleModal1();
      })
      .catch((err) => {
        alertNotification("error", "Hata oluştu!");
      });
  };

  const deleteSubCategory = () => {
    const service = new UserService(apiReq);
    service
      .deleteSubCategory(selectedSubCategoryId)
      .then((res) => {
        alertNotification("success", "Alt kategori başarıyla silindi");
      })
      .catch((err) => {
        alertNotification("error", "Hata oluştu");
      });
  };

  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "country") {
      setCountry(value);
    } else if (name === "categoryName") {
      setCategoryName(value);
    } else if (name === "selectedCategoryName") {
      setSelectedCategoryName(value);
    } else {
      setSubCategoryName(value);
    }
  };

  const grouppedCategories = groupBy(allCategories && allCategories, "country");

  grouppedCategories.forEach((element) => {
    element.sort((a, b) => (a.category_name > b.category_name ? 1 : -1));
  }); //sort by category_name in alphabetical order

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <ToastContainer />
      <Form
        onSubmit={addCategory}
        className="container formMedia asinForm d-flex justify-content-center"
      >
        <Input
          onChange={handleInputChange}
          type="select"
          id="selectCountry"
          name="country"
        >
          <option value="">Ülke Seçiniz</option>
          <option value="usa">USA</option>
          <option value="germany">Germany</option>
          <option value="britain">Britain</option>
          <option value="france">France</option>
          <option value="spain">Spain</option>
          <option value="italy">Italy</option>
          <option value="holland">Holland</option>
          <option value="canada">Canada</option>
          <option value="australia">Australia</option>
          <option value="uae">UAE</option>
          <option value="singapore">Singapore</option>
          <option value="mexico">Mexico</option>
        </Input>
        <Input
          onChange={handleInputChange}
          id="addCategory"
          className="form-control "
          type="text"
          placeholder="Kategori"
          name="categoryName"
          disabled={!country}
        />

        <Label htmlFor="categoryName">
          <small>
            Uzantıdaki kategori ismiyle birebir aynı olmasına dikkat ediniz!
            Büyük/küçük harf ve simgeler önemli!
          </small>
        </Label>
        <Button
          id="addCategoryButton"
          type="submit"
          className="btn p-2 text-align-center register"
          disabled={!country || !categoryName}
        >
          KATEGORİ EKLE
        </Button>
      </Form>
      <div className="d-flex">
        {grouppedCategories.length &&
          grouppedCategories.map((element) => {
            return (
              <Nav tabs className="horizontal left-aligned">
                <NavItem>
                  <NavLink
                    // className={
                    //   classnames({
                    //   active: activeTab == element[0].country,
                    // })}
                    onClick={() => {
                      toggleTab(element[0].country);
                    }}
                  >
                    {element[0].country.toUpperCase()}
                  </NavLink>
                </NavItem>
              </Nav>
            );
          })}
      </div>
      {!grouppedCategories.length ? (
        "Kategori Ekleyin"
      ) : grouppedCategories.length && activeTab ? (
        <TabContent activeTab={activeTab}>
          <TabPane tabId={activeTab}>
            <Row>
              <Col sm="12">
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Kategori İsmi</th>
                      <th>
                        Alt Kategoriler
                        <br />
                        <small>
                          Uzantıdaki kategori ismiyle <br />
                          birebir aynı olmasına dikkat ediniz!
                          <br />
                          Büyük/küçük harf ve simgeler önemli!
                        </small>
                      </th>
                      <th>Ülke</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activeTab
                      ? grouppedCategories
                        .filter(
                          (category) => category[0].country === activeTab
                        )[0]
                        .map((mainCategory, i) => {
                          const {
                            id,
                            category_name,
                            country,
                            category_subs,
                          } = mainCategory;
                          return (
                            <tr key={id}>
                              <td>{i + 1} </td>
                              <td>
                                {" "}
                                <Link
                                  to={`/extension-bsr/${category_name}/${id}`}
                                >
                                  {category_name}
                                </Link>{" "}
                                <br />
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    toggleModal2();
                                    setSelectedCategoryId(id);
                                    setSelectedCategoryName(category_name);
                                  }}
                                >
                                  <i className="fas fa-edit"></i>
                                </span>
                              </td>
                              <td>
                                {category_subs &&
                                  category_subs.map((subCategory, i) => {
                                    return (
                                      <span>
                                        {i +
                                          1 +
                                          ")" +
                                          subCategory.category_name}{" "}
                                        {selectedSubCategoryId ===
                                          subCategory.id ? (
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={deleteSubCategory}
                                          >
                                            <i className="fas fa-check"></i>
                                          </span>
                                        ) : (
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              setSelectedSubCategoryId(
                                                subCategory.id
                                              );
                                            }}
                                          >
                                            <i className="fas fa-remove"></i>
                                          </span>
                                        )}
                                        <br />
                                      </span>
                                    );
                                  })}
                                <br />
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    toggleModal1();
                                    setSelectedCategoryId(id);
                                    setSelectedCategoryName(category_name);
                                    setSelectedCountry(country);
                                  }}
                                >
                                  <i className="fas fa-plus-square"></i>
                                </span>
                              </td>
                              <td>{country.toUpperCase()}</td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      ) : (
        <Loading />
      )}

      <Modal isOpen={isModalOpen1} toggleModal={toggleModal1}>
        <ModalHeader toggleModal={toggleModal1}>
          <bold>{selectedCategoryName}</bold> için alt kategori ekle
        </ModalHeader>
        <ModalBody>
          <Form inline onSubmit={addSubCategory}>
            <Input
              onChange={handleInputChange}
              id="addSubCategory"
              className="form-control "
              type="text"
              placeholder="Alt Kategori"
              name="subCategoryName"
            // invalid={
            //   errors.categoryName !== "" || errors.subCategoryName !== ""
            // }
            />
            {/* <FormFeedback>{errors.subCategoryName}</FormFeedback>
              <FormFeedback>{errors.categoryName}</FormFeedback> */}

            <Button
              // disabled={
              //   !selectedSubCategoryName || this.state.subCategoryName === ""
              //   // ||
              //   // errors.categoryName !== "" ||
              //   // errors.subCategoryName !== ""
              // }
              color="primary"
              type="submit"
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal1}>
            İPTAL
          </Button>
        </ModalFooter>
      </Modal>
      {/*// update modal // */}
      <Modal isOpen={isModalOpen2} toggleModal={toggleModal2}>
        <ModalHeader toggleModal={toggleModal2}>
          <bold>{selectedCategoryName}</bold> Güncelle
        </ModalHeader>
        <ModalBody>
          <Form inline onSubmit={updateCategory}>
            <Input
              onChange={handleInputChange}
              id="updateCategory"
              className="form-control "
              type="text"
              value={selectedCategoryName}
              placeholder="Yeni Kategori İsmi"
              name="selectedCategoryName"
            />

            <Button
              disabled={!selectedCategoryId || selectedCategoryName === ""}
              color="primary"
              type="submit"
            >
              <i className="fas fa-edit"></i>
            </Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal2}>
            İPTAL
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Category;
