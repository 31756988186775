import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
//import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromRaw } from "draft-js";
import AdminDashboardLayout from "../layouts/adminDashboard";
import Breadcrumb from "../components/shared/breadcrumb";
import Card from "@mui/material/Card";
import { useParams } from "react-router-dom";
import createChannel from "../utils/createChannel";
import UserService from "../services/UserService";
import "react-toastify/dist/ReactToastify.css";
import alertNotification from "../utils/alertNotification";
const content = {
  entityMap: {},
  blocks: [
    {
      key: "637gr",
      text: "Initialized from content state.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
};

const EmailSend = (props) => {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [contentState, setContentState] = useState();
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [target, setTarget] = useState("");
  const [support, setSupport] = useState("");

  const params = useParams();

  const service = new UserService(apiReq);
  const sendEmail = () => {
    service
      .emailSend({ email: email, title: title, content: content })
      .then((res) => {
        alertNotification("success", "Mail gönderildi");

        setModal(!modal);
        setEmail("");
        setTitle("");
      })
      .catch((err) => {
        alertNotification("error", "Hata oluştu");
      });
  };

  const toggleClose = () => {
    setModal(!modal);
    setContent("");
  };

  const toggle = () => {
    const newContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setModal(!modal);
    setContent(newContent);
  };

  const onEditorStateChange = (content) => {
    setEditorState(content);
  };

  const onContentStateChange = (content) => {
    setContentState(content);
  };

  const sendToUsers = (target, group) => {
    setEmail(group);
    setTarget(target);
  };

  useEffect(() => {
    if (params.email !== "0") {
      setEmail(params.email);
    }
  }, []);

  return (
    <AdminDashboardLayout>
      <Breadcrumb title="Email Oluşturma" />
      <ToastContainer />
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card
                style={{ borderRadius: "10px", width: "90%", margin: "auto" }}
              >
                <div className="col-12">
                  <section className="box ">
                    <header className="panel_header">
                      <h2 className="title float-left">Mail Gönder</h2>
                    </header>
                    <div className="content-body">
                      <button
                        style={{ background: "#2d3748", color: "white" }}
                        onClick={() => sendToUsers("All", "0")}
                        className="btn mb-2 rounded"
                      >
                        Herkese Gönder
                      </button>
                      <button
                        style={{ background: "#2d3748", color: "white" }}
                        onClick={() => sendToUsers("First", "1")}
                        className="btn mb-2 rounded"
                      >
                        Üyelere Gönder
                      </button>
                      <button
                        style={{ background: "#2d3748", color: "white" }}
                        onClick={() => sendToUsers("Second", "2")}
                        className="btn mb-2 rounded"
                      >
                        2.Gruba Gönder
                      </button>
                      <button
                        style={{ background: "#2d3748", color: "white" }}
                        onClick={() => sendToUsers("Third", "3")}
                        className="btn mb-2 rounded"
                      >
                        3.Gruba Gönder
                      </button>
                      <div className=" mb-2">
                        <label>To:</label> <br />
                        {params.email === "0" ? (
                          <input
                            type="text"
                            value={target}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setTarget(e.target.value);
                            }}
                            className="col-lg-12"
                          />
                        ) : (
                          <input
                            type="text"
                            value={email}
                            defaultValue={email}
                            // onClick={() => {
                            //   setEmail(email);
                            //   setTarget(email);
                            // }}
                            className="col-lg-12"
                          />
                        )}
                      </div>
                      <div className=" mb-3">
                        <label>Konu:</label> <br />
                        <input
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          className="col-lg-12"
                        />
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div>
                              <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onEditorStateChange}
                              />
                            </div>
                          </div>
                          <Modal isOpen={modal} toggle={toggle}>
                            <ModalHeader toggle={toggle}>
                              Bu Maili Göndermek İstediğinize Emin Misiniz?
                            </ModalHeader>
                            <ModalBody>{content}</ModalBody>
                            <ModalFooter>
                              <Button color="primary" onClick={sendEmail}>
                                Gönder
                              </Button>{" "}
                              <Button color="secondary" onClick={toggleClose}>
                                İptal
                              </Button>
                            </ModalFooter>
                          </Modal>
                          {/* <FormGroup>
                                                            <Label htmlFor="exampleText">HTML Format</Label>
                                                            <textarea className="form-control"
                                                                disabled
                                                                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                                            />
                                                        </FormGroup> */}
                          <br />
                        </div>
                      </div>
                      <button
                        className="btn rounded mb-4"
                        style={{
                          background: "#2d3748",
                          color: "white",
                          float: "right",
                        }}
                        onClick={toggle}
                      >
                        {" "}
                        Gönder
                      </button>
                    </div>
                  </section>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </AdminDashboardLayout>
  );
};

export default EmailSend;
