import React, { useState, useEffect, useRef } from "react";

import { NavLink } from "react-router-dom";
import AdminDashboardLayout from "../layouts/adminDashboard";
import Breadcrumb from "../components/shared/breadcrumb";
import { Row, Col, Container, Table } from "reactstrap";
import { AddAffiliate } from "../components/addAffiliate";
import IyzicoService from "../services/IyzicoService";
import createChannel from "../utils/createChannel";

export default function Affiliates(props) {
  const channel = useRef(createChannel());
  const apiRequest = channel.current.request;

  const [affiliates, setAffiliates] = useState([]);

  const service = new IyzicoService(apiRequest);
  const getAffiliates = () => {
    service
      .getAffiliates()
      .then((res) => setAffiliates(res.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAffiliates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Affiliates" />
        <Container fluid="lg">
          <Row className="border rounded">
            <Col>
              <Container className="my-4" fluid="lg">
                <AddAffiliate
                  style={{ border: "none" }}
                  getAffiliates={getAffiliates}
                />
              </Container>
            </Col>
          </Row>
          <Row className="border rounded py-2 px-2 mt-3 bg-info">
            <Col>
              <Container
                className="bg-light text-dark  border rounded px-4"
                fluid="lg"
              >
                <Row>
                  <Col>
                    <Table
                      className="mt-3 h6 table-hover"
                      style={{ overflow: "hidden" }}
                      bordered
                      striped
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Country</th>
                          <th>Phone</th>
                          <th>IBAN</th>
                          <th>Apply</th>
                        </tr>
                      </thead>
                      <tbody>
                        {affiliates?.map((el, key) => {
                          return (
                            <tr>
                              <th scope="row">{key + 1}</th>
                              <td>
                                {el.name} {el.surname}
                              </td>
                              <td>{el.country}</td>
                              <td>{el.gsm_number}</td>
                              <td>{el.iban}</td>
                              <td>
                                <NavLink to={`/affiliate-details/${el.id}`}>
                                  <button className="btn btn-sm btn-info btn-primary">
                                    Details
                                  </button>
                                </NavLink>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </AdminDashboardLayout>
    </div>
  );
}
