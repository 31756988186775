import React from "react";

export default function GiftMemberAdd({
  handleInputChange,
  handleAddGiftMember,
  inputValues,
}) {
  React.useEffect(() => {}, []);

  return (
    <div className="d-flex flex-row p-2 justify-content-center align-items-center mt-4">
      <form
        onSubmit={handleAddGiftMember}
        className="d-flex flex-row justify-content-center mt-4"
      >
        {/* <div className="form-row"> */}
        <div className="form-group col-3">
          <label for="startDate">Başlangıç</label>
          <input
            onChange={handleInputChange}
            value={inputValues.startDate}
            name="startDate"
            id="startDate"
            type="date"
            className="form-control"
          />
        </div>
        <div className="form-group col-3">
          <label for="endDate">Bitiş</label>
          <input
            onChange={handleInputChange}
            value={inputValues.endDate}
            name="endDate"
            id="endDate"
            type="date"
            className="form-control"
          />
        </div>

        <div className="form-group col-2">
          <label for="userId">Kullanıcı Id</label>
          <input
            onChange={handleInputChange}
            value={inputValues.userId}
            name="userId"
            id="userId"
            type="number"
            placeholder="Kullanıcı Id"
            className="form-control"
          />
        </div>
        <div className="form-group col-2">
          <label for="packageId">Paket</label>
          <select
            name="packageId"
            id="packageId"
            onChange={handleInputChange}
            value={inputValues.packageId}
          >
            <option value={2}>Web</option>
            <option value={3}>Extension</option>
            <option value={4}>Full Paket</option>
          </select>
        </div>
        <div className="form-group col-2">
          <button
            disabled={!inputValues.userId}
            type="submit"
            className="btn btn-success mt-4"
          >
            Tanımla
          </button>
        </div>
        {/* </div> */}
      </form>
    </div>
  );
}
