import React, { useRef, useState, useEffect } from "react";

import { Table } from "reactstrap";
import { NavLink } from "react-router-dom";
import Pagination from "./shared/pagination";

import createChannel from "../utils/createChannel";
import UserService from "../services/UserService";

export default function LostMember() {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;
  const [lostMembers, setLostMembers] = useState([]);
  const [page, setPage] = useState(1);

  const service = new UserService(apiReq);

  function getLostMembers(e) {
    if (e) {
      e.preventDefault();
    }

    service
      .getLostMembers(page)
      .then((res) => {
        setLostMembers(res.data.data[0]);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getLostMembers();
  }, [page]);
  return (
    <div>
      <Table striped>
        <thead>
          <tr>
            <th>Id</th>
            <th>Email</th>
            <th>Ad-Soyad</th>
            <th>Paket</th>
            <th>İptal Tarihi</th>
            <th>Geribildirim</th>
          </tr>
        </thead>
        {lostMembers?.length > 0 ? (
          <tbody>
            {lostMembers?.map((i) => {
              return (
                <tr>
                  <th>{i?.user_info_id}</th>
                  <td>
                    <NavLink to={`/user-details/${i?.user_info_id}`}>
                      <span style={{ color: "blue" }}>
                        {i?.user_info === null ? "-" : i?.email}
                      </span>
                    </NavLink>
                  </td>
                  <td>
                    {i?.isim} {i?.soyad}
                  </td>
                  <td>
                    {i?.name} {i?.duration === 1 ? "Aylık" : "Yıllık"}{" "}
                  </td>
                  <td>{new Date(i?.created_at).toLocaleString()}</td>
                  <td>
                    <span style={{ color: "red" }}>{i?.feedback}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <h1>Kaybedilen müşteri yok!</h1>
        )}
      </Table>

      <Pagination page={page} setPage={setPage} />
    </div>
  );
}
