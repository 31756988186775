import React from 'react'
import StatsCard from '../shared/statsCard';

function SubscriptionCards({ data }) {
    console.log(data);
    return (
        <>
            <div className='subCards'>
                {
                    data.length && data.map((el) => (
                        <StatsCard
                            component='subscription'
                            progressColor="primary"
                            title={el.title}
                            count={el.amount}
                            month={el.month}
                        // value={el.amount}
                        />
                    ))
                }
            </div>
        </>
    )
}

export default SubscriptionCards