import React, { useEffect, useRef } from "react";
import { useState } from "react";

import Pagination from "./shared/pagination";
import { NavLink } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import createChannel from "../utils/createChannel";
import UserService from "../services/UserService";
import { ToastContainer } from "react-toastify";
import alertNotification from "../utils/alertNotification";

export default function AdminSupportPage() {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;

  const [user, setUser] = useState([]);
  const [page, setPage] = useState(1);
  // const [color, setColor] = useState("");
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState(false);
  const [isId, setIsId] = useState(null);
  const [view, setView] = useState(false);
  const [theme, setTheme] = useState("");
  const [file, setFile] = useState("");
  const [reply, setReply] = useState("");

  const service = new UserService(apiReq);

  function getUserOffer(e) {
    if (e) e.preventDefault();

    service.getUserOffer(page).then((res) => {
      setUser(res.data.data);
    });
  }

  const toggle = (e) => {
    setModal(!modal);
    setIsId(e.target.id);
    console.log(e.target.id);
    setStatus(true);
  };

  const viewToggle = (e) => {
    setView(!view);
    setTheme(e.target.value);
    setFile(e.target.id);
  };

  const fileType = file?.substring(5, 10);

  const handleUpdateStatus = () => {
    service
      .updateInquiryStatus(isId, status)
      .then((res) => {
        getUserOffer();
      })
      .catch((err) => {
        alertNotification("error", "Hata oluştu!");
      });

    secondToggle();
  };

  const secondToggle = () => {
    setModal(!modal);
  };

  const getEmail = (email, file, content) => {
    setView(!view);
    setTheme(content);
    setFile(file);
    setReply(email);
  };

  useEffect(() => {
    getUserOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div>
      <ToastContainer />
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Destek/Öneri Durum Güncelleme</ModalHeader>
        <ModalBody style={{ color: "red", fontSize: "1.2rem" }}>
          İstek cevaplandı olarak işaretlenecektir. Onaylıyor musunuz?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdateStatus}>
            Onayla
          </Button>
          <Button color="secondary" onClick={toggle}>
            İptal Et
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={view} toggle={viewToggle}>
        <ModalHeader toggle={viewToggle}>Destek/Öneri</ModalHeader>
        <ModalBody>
          {file ? (
            fileType === "image" ? (
              <figure>
                <img src={file} alt="image" />
              </figure>
            ) : (
              <figure>
                <video controls src={file} width="452px" height="360px" />
              </figure>
            )
          ) : (
            ""
          )}
        </ModalBody>
        <ModalBody style={{ fontSize: "1.1rem" }}>{theme}</ModalBody>

        <ModalFooter>
          <NavLink to={`/email-send/${reply}`} target="_blank">
            <Button color="primary">Cevapla</Button>
          </NavLink>

          {/* <Button color="secondary" onClick={toggle}>
            İptal Et
          </Button> */}
        </ModalFooter>
      </Modal>
      <Table striped>
        <thead>
          <tr>
            <th>ID</th>
            <th>EMAIL</th>
            <th>AD-SOYAD</th>
            <th>PLATFORM</th>
            <th>KONU</th>
            <th>İSTEK-ÖNERİ</th>
            <th>DURUM</th>
            <th>İŞLEM</th>
            <th>SONUÇ</th>
          </tr>
        </thead>
        <tbody>
          {user.map((i) => {
            return (
              <tr>
                <td>{i.id}</td>
                <td>
                  <NavLink to={`/user-details/${i.userInfoId}`} target="_blank">
                    <span style={{ color: "blue" }}>
                      {i.email}
                    </span>
                  </NavLink>
                </td>
                <td>
                  {i.user_info === null ? "---" : i.user_info.isim}{" "}
                  {i.user_info === null ? "---" : i.user_info.soyad}
                </td>
                <td>{i.platform}</td>
                <td>
                  {i.topic === "Hesap İşlemleri" || i.topic === "Ödeme" ? (
                    <span style={{ color: "red" }}>{i.topic}</span>
                  ) : (
                    <span>{i.topic}</span>
                  )}
                </td>

                <td>
                  <button
                    className="btn btn-sm btn-warning mx-1 my-1"
                    value={i.content}
                    id={i.file}
                    onClick={() => getEmail(i.email, i.file, i.content)}
                  >
                    Görüntüle
                  </button>
                </td>
                <td>
                  {i.durum === "cevaplandı" ? (
                    <span style={{ color: "green" }}>Cevaplandı</span>
                  ) : (
                    <span style={{ color: "red" }}>Cevaplanmadı</span>
                  )}
                </td>

                <td>
                  <NavLink
                    to={`/email-send/${i.email}`}
                    target="_blank"
                    supportEmail={i.email}
                  >
                    <Button
                      className="btn btn-sm"
                      color="info"
                      disabled={i.durum === "cevaplandı"}
                    >
                      Cevapla
                    </Button>
                  </NavLink>
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-success mx-1 my-1"
                    // color="success"
                    onClick={toggle}
                    id={i.id}
                    value={i.durum}
                    disabled={i.durum === "cevaplandı"}
                  >
                    Bitir
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Pagination page={page} setPage={setPage} />
    </div>
  );
}
