import React from "react";

export default function Pagination(props) {
  return (
    <div>
      <nav ariaLabel="Page navigation example">
        <ul className="pagination">
          <li className="page-item">
            <a
              className="page-link"
              onClick={props.getDetails}
              ariaLabel="Previous"
            >
              <span>&laquo;</span>
              <span className="sr-only">Previous</span>
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              {props.page}
            </a>
          </li>

          <li className="page-item">
            <a
              className="page-link"
              onClick={props.getDetails}
              ariaLabel="Next"
            >
              <span>&raquo;</span>
              <span className="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
