import React, { useRef, useState, useContext, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { Nav } from "reactstrap";
import {
  Navmenudropdown,
  Navmenugroup,
} from "../components/dashboardComponents";
import AuthContext from "../context/AuthContext";

// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
// import useravatar from "../../assets/img/profile.jpg";

// var ps;
var currentmenu = "notset";

// var IMGDIR = process.env.REACT_APP_IMGDIR;

const AdminSidebar = (props) => {
  // console.log(props)
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState("");
  const sideBar = useRef();
  const [route, setRoute] = useState([]);

  // const handlecurrent = (currentmenu) => {
  //   if (open !== "") {
  //     currentmenu = "";
  //   }
  // };

  const handleOpendd = (x) => {
    if (open === x) {
      setOpen("");
    } else {
      setOpen(x);
    }
    currentmenu = "";
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    //active route ı bul!!!
    // return history.location.pathname.indexOf(routeName) > -1 ? " active" : "";
  };

  const children = (child, parent) => {
    var links = [];
    if (child) {
      for (var i = 0; i < child.length; i++) {
        links.push(
          <li key={i}>
            <NavLink
              to={child[i].path}
              className="nav-link"
              activeClassName="active"
            >
              <span>{child[i].name}</span>
            </NavLink>
          </li>
        );

        // if (history.location.pathname === child[i].path) {
        //   if (currentmenu === "notset" && this.state.opendd === "") {
        //     currentmenu = parent; //child[i].parent;
        //   }
        // }

        // if (history.location.pathname === "/") {
        //   currentmenu = "dashboards";
        // }
      }
      return <Nav>{links}</Nav>;
    }
  };
  useEffect(() => {
    if (user?.role === "dwtqXfOhfh") {
      setRoute(props.routes.adminRoutes);
    } else if (user?.role === "devRxvn") {
      setRoute(props.routes.devRoutes);
    } else if (user?.role === 'salesRxvn') {
      setRoute(props.routes.salesRoutes);
    } else {
      setRoute(props.routes.crmRoutes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="sidebar menubar" data-color="black">
      <div className="logo">
        <Link to="/" className="logo-mini">
          <div className="logo-img">
            <img
              src="/logoTitleWhite.png"
              alt="react-logo"
              className="light-logo"
            />
            {/* <img
              src="/logoTitleWhite.png"
              alt="react-logo"
              className="dark-logo"
            /> */}
          </div>
        </Link>
        <Link to="/" className="logo-full">
          <img src="/logo.png" alt="react-logo" className="light-logo" />
          <img src="/logo.png" alt="react-logo" className="dark-logo" />
        </Link>
      </div>

      <div className="sidebar-wrapper" ref={sideBar}>
        <div className="profile-info row">
          <div className="profile-details col-8">
            <h3>
              <span style={{ color: "white" }}>{user.name}</span>
              <span className="profile-status online"></span>
            </h3>
            <p className="profile-title">{user.role}</p>
          </div>
        </div>

        <Nav className="navigation">
          {route.map((prop, key) => {
            if (prop.redirect) return null;
            if (prop.type === "child") return null;
            if (prop.type === "navgroup")
              return <Navmenugroup name={prop.name} key={key}></Navmenugroup>;
            if (prop.type === "dropdown")
              return (
                <li
                  className={
                    prop.parentid +
                    " " +
                    // (prop.parentid === currentmenu &&
                    (open &&
                      prop.parentid !== "" &&
                      prop.parentid !== "multipurpose"
                      ? "active"
                      : "") +
                    " nav-parent "
                  }
                  data-toggle="collapse"
                  key={key}
                >
                  <NavLink
                    to={prop.path}
                    className="nav-link"
                    href="#!"
                    onClick={() => {
                      handleOpendd(prop.name);
                    }}
                  >
                    <i className={"i-" + prop.icon}></i>
                    <p>{prop.name}</p>
                    <span className="badge">{prop.badge}</span>
                    <span className={"arrow i-arrow-left"}></span>
                  </NavLink>
                  {children(prop.child, prop.parentid)}
                </li>
              );

            if (prop.type === "dropdown-backup") {
              console.log("prop", prop);
              return (
                <Navmenudropdown
                  name={prop.name}
                  icon={prop.icon}
                  path={prop.path}
                  badge={prop.badge}
                  child={prop.child}
                  key={key}
                  openclass={open === prop.name ? "activethis" : ""}
                  onClick={() => handleOpendd(prop.name)}
                ></Navmenudropdown>
              );
            }
            return (
              <li
                className={activeRoute(prop.path) + " nav-parent "}
                key={key}
                onClick={() => handleOpendd(prop.name)}
              >
                <NavLink
                  to={prop.path}
                  className="nav-link"
                // activeClassName="active"
                >
                  <i className={"i-" + prop.icon}></i>

                  <p>{prop.name}</p>
                  <span className="badge">{prop.badge}</span>
                </NavLink>
                {children(prop.child, prop.parentid)}
              </li>
            );
          })}
        </Nav>
      </div>
    </div>
  );
};

export default AdminSidebar;
