import React, { Component } from "react";
import { Link } from "react-router-dom";
import { IoIosMenu } from "react-icons/io";
import jwt_decode from "jwt-decode";

export class headerBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      userData: {},
    };
  }

  logout = () => {
    localStorage.removeItem("token");
    // const history = createBrowserHistory();
    // history.push('/')
    window.location.reload();
  };

  triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  checkLogin = () => {
    if (localStorage.getItem("token") !== null) {
      let token = localStorage.getItem("token");
      let tokenData = jwt_decode(token);
      if (tokenData.id) {
        if (this.props.isLoggedIn === false) {
          localStorage.removeItem("token");
          this.setState({ isLoggedIn: false });
        } else {
          this.setState({ isLoggedIn: true, userData: tokenData });
        }
      }
    }
  };

  componentDidMount() {
    this.checkLogin();
  }

  render() {
    const { t } = this.props;
    return (
      <div className="header-btn-wrapper">
        {this.state.isLoggedIn ? (
          <ul className="accounts d-none d-lg-flex mainmenu">
            <li className="active drop">
              <Link to={process.env.PUBLIC_URL + "/hesabım"}>
                Hesabım
                {/* <IoIosArrowDown /> */}
              </Link>
              {/* <ul className="dropdown">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/hesabım"}>Profilim</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/favori-keyword"}>
                    Kelimelerim
              </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/favori-asin"}>
                    Asinlerim
              </Link>
                </li>
                <li onClick={this.logout}>
                  <Link>
                    Çıkış
              </Link>
                </li>
              </ul> */}
            </li>
          </ul>
        ) : null}

        {this.state.isLoggedIn === false ? (
          <ul className="accounts d-none d-lg-flex">
            <li style={{ textAlign: "center" }}>
              <Link to="/kayitol">Kayıt Ol</Link>
            </li>
            <li className="active">
              <Link to="/login">Giriş</Link>
            </li>
          </ul>
        ) : null}
        <div className="mobile-button-wrapper d-block d-lg-none text-right">
          <button
            className="mobile-aside-button"
            onClick={this.triggerMobileMenu}
          >
            <IoIosMenu />
          </button>
        </div>
      </div>
    );
  }
}

export default headerBtn;
