import { useContext } from "react"
import { Navigate, Outlet } from "react-router-dom";
import NotAuthorized from "../components/notAuthorized";
import Loading from "../components/tools/loading";
import AuthContext from "../context/AuthContext"

const SalesRoute = ({ children }) => {
    const { user, isLoading } = useContext(AuthContext);

    if (!isLoading && !user) {
        <Navigate to='/login' />
    } else if (isLoading) {
        return <Loading />
    } else if (
        (user && user?.role === 'dwtqXfOhfh') ||
        (user && user?.role === 'devRxvn') ||
        (user && user?.role === 'salesRxvn')
    ) {
        return <Outlet />
    } else {
        return <NotAuthorized />
    }
}

export default SalesRoute;