import AdminDashboardLayout from '../layouts/adminDashboard'
import Breadcrumb from '../components/shared/breadcrumb'
import { Card, Col, Container, Form, Row } from 'reactstrap'
import UserService from '../services/UserService';
import { useState, useRef } from 'react';
import createChannel from '../utils/createChannel';
import CouponTable from '../components/couponsTable';




const Coupons = () => {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;
  const service = new UserService(apiReq);

  const createCoupons = (payload) => {
    // console.log('payload', payload);
    service.createCoupon(payload).then((res) => {
      console.log('res', res);
    })
  }

  const [repatingInfo, setRepatingInfo] = useState(true)

  const [couponValues, setCouponValues] = useState({
    percentOff: '',
    couponId: '',
    affiliateName: '',
    usageLimit: '',
    duration: 'forever',
    durationMonths: '',
    planName: ''
  })
  const handleCouponSubmit = (e) => {
    e.preventDefault();
    // console.log('couponValues', couponValues);
    createCoupons(couponValues);
    setCouponValues({
      percentOff: '',
      couponId: '',
      affiliateName: '',
      usageLimit: '',
      duration: 'forever',
      durationMonths: '',
      planName: ''
    })
  }

  const handleCouponInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'percentOff') {
      setCouponValues({ ...couponValues, [name]: +value, planName: 'YUZDE' + value })
    } else if (name === 'usageLimit' || name === 'durationMonths') {
      setCouponValues({ ...couponValues, [name]: +value })
    } else {
      setCouponValues({ ...couponValues, [name]: value })
    }



    if (name === 'duration') {
      if (value === 'repeating') {
        setRepatingInfo(false)
      } else {
        setRepatingInfo(true)
      }
    }

  }

  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Coupons" />

        <Row className='border rounded'>
          <Col xs="3">
            <Container fluid="lg" className='bg-light border'>
              <h1>Kupon Tanımla</h1>
              <Form onSubmit={handleCouponSubmit}>

                <div className='form-group'>
                  <Row>
                    <Col>
                      <label htmlFor='couponId'>Kupon</label>
                      <input onChange={handleCouponInputChange} value={couponValues.couponId} type="text" max={99} className="form-control form-control-sm" name='couponId' placeholder='Coupon' required />
                    </Col>
                  </Row>
                </div>

                <div className='form-group'>
                  <Row>
                    <Col>
                      <label htmlFor='percentage'>Kupon Yüzdesi</label>
                      <input onChange={handleCouponInputChange} value={couponValues.percentOff} type="number" max="100" className="form-control form-control-sm" name='percentOff' placeholder='Kupon Yüzdesi' required />
                    </Col>
                  </Row>
                </div>


                <div className='form-group'>
                  <Row>
                    <Col>
                      <label htmlFor='planName'>Plan Adı</label>
                      <input disabled onChange={handleCouponInputChange} value={couponValues.planName} type="text" className="form-control form-control-sm" name='planName' placeholder='Plan Adı' />
                    </Col>
                  </Row>
                </div>

                <div className='form-group'>
                  <Row>
                    <Col>
                      <label htmlFor='duration'>Süre</label>
                      <select style={{ width: '100%' }} className='' name="duration" onChange={handleCouponInputChange}>
                        <option selected value='forever'>Sınırsız</option>
                        <option value='once'>Tek Seferlik</option>
                        <option value='repeating'>Tekrarlı</option>
                      </select>
                    </Col>
                  </Row>
                </div>


                <div className='form-group'>
                  <Row>
                    <Col>
                      <label htmlFor='usageLimit'>Kullanım Limiti</label>
                      <input disabled={repatingInfo} onChange={handleCouponInputChange} value={couponValues.usageLimit} type="number" className="form-control form-control-sm" name='usageLimit' placeholder='Kullanım Limiti' />
                    </Col>
                  </Row>
                </div>

                <div className='form-group'>
                  <Row>
                    <Col>
                      <label htmlFor='planName'>Süre Ay</label>
                      <input disabled={repatingInfo} onChange={handleCouponInputChange} value={couponValues.durationMonths} type="number" className="form-control form-control-sm" name='durationMonths' placeholder='Süre Ay' required />
                    </Col>
                  </Row>
                </div>

                <div className='form-group'>
                  <Row>
                    <Col>
                      <label htmlFor='affialate'>Bağlı Kurum</label>
                      <input onChange={handleCouponInputChange} value={couponValues.affiliateName} type="text" className="form-control form-control-sm" name='affiliateName' placeholder='Bağlı Kurum' />
                    </Col>
                  </Row>
                </div>

                <div className='form-group'>
                  <Row>
                    <Col>
                      <button type='submit' className='btn btn-success' style={{ width: '100%' }}>
                        Kupon Olustur
                      </button>
                    </Col>
                  </Row>
                </div>


              </Form>
            </Container>
          </Col>
          <Col xs="9">
            <Container fluid='lg' className='bg-light border'>
              <h1>Tanımlanmış Kuponlar</h1>
              <Card>
                <CouponTable />
              </Card>
            </Container>
          </Col>
        </Row>

      </AdminDashboardLayout>
    </div>
  )
}

export default Coupons;

