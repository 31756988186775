import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Table } from "reactstrap";

import Pagination from "./shared/paginationUserTag";
import { Input, Label, Form, Row, Col, FormGroup, Button } from "reactstrap";
import createChannel from "../utils/createChannel";
import UserService from "../services/UserService";

export default function UserTagPage() {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;

  const [page, setPage] = useState(1);
  const [user, setUser] = useState([]);
  const [type, setType] = useState();

  const service = new UserService(apiReq);

  function getUserTag(e) {
    if (e) {
      e.preventDefault();
    }

    service
      .getUserTags(page, type)
      .then((res) => {
        setUser(res.data.data);
      })
      .catch((err) => console.log(err));
  }

  const handleDataChange = (e) => {
    e.preventDefault();
    setType(e.target.value);
    getUserTag();
  };

  function msToTime(duration) {
    var milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  }

  useEffect(() => {
    getUserTag();
  }, [page]);
  return (
    <div>
      <div className="container pt-2">
        <h3 className="text-center text-danger">
          İncelemek İstediğiniz Verileri Seçiniz
        </h3>
        <div className="col-12 d-flex justify-content-center">
          <Form onSubmit={getUserTag}>
            <Row>
              <Col xs="7">
                <FormGroup>
                  <Label for="data">Veri Türü</Label>
                  <Input
                    type="select"
                    name="data"
                    id="data"
                    value={type}
                    onChange={handleDataChange}
                    required
                  >
                    <option>Seçiniz</option>
                    <option value={1}>GSM Doğrulama</option>
                    <option value={2}>E-Book İndirme</option>
                    <option value={3}>Amazon Kursu İzleme</option>
                    <option value={4}>Ürün Araştırma Kursu İzleme</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs="4">
                <Label>Filtre</Label>
                <Button type="submit" className="btn opacity register p-3">
                  Filtrele
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div>
        <Table striped>
          <thead>
            <tr>
              <th>ID</th>
              <th>EMAIL</th>
              <th>AD-SOYAD</th>
              <th>GSM</th>
              <th>TARİH</th>
              <th>EBOOK</th>
              <th>TARİH</th>
              <th>AMAZON KURS</th>
              <th>SÜRESİ-SON GÖRÜNME</th>
              <th>PRODUCT KURS</th>
              <th>SÜRESİ-SON GÖRÜNME</th>
            </tr>
          </thead>
          <tbody>
            {user.map((i) => {
              return (
                <tr>
                  <td>{i.id}</td>
                  <td>{i.user_info.email}</td>
                  <td>
                    {i.user_info.isim} {i.user_info.soyad}
                  </td>
                  <td>
                    {i.gsm_verified == true ? (
                      <span style={{ color: "green" }}>Doğrulanmış</span>
                    ) : (
                      "---"
                    )}
                  </td>
                  <td>
                    {i.gsm_verified == true
                      ? new Date(i.gsm_verification_date).toLocaleString()
                      : "---"}
                  </td>
                  <td>
                    {i.e_book == true ? (
                      <span style={{ color: "green" }}>İndirilmiş</span>
                    ) : (
                      "---"
                    )}
                  </td>
                  <td>
                    {i.e_book == true
                      ? new Date(i.e_book_date).toLocaleString()
                      : "---"}
                  </td>
                  <td>
                    {i.amazon_course == true ? (
                      <span style={{ color: "green" }}>İzlenmiş</span>
                    ) : (
                      "---"
                    )}
                  </td>
                  <td>
                    {i.amazon_course == true
                      ? msToTime(Number(i.amazon_course_duration))
                      : "---"}
                    {" / "}
                    {i.amazon_course == true
                      ? new Date(i.amazon_course_latest_action).toLocaleString()
                      : "---"}
                  </td>
                  <td>
                    {i.product_course == true ? (
                      <span style={{ color: "green" }}>İzlenmiş</span>
                    ) : (
                      "---"
                    )}
                  </td>
                  <td>
                    {i.product_course == true
                      ? msToTime(Number(i.product_course_duration))
                      : "---"}
                    {" / "}
                    {i.product_course == true
                      ? new Date(
                          i.product_course_latest_action
                        ).toLocaleString()
                      : "---"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Pagination page={page} setPage={setPage} />
    </div>
  );
}
