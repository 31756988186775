import React from "react";
// import { useEffect } from "react";
import AdminSupportPage from "../components/adminSupportPage";
import Breadcrumb from "../components/shared/breadcrumb";
import AdminDashboardLayout from "../layouts/adminDashboard";

export default function AdminSupport(props) {
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Destek" />
        <AdminSupportPage />
      </AdminDashboardLayout>
    </div>
  );
}
