import React from "react";
import RegisterTagPage from "../components/registerTagPage";
import Breadcrumb from "../components/shared/breadcrumb";
import AdminDashboardLayout from "../layouts/adminDashboard";

export default function RegisterTag() {
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Kayıt İzleri" />
        <RegisterTagPage />
      </AdminDashboardLayout>
    </div>
  );
}
