import React from "react";
import AdminPanel from "../components/adminPanel";
import AdminDashboardLayout from "../layouts/adminDashboard";
import Breadcrumb from "../components/shared/breadcrumb";

export default function Stats(props) {
  // React.useEffect(() => {
  //   if (localStorage.getItem("token") != null) {
  //     props.checkAdminAccess();
  //   }
  // }, []);
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="İstatistikler" />
        <AdminPanel />
      </AdminDashboardLayout>
    </div>
  );
}
