import React from "react";
import AdminDashboardLayout from "../layouts/adminDashboard";
import Breadcrumb from "../components/shared/breadcrumb";
import SubscriptionTag from "../components/subscriptionTag";

export default function SubscripTags(props) {
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Abonelik İzleri" />
        <SubscriptionTag />
      </AdminDashboardLayout>
    </div>
  );
}
