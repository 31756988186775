import React, { useState, useEffect } from "react";

import axios from "axios";

const Payment = () => {
  const [checkout, setCheckout] = useState("");

  useEffect(() => {
    axios.get("http://localhost:8000/api/etsy/sign-in").then((res) => {
      setCheckout(res.data);
    });
  }, []);

  return (
    <div>
      <div>
        {/*<iframe id="if" style={{ width: "100%", height: "985px" }} srcDoc={this.state.checkout} frameBorder="0"></iframe>*/}
        <a href="https://www.etsy.com/oauth/connect?response_type=code&redirect_uri=http://localhost:8000/api/etsy/oauth/redirect&scope=email_r&client_id=ukj651vazq2bxx3kp0kj8f3e&state=v5o7yv&code_challenge=z2wG7O8e_GfxidtJrQa9m1LkL2VW89h0Vps_W78i0Ps&code_challenge_method=S256">
          yusuf ali
        </a>
      </div>
    </div>
  );
};
export default Payment;
