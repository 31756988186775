import { toast } from "react-toastify";

let option = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light"
};

export default function alertNotification(status, message) {
  if (status === "success") {
    toast.success(message, option);
  } else if (status === "error") {
    toast.error(message, option);
  }
}
