import React, { useState } from "react";
import PropTypes from "prop-types";

// import { makeStyles, useTheme } from "@mui/styles";
import {
  // useMediaQuery,
  Grid,
  Typography,
  TextField,
  Button,
  Divider,
} from "@mui/material";

import { ToastContainer } from "react-toastify";


const AddAffiliate = (props) => {
  const [inputValues, setInputValues] = useState({
    name: "",
    surname: "",
    address: "",
    city: "",
    country: "",
    zipCode: "",
    companyName: "",
    taxOffice: "",
    iban: "",
    maxShare: null,
    email: "",
  });
  const [gsmNumber, setGsmNumber] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [identityNumber, setIdentityNumber] = useState("");
  const [gsmError, setGsmError] = useState(false);
  const [idError, setIdError] = useState(false);
  const [taxIdError, setTaxIdError] = useState(false);
  const [maxShareError, setMaxShareError] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isCorporate, setIsCorporate] = useState(false);

  const { className, ...rest } = props;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleGsmChange = (e) => {
    e.preventDefault();
    var x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
    var y = !x[2]
      ? "90 5"
      : "" +
      x[1] +
      " " +
      x[2] +
      (x[3] ? " " + x[3] : "") +
      (x[4] ? " " + x[4] : "") +
      (x[5] ? "" + x[5] : "");
    setGsmNumber(y);
    if (y.length < 15) {
      setGsmError(true);
    } else {
      setGsmError(false);
    }
  };

  const handleIdChange = (e) => {
    e.preventDefault();
    var x = e.target.value.replace(/\D/g, "").match(/(\d{0,11})/);
    var y = !x[2] ? x[1] : "";
    setIdentityNumber(y);
    if (e.target.value === "" || y.length < 11) {
      setIdError(true);
    } else {
      setIdError(false);
    }
  };

  const handleTaxIdChange = (e) => {
    e.preventDefault();
    var x = e.target.value.replace(/\D/g, "").match(/(\d{0,10})/);
    var y = !x[2] ? x[1] : "";
    setTaxNumber(y);
    if (e.target.value === "" || y.length < 10) {
      setTaxIdError(true);
    } else {
      setTaxIdError(false);
    }
  };

  const handleCheckChange = (event) => {
    setIsCorporate(!isCorporate);
  };

  const handleMaxShareChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });

    if (e.target.value < 0 || e.target.value > 1) {
      setMaxShareError(true);
    } else {
      setMaxShareError(false);
    }
  };

  const handleFormSubmit = (
    name,
    surname,
    gsm_number,
    email,
    identity_number,
    address,
    city,
    country,
    zipCode,
    companyName,
    taxNumber,
    taxOffice,
    iban,
    maxShare
  ) => {
    // let createAffiliate = new UserProxy();
    // createAffiliate
    //   .createAffiliate(
    //     name,
    //     surname,
    //     gsm_number,
    //     email,
    //     identity_number,
    //     address,
    //     city,
    //     country,
    //     zipCode,
    //     companyName,
    //     taxNumber,
    //     taxOffice,
    //     iban,
    //     maxShare
    //   )
    //   .then((res) => {
    //     if (res.data.message === "success") {
    //       props.getAffiliates();
    //       setInputValues({
    //         name: "",
    //         surname: "",
    //         address: "",
    //         city: "",
    //         country: "",
    //         zipCode: "",
    //         companyName: "",
    //         taxOffice: "",
    //         iban: "",
    //         maxShare: "",
    //         email: "",
    //       });
    //       setGsmNumber("");
    //       setTaxNumber("");
    //       setIdentityNumber("");
    //       var successOptions = {};
    //       successOptions = {
    //         place: "br",
    //         message: (
    //           <div className="notification-msg text-center">
    //             <div>Affilate saved succsessfully!</div>
    //           </div>
    //         ),
    //         type: "success",
    //         icon: "",
    //         autoDismiss: 7,
    //       };
    //       successAlert.current.notificationAlert(successOptions);
    //     }
    //   })
    //   .catch((err) => {
    //     var errorOptions = {};
    //     errorOptions = {
    //       place: "br",
    //       message: (
    //         <div className="notification-msg text-center">
    //           <div>{err.response.data.message}</div>
    //         </div>
    //       ),
    //       type: "danger",
    //       icon: "",
    //       autoDismiss: 7,
    //     };
    //     errorAlert.current.notificationAlert(errorOptions);
    //   });
  };

  return (
    <div className={className} {...rest}>
      <ToastContainer />
      <Grid container>
        <Grid item xs={12} className="text-center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setToggle(!toggle)}
            style={{ fontFamily: "Josefin Sans" }}
            fullWidth
          >
            Add Affiliate
          </Button>
        </Grid>
        {toggle && (
          <>
            {/* <NotificationAlert ref={successAlert} />
            <NotificationAlert ref={errorAlert} /> */}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                // className={classes.inputTitle}
                style={{ fontFamily: "Josefin Sans" }}
              >
                NAME
              </Typography>
              <TextField
                onChange={handleInputChange}
                value={inputValues.name}
                placeholder="Ad"
                variant="outlined"
                size="small"
                className="py-0"
                name="name"
                fullWidth
                type="text"
                error={!inputValues.name}
                id="filled-error-helper-text"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                // className={classes.inputTitle}
                style={{ fontFamily: "Josefin Sans" }}
              >
                SURNAME
              </Typography>
              <TextField
                onChange={handleInputChange}
                value={inputValues.surname}
                placeholder="Soyad"
                variant="outlined"
                size="small"
                name="surname"
                fullWidth
                type="text"
                style={{ fontFamily: "Josefin Sans" }}
                error={!inputValues.surname}
                id="filled-error-helper-text"
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                // className={classes.inputTitle}
                style={{ fontFamily: "Josefin Sans" }}
              >
                ID NUMBER
              </Typography>
              <TextField
                onChange={handleIdChange}
                value={identityNumber}
                placeholder="TC Kimlik No"
                variant="outlined"
                size="small"
                name="identityNumber"
                fullWidth
                type="number"
                style={{ fontFamily: "Josefin Sans" }}
                error={idError || !identityNumber}
                id="filled-error-helper-text"
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                // className={classes.inputTitle}
                style={{ fontFamily: "Josefin Sans" }}
              >
                PHONE
              </Typography>
              <TextField
                onChange={handleGsmChange}
                value={gsmNumber}
                placeholder="90 555 555 5555"
                variant="outlined"
                size="small"
                name="gsmNumber"
                fullWidth
                type="text"
                style={{ fontFamily: "Josefin Sans" }}
                error={gsmError || !gsmNumber}
                id="filled-error-helper-text"
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                // className={classes.inputTitle}
                style={{ fontFamily: "Josefin Sans" }}
              >
                EMAIL
              </Typography>
              <TextField
                onChange={handleInputChange}
                value={inputValues.email}
                placeholder="Email"
                variant="outlined"
                size="small"
                name="email"
                fullWidth
                type="email"
                style={{ fontFamily: "Josefin Sans" }}
                error={!inputValues.email}
                id="filled-error-helper-text"
              />
            </Grid>
            <Grid item xs={12}>
              <input
                id="isCorporate"
                type="checkbox"
                checked={isCorporate}
                onChange={handleCheckChange}
                name="isCorporate"
                style={{ outline: "1px solid black" }}
              />
              <label for="isCorporate">
                Kurumsal fatura kesilmesini istiyorum.
              </label>
            </Grid>

            {isCorporate && (
              <>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    // className={classes.inputTitle}
                    style={{ fontFamily: "Josefin Sans" }}
                  >
                    COMPANY NAME
                  </Typography>
                  <TextField
                    onChange={handleInputChange}
                    value={inputValues.companyName}
                    placeholder="Şirket Adı"
                    variant="outlined"
                    size="small"
                    name="companyName"
                    fullWidth
                    type="text"
                    // error={!inputValues.companyName}
                    id="filled-error-helper-text"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    // className={classes.inputTitle}
                    style={{ fontFamily: "Josefin Sans" }}
                  >
                    TAX NUMBER
                  </Typography>
                  <TextField
                    onChange={handleTaxIdChange}
                    value={taxNumber}
                    placeholder="Vergi Numarası"
                    variant="outlined"
                    size="small"
                    name="taxNumber"
                    fullWidth
                    type="text"
                    style={{ fontFamily: "Josefin Sans" }}
                    // error={taxIdError || !taxNumber}
                    id="filled-error-helper-text"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    // className={classes.inputTitle}
                    style={{ fontFamily: "Josefin Sans" }}
                  >
                    TAX OFFICE
                  </Typography>
                  <TextField
                    onChange={handleInputChange}
                    value={inputValues.taxOffice}
                    placeholder="Vergi Dairesi"
                    variant="outlined"
                    name="taxOffice"
                    fullWidth
                    type="text"
                    // error={!inputValues.taxOffice}
                    id="filled-error-helper-text"
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                // className={classes.inputTitle}
                style={{ fontFamily: "Josefin Sans" }}
              >
                ADDRESS
              </Typography>
              <TextField
                onChange={handleInputChange}
                value={inputValues.address}
                placeholder="Adres"
                variant="outlined"
                size="small"
                name="address"
                fullWidth
                type="text"
                style={{ fontFamily: "Josefin Sans" }}
                error={!inputValues.address}
                id="filled-error-helper-text"
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                // className={classes.inputTitle}
                style={{ fontFamily: "Josefin Sans" }}
              >
                CITY
              </Typography>
              <TextField
                onChange={handleInputChange}
                value={inputValues.city}
                placeholder="İL/İLÇE"
                variant="outlined"
                size="small"
                name="city"
                fullWidth
                type="text"
                error={!inputValues.city}
                id="filled-error-helper-text"
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                // className={classes.inputTitle}
                style={{ fontFamily: "Josefin Sans" }}
              >
                COUNTRY
              </Typography>
              <TextField
                onChange={handleInputChange}
                value={inputValues.country}
                placeholder="ÜLKE"
                variant="outlined"
                name="country"
                fullWidth
                type="text"
                error={!inputValues.country}
                id="filled-error-helper-text"
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                // className={classes.inputTitle}
                style={{ fontFamily: "Josefin Sans" }}
              >
                ZIP CODE
              </Typography>
              <TextField
                onChange={handleInputChange}
                value={inputValues.zipCode}
                placeholder="Posta Kodu"
                variant="outlined"
                name="zipCode"
                fullWidth
                type="text"
                error={!inputValues.zipCode}
                id="filled-error-helper-text"
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                // className={classes.inputTitle}
                style={{ fontFamily: "Josefin Sans" }}
              >
                MAX SHARE
              </Typography>
              <TextField
                onChange={handleMaxShareChange}
                value={inputValues.maxShare}
                placeholder="Max Share Rate"
                variant="outlined"
                name="maxShare"
                fullWidth
                type="number"
                inputProps={{
                  min: "0",
                  max: "1",
                  step: "0.01",
                }}
                error={maxShareError || !inputValues.maxShare}
                id="filled-error-helper-text"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                // className={classes.inputTitle}
                style={{ fontFamily: "Josefin Sans" }}
              >
                IBAN
              </Typography>
              <TextField
                onChange={handleInputChange}
                value={inputValues.iban}
                placeholder="IBAN"
                variant="outlined"
                name="iban"
                fullWidth
                type="text"
                error={!inputValues.iban}
                id="filled-error-helper-text"
              />
            </Grid>
            <Grid item container alignItems="center" justify="center" xs={6}>
              <Button
                disabled={
                  gsmError ||
                  idError ||
                  !gsmNumber ||
                  !identityNumber ||
                  !inputValues.name ||
                  !inputValues.surname ||
                  !inputValues.email ||
                  !inputValues.address ||
                  !inputValues.city ||
                  !inputValues.country ||
                  !inputValues.zipCode ||
                  taxIdError ||
                  !inputValues.iban ||
                  maxShareError ||
                  !inputValues.maxShare
                }
                // className={classes.btnAdd}
                variant="contained"
                type="submit"
                color="primary"
                size="large"
                fullWidth
                onClick={() =>
                  handleFormSubmit(
                    inputValues.name,
                    inputValues.surname,
                    gsmNumber,
                    inputValues.email,
                    identityNumber,
                    inputValues.address,
                    inputValues.city,
                    inputValues.country,
                    inputValues.zipCode,
                    inputValues.companyName,
                    taxNumber,
                    inputValues.taxOffice,
                    inputValues.iban,
                    inputValues.maxShare
                  )
                }
                style={{ fontFamily: "Josefin Sans" }}
              >
                ADD
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

AddAffiliate.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export { AddAffiliate };
