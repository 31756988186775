import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Nav } from "reactstrap";

import { Navmenudropdown, Navmenugroup } from "../index";

// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
//import useravatar from "../../assets/img/profile.jpg";

// import history from "../../history";

var ps;
var currentmenu = "notset";

var IMGDIR = process.env.REACT_APP_IMGDIR;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opendd: "",
      openmenu: "none",
      profilename: "",
      profileimg: IMGDIR + "/images/profile/profile.jpg",
      profileposition: "Web Developer",
    };
  }

  handlecurrent = (currentmenu) => {
    if (this.state.opendd !== "") {
      currentmenu = "";
    }
  };

  handleOpendd = (open) => {
    currentmenu = "";
    if (this.state.opendd === open) {
      this.setState({
        opendd: "",
      });
    } else {
      this.setState({
        opendd: open,
      });
    }
    //currentmenu = "";
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    // return history.location.pathname.indexOf(routeName) > -1 ? " active" : "";
  };

  handleLogout = () => {
    localStorage.removeItem("token");
    // this.props.history.push("/");
  };

  componentDidMount() {}
  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    // }
  }

  // to display child routes
  children = (child, parent) => {
    var links = [];
    if (child) {
      for (var i = 0; i < child.length; i++) {
        links.push(
          <li key={i}>
            <NavLink
              to={child[i].path}
              className="nav-link"
              // activeClassName="active"
            >
              <span>{child[i].name}</span>
            </NavLink>
          </li>
        );
        // if (history.location.pathname === child[i].path) {
        //   if (currentmenu === "notset" && this.state.opendd === "") {
        //     currentmenu = parent; //child[i].parent;
        //   }
        // }
        // if (history.location.pathname === "/") {
        //   currentmenu = "dashboards";
        // }
      }
      return <Nav>{links}</Nav>;
    }
  };
  render() {
    return (
      <div className="sidebar menubar" data-color="black">
        <div className="logo">
          <Link to="/" className="logo-mini">
            <div className="logo-img p-2">
              <img
                src="/logoTitleWhite.png"
                alt="react-logo"
                className="light-logo"
              />

              <img
                src="/logoTitleWhite.png"
                alt="react-logo"
                className="dark-logo"
              />
            </div>
          </Link>
          <Link to="/" className="logo-full">
            <img src="/logo.png" alt="react-logo" className="light-logo" />
            <img src="/logo.png" alt="react-logo" className="dark-logo" />
          </Link>
        </div>

        <div className="sidebar-wrapper" ref="sidebar">
          <div className="profile-info row">
            {/* <div className="profile-image col-4">
              <a href="#!">
                <img
                  alt=""
                  src={this.state.profileimg}
                  className="img-fluid avatar-image"
                />
              </a>
            </div> */}
            <div className="profile-details col-8">
              <h3>
                <span>{this.state.profilename}</span>
                <span className="profile-status online"></span>
              </h3>
              <p className="profile-title">{this.state.profileposition}</p>
            </div>
          </div>

          <Nav className="navigation">
            {this.props.routes.map((prop, key) => {
              if (prop.redirect) return null;
              if (prop.type === "child") return null;
              if (prop.type === "navgroup")
                return <Navmenugroup name={prop.name} key={key}></Navmenugroup>;
              if (prop.type === "dropdown")
                return (
                  <li
                    className={
                      prop.parentid +
                      " " +
                      (this.state.opendd === prop.name ? "active" : "") +
                      " nav-parent "
                    }
                    data-toggle="collapse"
                    key={key}
                    style={{ fontFamily: "Josefin Sans" }}
                  >
                    <NavLink
                      to={prop.path}
                      className="nav-link"
                      onClick={() => {
                        this.handleOpendd(prop.name);
                      }}
                      href="#!"
                      // isActive={false}
                      style={{ fontFamily: "Josefin Sans" }}
                    >
                      <i className={prop.icon}></i>
                      <p>
                        {prop.name}{" "}
                        {prop.image == "new" ? (
                          <img src="/images/new-gif-icon-3.jpg" width="40px" />
                        ) : (
                          ""
                        )}
                      </p>

                      <span className="badge">{prop.badge}</span>

                      <span className={"arrow i-arrow-left"}></span>
                    </NavLink>
                    {this.children(prop.child, prop.parentid)}
                  </li>
                );

              if (prop.type === "dropdown-backup")
                return (
                  <Navmenudropdown
                    name={prop.name}
                    icon={prop.icon}
                    path={prop.path}
                    badge={prop.badge}
                    child={prop.child}
                    key={key}
                    openclass={
                      this.state.opendd === prop.name ? "activethis" : ""
                    }
                    onClick={() => this.handleOpendd(prop.name)}
                  ></Navmenudropdown>
                );
              return (
                <li
                  className={this.activeRoute(prop.path) + " nav-parent "}
                  key={key}
                  onClick={() => this.handleOpendd(prop.name)}
                  style={{ fontFamily: "Josefin Sans" }}
                >
                  <NavLink
                    to={prop.path}
                    className="nav-link"
                    // activeClassName="active"
                  >
                    <i className={prop.icon}></i>

                    <p>{prop.name}</p>
                    <span className="badge">{prop.badge}</span>
                  </NavLink>
                  {this.children(prop.child, prop.parentid)}
                </li>
              );
            })}
            <li className="nav-parent" style={{ fontFamily: "Josefin Sans" }}>
              <NavLink
                as="a"
                to="https://chrome.google.com/webstore/detail/emparazon/kacdmmlabjolkpfoapmdmjkdaheaobna?hl=tr"
                className="nav-link"
                // activeClassName="active"
                target="_blank"
              >
                <a
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  href="https://chrome.google.com/webstore/detail/emparazon/kacdmmlabjolkpfoapmdmjkdaheaobna?hl=tr"
                >
                  <i className="fa fa-chrome"></i>
                  <p>{this.props.chrome.name}</p>
                </a>
              </NavLink>
            </li>
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
