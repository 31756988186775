import React, { useEffect, useRef, useState } from "react";

import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  FormFeedback,
  Nav,
  NavLink,
  NavItem,
  TabPane,
  TabContent,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import seperateNumbersWithDots from "../../helpers/seperateNumbersWithDots";
import Loading from "../../components/tools/loading";
import createChannel from "../../utils/createChannel";
import UserService from "../../services/UserService";

const Bsr = ({ bsr, catId, catName }) => {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;
  const service = new UserService(apiReq);

  const [allBsr, setAllBsr] = useState([]);
  const [selectedBsrId, setSelectedBsrId] = useState(null);
  const [selectedBsrSales, setSelectedBsrSales] = useState(null);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [sales, setSales] = useState([]);
  const [date, setDate] = useState(null);
  const [grouppedBsr, setGrouppedBsr] = useState([]);

  const toggleModal1 = () => {
    setIsModalOpen1(!isModalOpen1);
    setSales([]);
    setDate(null);
  };
  const toggleModal2 = () => {
    setIsModalOpen2(!isModalOpen2);
  };
  const toggleModal3 = () => {
    setIsModalOpen3(!isModalOpen3);
  };
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // console.log('BSR', bsr)

  const groupBy = (arr, prop) => {
    const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
    arr.forEach((obj) => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
  };

  //USESTATE !!!
  // componentDidMount() {
  //   //console.log("bsr props", this.props);
  //   //this.setState({ grouppedBsr: this.groupBy(this.props.bsr, "date") });
  //   this.setState({
  //     allBsr: this.props.bsr,
  //     activeTab: this.props.bsr[0] && this.props.bsr[0].date,
  //   });
  //   //console.log(this.groupBy(this.props.bsr, "date"));
  // }

  useEffect(() => {
    setGrouppedBsr(groupBy(bsr, "date"))
    setAllBsr(bsr)
    setActiveTab(bsr[0] && bsr[0].date)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFormSubmit = (e) => {
    e.preventDefault();
    toggleModal2();
  };

  const addBsr = () => {
    // const updatedBsr = [...allBsr];
    const trimmedDate = date.trim();
    const bsrs = [...allBsr];
    const categoryId = +catId
    //userservice!!!!
    service
      .addBsr({ date: trimmedDate, sales, categoryId })
      .then((res) => {
        res.data.data.forEach((e) => {
          bsrs.push(e);
        });
        setAllBsr(bsrs);
        setGrouppedBsr(groupBy(bsrs, 'date'))
        toggleModal2();
        toggleModal1();
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };


  const updateBsr = (e) => {
    e.preventDefault();
    const updatedBsrs = [...allBsr]
    const newBsrSales = e.target.selectedBsrSales.value.trim(); //KONTROL ET!!!!

    service.updateBsr(selectedBsrId, { newBsrSales })
      .then((res) => {
        console.log(res.data.data);


        for (let i in allBsr) {
          if (updatedBsrs[i].id === selectedBsrId) {
            updatedBsrs[i].sales = newBsrSales;
            break;
          }

          setAllBsr(updatedBsrs);
          setGrouppedBsr(groupBy(updatedBsrs, "date"))
        }


      })
      .then(() => {
        toggleModal3()
      })
      .catch(err => {
        console.log('ERROR', err)
      })

    // const updateBsr = new UserProxy();
    // updateBsr
    //   .updateBsr(+newBsrSales, selectedBsrId)
    //   .then((res) => {
    //     if (res.data[0] == 1) {
    //       for (let i in allBsr) {
    //         if (allBsr[i].id == selectedBsrId) {
    //           allBsr[i].sales = newBsrSales;
    //           break;
    //         }
    //       }
    //       this.setState({ allBsr });
    //     }
    //   })
    //   .then(() => {
    //     this.toggleModal3();
    //   })
    //   .catch((err) => {
    //     // console.log("err", err);
    //   });
  };


  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let rank = e.target.id;
    if (name === "date") {
      setDate(value);
    } else if (name === "selectedBsrSales") {
      setSelectedBsrSales(value);
    } else {
      const saleCounts = [...sales]
      saleCounts[name] = { bsr_rank: +rank.trim(), sales: +value.trim() };
      setSales(saleCounts);
    }

    //console.log("state", this.state);
  };
  const bsrReferenceRanks = [
    1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 150, 200,
    250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000, 1500, 2000, 2500,
    3000, 3500, 4000, 4500, 5000, 6000, 7000, 8000, 9000, 10000, 12000, 14000,
    16000, 18000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000,
    100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000,
    1000000,
  ];

  // const {
  //   category: { catName, i },
  // } = this.props;

  //const grouppedBsr = groupBy(allBsr, "date");

  grouppedBsr.length && grouppedBsr.forEach((element) => {
    element.sort((a, b) => a.bsr_rank - b.bsr_rank);
  }); //sort by bsr_rank ascending
  console.log(sales.length, bsrReferenceRanks.length)
  //console.log("bsr state", this.state, grouppedBsr);
  return (
    <div>
      <h2>{catName}</h2>
      <Button color="primary" onClick={toggleModal1}>
        BSR EKLE
      </Button>
      {/* Add BSR Modal */}
      <Modal
        isOpen={isModalOpen1}
        toggleModal1={toggleModal1}
      // className={props.className}
      >
        <ModalHeader toggleModal1={toggleModal1}>
          {catName} için BSR Nirengi Noktası Değerlerini Ekle
        </ModalHeader>
        <ModalBody>
          <Form inline onSubmit={onFormSubmit}>
            <Input
              onChange={handleInputChange}
              id="addDate"
              className="form-control "
              placeholder="Tarih"
              name="date"
              bsSize="lg"
              type="date"
              style={{ width: "100%" }}
              invalid={!date || date === ""}
            />
            {(bsrReferenceRanks || []).map((rank, i) => {
              return (
                <React.Fragment key={i}>
                  <Input
                    onChange={handleInputChange}
                    id={"addBsrRank" + i}
                    className="form-control "
                    placeholder="BSR"
                    name={"bsr_rank" + i}
                    type="number"
                    value={rank}
                    disabled
                  />
                  <Input
                    onChange={handleInputChange}
                    id={rank}
                    className="form-control "
                    placeholder="Satış Miktarı"
                    name={i}
                    min={0}
                    type="number"
                    invalid={sales.length && !sales[i]}
                  />
                  {sales.length &&
                    sales[i] &&
                    sales[i + 1] &&
                    sales[i].sales < sales[i + 1].sales ? (
                    <FormFeedback>Küçük olamaz</FormFeedback>
                  ) : null}
                </React.Fragment>
              );
            })}

            <Button
              id="addBsrButton"
              type="submit"
              className="btn p-2 text-align-center register"
              disabled={!date || sales.length !== bsrReferenceRanks.length}
            >
              EKLE
            </Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal1}>
            İPTAL
          </Button>
        </ModalFooter>
      </Modal>
      {/* Confirm Adding BSRs Modal */}
      <Modal
        isOpen={isModalOpen2}
        toggle={toggleModal2}
      // className={props.className}
      >
        <ModalHeader toggle={toggleModal2}>ONAY</ModalHeader>
        <ModalBody>
          {catName} kategorisi için {date} tarihli {sales.length} adet BSR
          eklenecek!
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={addBsr}>
            TAMAM
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal2}>
            İPTAL
          </Button>
        </ModalFooter>
      </Modal>
      {/* Edit BSR Modal */}
      <Modal
        isOpen={isModalOpen3}
        toggle={toggleModal3}
      // className={props.className}
      >
        <ModalHeader toggle={toggleModal3}>Güncelle</ModalHeader>
        <ModalBody>
          <Form inline onSubmit={updateBsr}>
            <Input
              onChange={handleInputChange}
              id="updateBsr"
              className="form-control "
              type="text"
              value={selectedBsrSales}
              placeholder="Yeni Bsr Satış Miktarı"
              name="selectedBsrSales"
            />

            <Button
              disabled={!selectedBsrId || selectedBsrSales === ""}
              color="primary"
              type="submit"
            >
              <i className="fas fa-edit"></i>
            </Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal3}>
            İPTAL
          </Button>
        </ModalFooter>
      </Modal>
      <div className="d-flex">
        {grouppedBsr &&
          grouppedBsr.map((element) => {
            return (
              <Nav tabs className="horizontal cenrer-aligned">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === element[0].date,
                    })}
                    onClick={() => {
                      toggleTab(element[0].date);
                    }}
                  >
                    {element[0].date}
                  </NavLink>
                </NavItem>
              </Nav>
            );
          })}
      </div>
      {!grouppedBsr.length ? (
        "BSR Ekleyin"
      ) : grouppedBsr.length && activeTab ? (
        <TabContent activeTab={activeTab}>
          <TabPane tabId={activeTab}>
            <Row>
              <Col sm="12">
                <Table striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>BSR</th>
                      <th>Satış Miktarı</th>
                      <th>Tarih</th>
                      <th>Güncelle</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activeTab
                      ? grouppedBsr
                        .filter((bsr) => bsr[0].date === activeTab)[0]
                        .map((elm, i) => {
                          return (
                            <tr key={elm.id}>
                              <td>{i + 1} </td>
                              <td>{elm.bsr_rank}</td>
                              <td>{seperateNumbersWithDots(elm.sales)}</td>
                              <td>{elm.date}</td>
                              <td>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    toggleModal3();
                                    setSelectedBsrId(elm.id);
                                    setSelectedBsrSales(elm.sales);
                                  }}
                                >
                                  <i className="fas fa-edit"></i>
                                </span>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Bsr;
