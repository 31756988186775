import React, { useRef, useState } from "react";
import {
  Collapse,
  Navbar,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
var IMGDIR = process.env.REACT_APP_IMGDIR;

const dashboardRoutes = [
  { path: "/deneme", component: {} },
  { path: "/hop", component: {} },
];

const Header = (props) => {
  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState("primary");
  const [userddOpen, setUserddOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [messagesddOpen, setMessagesddOpen] = useState(false);
  const [notificationsddOpen, setNotificationsddOpen] = useState(false);
  const [country, setCountry] = useState("");
  const [isCountry, setIsCountry] = useState(false);
  const [isLanguage, setIsLanguage] = useState(false);
  const [profilename, setProfilename] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isOpenCountry, setIsOpenCountry] = useState(false);

  const sidebarToggle = useRef();
  // constructor(props) {
  //   super(props);
  //     state = {
  //     isOpen: false,
  //     userddOpen: false,
  //     searchOpen: false,
  //     messagesddOpen: false,
  //     notificationsddOpen: false,
  //     color: "primary",
  //     profilename: null,
  //     profileimg: IMGDIR + "/images/profile/profile.jpg",
  //     errorMessage: null,
  //     isOpenCountry: false,
  //     country: "",
  //     isCountry: false,
  //     isLanguage: false,
  //   };
  // }

  // configuring context

  const toggle = () => {
    if (isOpen) {
      setColor("primary");
    } else {
      setColor("white");
    }
    setIsOpen(!isOpen);
  };

  const userddToggle = (e) => {
    setUserddOpen(!userddOpen);
  };
  const searchToggle = () => {
    //  refs.searchbarToggle.classList.toggle('toggled');
    setSearchOpen(!searchOpen);
    //  refs.searchbarToggle.classList.toggle('opened');
  };
  const messagesddToggle = (e) => {
    setMessagesddOpen(!messagesddOpen);
  };
  const notificationsddToggle = (e) => {
    setNotificationsddOpen(!notificationsddOpen);
  };
  const getBrand = () => {
    var name;
    dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return name;
  };
  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-toggle");
    //   refs.sidebarToggle.classList.toggle('toggled');
  };

  const openStyle = () => {
    document.documentElement.classList.toggle("nav-toggle-style");
    //   refs.chatToggle.classList.toggle("toggled");

    // close menu bar if open on smaller screens
    if (window.innerWidth < 993) {
      document.documentElement.classList.remove("nav-toggle");
      //   refs.sidebarToggle.classList.remove("toggled");
    }
  };
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("primary");
    } else {
      setColor("secondary");
    }
  };
  const logOutClick = () => {
    //   props.history.push("/");
    logout();

    window.location.reload();
  };
  // componentDidMount() {
  //   // document.documentElement.classList.toggle("nav-toggle");
  //   // document.documentElement.classList.add("nav-toggle");
  //   //   refs.sidebarToggle.classList.remove("toggled");

  //   window.addEventListener("resize",   updateColor);

  //   if (localStorage.getItem("token") !== null) {
  //     let token = localStorage.getItem("token");
  //     let user = jwt_decode(token);
  //     //USERI GETIR
  //     // let userproxy = new UserProxy();
  //     // userproxy
  //     //   .getUser()
  //     //   .then((res) => {
  //     //       setState({
  //     //       profilename: `${res.data.isim} ${res.data.soyad}`,
  //     //     });
  //     //   })
  //     //   .catch((err) => {
  //     //       setState({ errorMessage: err.response.data.message });
  //     //   });
  //   }

  //     getCountry();
  // }

  const getCountry = () => {
    if (localStorage.getItem("Country")) {
      setCountry(localStorage.getItem("Country"));
    } else {
      localStorage.setItem("Country", "USA");
      setCountry("USA");
    }
  };
  const countryToggle = (e) => {
    e.preventDefault();
    setIsCountry(!isCountry);
  };
  const languageToggle = (e) => {
    e.preventDefault();
    setIsLanguage(!isLanguage);
  };
  const changeCountry = (e) => {
    localStorage.setItem("Country", e.target.innerText);
    setCountry(e.target.innerText);

    if (
      window.location.href.indexOf("dashboard") != -1 ||
      window.location.href.indexOf("cargo") != -1
    ) {
      window.location.reload();
    } else {
      if (window.location.href.indexOf("-ger") != -1) {
        if (e.target.innerText === "France") {
          window.location.replace(window.location.href.replace("-ger", "-fr"));
        } else if (e.target.innerText === "UK") {
          window.location.replace(window.location.href.replace("-ger", "-uk"));
        } else if (e.target.innerText === "Mexico") {
          window.location.replace(window.location.href.replace("-ger", "-mx"));
        } else if (e.target.innerText === "Italy") {
          window.location.replace(window.location.href.replace("-ger", "-it"));
        } else if (e.target.innerText === "USA") {
          window.location.replace(window.location.href.replace("-ger", ""));
        } else if (e.target.innerText === "Canada") {
          window.location.replace(window.location.href.replace("-ger", "-ca"));
        } else if (e.target.innerText === "Spain") {
          window.location.replace(window.location.href.replace("-ger", "-sp"));
        } else if (e.target.innerText === "Australia") {
          window.location.replace(window.location.href.replace("-ger", "-au"));
        }
      } else if (window.location.href.indexOf("-uk") != -1) {
        if (e.target.innerText === "France") {
          window.location.replace(window.location.href.replace("-uk", "-fr"));
        } else if (e.target.innerText === "Italy") {
          window.location.replace(window.location.href.replace("-uk", "-it"));
        } else if (e.target.innerText === "Mexico") {
          window.location.replace(window.location.href.replace("-uk", "-mx"));
        } else if (e.target.innerText === "Germany") {
          window.location.replace(window.location.href.replace("-uk", "-ger"));
        } else if (e.target.innerText === "USA") {
          window.location.replace(window.location.href.replace("-uk", ""));
        } else if (e.target.innerText === "Canada") {
          window.location.replace(window.location.href.replace("-uk", "-ca"));
        } else if (e.target.innerText === "Spain") {
          window.location.replace(window.location.href.replace("-uk", "-sp"));
        } else if (e.target.innerText === "Australia") {
          window.location.replace(window.location.href.replace("-uk", "-au"));
        }
      } else if (window.location.href.indexOf("-it") != -1) {
        if (e.target.innerText === "France") {
          window.location.replace(window.location.href.replace("-it", "-fr"));
        } else if (e.target.innerText === "Germany") {
          window.location.replace(window.location.href.replace("-it", "-ger"));
        } else if (e.target.innerText === "USA") {
          window.location.replace(window.location.href.replace("-it", ""));
        } else if (e.target.innerText === "Mexico") {
          window.location.replace(window.location.href.replace("-it", "-mx"));
        } else if (e.target.innerText === "Canada") {
          window.location.replace(window.location.href.replace("-it", "-ca"));
        } else if (e.target.innerText === "Spain") {
          window.location.replace(window.location.href.replace("-it", "-sp"));
        } else if (e.target.innerText === "UK") {
          window.location.replace(window.location.href.replace("-it", "-uk"));
        } else if (e.target.innerText === "Australia") {
          window.location.replace(window.location.href.replace("-it", "-au"));
        }
      } else if (window.location.href.indexOf("-fr") != -1) {
        if (e.target.innerText === "Germany") {
          window.location.replace(window.location.href.replace("-fr", "-ger"));
        } else if (e.target.innerText === "UK") {
          window.location.replace(window.location.href.replace("-fr", "-uk"));
        } else if (e.target.innerText === "Italy") {
          window.location.replace(window.location.href.replace("-fr", "-it"));
        } else if (e.target.innerText === "USA") {
          window.location.replace(window.location.href.replace("-fr", ""));
        } else if (e.target.innerText === "Mexico") {
          window.location.replace(window.location.href.replace("-fr", "-mx"));
        } else if (e.target.innerText === "Canada") {
          window.location.replace(window.location.href.replace("-fr", "-ca"));
        } else if (e.target.innerText === "Spain") {
          window.location.replace(window.location.href.replace("-fr", "-sp"));
        } else if (e.target.innerText === "Australia") {
          window.location.replace(window.location.href.replace("-fr", "-au"));
        }
      } else if (window.location.href.indexOf("-ca") != -1) {
        if (e.target.innerText === "France") {
          window.location.replace(window.location.href.replace("-ca", "-fr"));
        } else if (e.target.innerText === "UK") {
          window.location.replace(window.location.href.replace("-ca", "-uk"));
        } else if (e.target.innerText === "Italy") {
          window.location.replace(window.location.href.replace("-ca", "-it"));
        } else if (e.target.innerText === "Mexico") {
          window.location.replace(window.location.href.replace("-ca", "-mx"));
        } else if (e.target.innerText === "USA") {
          window.location.replace(window.location.href.replace("-ca", ""));
        } else if (e.target.innerText === "Germany") {
          window.location.replace(window.location.href.replace("-ca", "-ger"));
        } else if (e.target.innerText === "Spain") {
          window.location.replace(window.location.href.replace("-ca", "-sp"));
        } else if (e.target.innerText === "Australia") {
          window.location.replace(window.location.href.replace("-ca", "-au"));
        }
      } else if (window.location.href.indexOf("-sp") != -1) {
        if (e.target.innerText === "France") {
          window.location.replace(window.location.href.replace("-sp", "-fr"));
        } else if (e.target.innerText === "UK") {
          window.location.replace(window.location.href.replace("-sp", "-uk"));
        } else if (e.target.innerText === "Italy") {
          window.location.replace(window.location.href.replace("-sp", "-it"));
        } else if (e.target.innerText === "Mexico") {
          window.location.replace(window.location.href.replace("-sp", "-mx"));
        } else if (e.target.innerText === "USA") {
          window.location.replace(window.location.href.replace("-sp", ""));
        } else if (e.target.innerText === "Germany") {
          window.location.replace(window.location.href.replace("-sp", "-ger"));
        } else if (e.target.innerText === "Canada") {
          window.location.replace(window.location.href.replace("-sp", "-ca"));
        } else if (e.target.innerText === "Australia") {
          window.location.replace(window.location.href.replace("-sp", "-au"));
        }
      } else if (window.location.href.indexOf("-au") != -1) {
        if (e.target.innerText === "France") {
          window.location.replace(window.location.href.replace("-au", "-fr"));
        } else if (e.target.innerText === "UK") {
          window.location.replace(window.location.href.replace("-au", "-uk"));
        } else if (e.target.innerText === "Italy") {
          window.location.replace(window.location.href.replace("-au", "-it"));
        } else if (e.target.innerText === "Mexico") {
          window.location.replace(window.location.href.replace("-au", "-mx"));
        } else if (e.target.innerText === "USA") {
          window.location.replace(window.location.href.replace("-au", ""));
        } else if (e.target.innerText === "Germany") {
          window.location.replace(window.location.href.replace("-au", "-ger"));
        } else if (e.target.innerText === "Canada") {
          window.location.replace(window.location.href.replace("-au", "-ca"));
        } else if (e.target.innerText === "Spain") {
          window.location.replace(window.location.href.replace("-au", "-sp"));
        }
      } else if (window.location.href.indexOf("-mx") != -1) {
        if (e.target.innerText === "France") {
          window.location.replace(window.location.href.replace("-mx", "-fr"));
        } else if (e.target.innerText === "UK") {
          window.location.replace(window.location.href.replace("-mx", "-uk"));
        } else if (e.target.innerText === "Italy") {
          window.location.replace(window.location.href.replace("-mx", "-it"));
        } else if (e.target.innerText === "Australia") {
          window.location.replace(window.location.href.replace("-mx", "-au"));
        } else if (e.target.innerText === "USA") {
          window.location.replace(window.location.href.replace("-mx", ""));
        } else if (e.target.innerText === "Germany") {
          window.location.replace(window.location.href.replace("-mx", "-ger"));
        } else if (e.target.innerText === "Canada") {
          window.location.replace(window.location.href.replace("-mx", "-ca"));
        } else if (e.target.innerText === "Spain") {
          window.location.replace(window.location.href.replace("-mx", "-sp"));
        }
      } else {
        let link = window.location.href;
        function getPosition(string, subString, index) {
          return string.split(subString, index).join(subString).length;
        }
        if (e.target.innerText === "France") {
          window.location.replace(
            link.slice(0, getPosition(link, "/", 4)) +
              "-fr" +
              link.slice(getPosition(link, "/", 4), link.length)
          );
        } else if (e.target.innerText === "UK") {
          window.location.replace(
            link.slice(0, getPosition(link, "/", 4)) +
              "-uk" +
              link.slice(getPosition(link, "/", 4), link.length)
          );
        } else if (e.target.innerText === "Italy") {
          window.location.replace(
            link.slice(0, getPosition(link, "/", 4)) +
              "-it" +
              link.slice(getPosition(link, "/", 4), link.length)
          );
        } else if (e.target.innerText === "Germany") {
          window.location.replace(
            link.slice(0, getPosition(link, "/", 4)) +
              "-ger" +
              link.slice(getPosition(link, "/", 4), link.length)
          );
        } else if (e.target.innerText === "Mexico") {
          window.location.replace(
            link.slice(0, getPosition(link, "/", 4)) +
              "-mx" +
              link.slice(getPosition(link, "/", 4), link.length)
          );
        } else if (e.target.innerText === "Canada") {
          window.location.replace(
            link.slice(0, getPosition(link, "/", 4)) +
              "-ca" +
              link.slice(getPosition(link, "/", 4), link.length)
          );
        } else if (e.target.innerText === "Spain") {
          window.location.replace(
            link.slice(0, getPosition(link, "/", 4)) +
              "-sp" +
              link.slice(getPosition(link, "/", 4), link.length)
          );
        } else if (e.target.innerText === "Australia") {
          window.location.replace(
            link.slice(0, getPosition(link, "/", 4)) +
              "-au" +
              link.slice(getPosition(link, "/", 4), link.length)
          );
        }
      }
    }
  };

  // componentDidUpdate(pP) {
  //   if (
  //     window.innerWidth < 993 &&
  //       props.location.pathname !== pP.location.pathname &&
  //     document.documentElement.className.indexOf("nav-toggle") !== -1
  //   ) {
  //     document.documentElement.classList.toggle("nav-toggle");
  //       refs.sidebarToggle.classList.toggle("toggled");
  //   }
  //   if (
  //     window.innerWidth < 993 &&
  //       props.location.pathname !== pP.location.pathname &&
  //     document.documentElement.className.indexOf("nav-toggle-chat") !== -1
  //   ) {
  //     document.documentElement.classList.toggle("nav-toggle-chat");
  //     //   refs.chatToggle.classList.toggle('toggled');
  //   }
  // }

  // destructure localization t function

  return (
    // add or remove classes depending if we are on full-screen-maps page or not

    <Navbar expand="lg" className="navbar-absolute fixed-top ">
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={() => openSidebar()}
            >
              <i className="i-menu"></i>
            </button>
          </div>
        </div>

        <Collapse isOpen={isOpen} navbar className="navbar-right mr-2">
          <Nav navbar>
            {user && (
              <Dropdown
                nav
                isOpen={userddOpen}
                toggle={(e) => userddToggle(e)}
                className="userdd"
              >
                <DropdownToggle caret nav>
                  {/* <img
                src={ profileimg}
                alt="react-logo"
                className="avatar-image"
                  />{" "}*/}
                  <span>{profilename}</span>
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem tag={NavLink} to="/hesabım">
                    <i className="i-user"></i>
                    <p className="title">Hesabım</p>
                  </DropdownItem>
                  <DropdownItem tag={NavLink} to="/favori-keyword">
                    <i className="i-question"></i>
                    <p className="title">Favori Kelimeler</p>
                  </DropdownItem>
                  <DropdownItem tag={NavLink} to="/favori-asin">
                    <i className="i-question"></i>
                    <p className="title">Favori ASIN</p>
                  </DropdownItem>
                  <DropdownItem onClick={logOutClick}>
                    <i className="i-logout"></i>
                    <p className="title">Çıkış</p>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}

            <Dropdown
              nav
              isOpen={isLanguage}
              toggle={(e) => languageToggle(e)}
              className="userdd"
            >
              <DropdownToggle caret nav>
                {/* <img
                    src={ profileimg}
                    alt="react-logo"
                    className="avatar-image"
                  />{" "} */}
                {/* <span>{  context.lang}</span> */}
              </DropdownToggle>
              {/* <DropdownMenu end>
                <DropdownItem onClick={  context.toggleLanguage}>
                  <p className="title">tr-TR</p>
                </DropdownItem>
                <DropdownItem onClick={  context.toggleLanguage}>
                  <p className="title">en-EN</p>
                </DropdownItem>
              </DropdownMenu> */}
            </Dropdown>
          </Nav>
        </Collapse>

        <Collapse isOpen={isOpenCountry} navbar className="navbar-right">
          <Nav navbar>
            {window.location.href.indexOf("tool") > -1 ||
            window.location.href.indexOf("training") > -1 ||
            window.location.href.indexOf("admin") > -1 ||
            window.location.href.indexOf("users") > -1 ||
            window.location.href.indexOf("user-details") > -1 ||
            window.location.href.indexOf("members") > -1 ||
            window.location.href.indexOf("service-panel") > -1 ||
            window.location.href.indexOf("billing-panel") > -1 ||
            window.location.href.indexOf("affiliates") > -1 ||
            window.location.href.indexOf("coupons") > -1 ||
            window.location.href.indexOf("affiliate-details") > -1 ||
            window.location.href.indexOf("add-discounted-plan") > -1 ||
            window.location.href.indexOf("add-iyzico-plan") > -1 ||
            window.location.href.indexOf("payment-logs") > -1 ||
            window.location.href.indexOf("error-logs") > -1 ||
            window.location.href.indexOf("package-plans") > -1 ||
            window.location.href.indexOf("data-import") > -1 ||
            window.location.href.indexOf("product-sales") > -1 ||
            window.location.href.indexOf("gift-members") > -1 ||
            window.location.href.indexOf("email-send") > -1 ||
            window.location.href.indexOf("extension-category") > -1 ||
            window.location.href.indexOf("cargo-calc") > -1 ||
            window.location.href.indexOf("tool") > -1 ||
            window.location.href.indexOf("analiz") > -1 ||
            (window.location.href.indexOf("timeline") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("timeline-ger") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("timeline-uk") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("timeline-mx") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("timeline-it") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("timeline-fr") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("timeline-ca") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("timeline-sp") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("timeline-au") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("ocean") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("ocean-ger") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("ocean-uk") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("ocean-mx") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("ocean-it") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("ocean-fr") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("ocean-ca") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("ocean-sp") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("ocean-au") > -1 &&
              window.location.href.includes("/0/0") === false) ||
            (window.location.href.indexOf("space") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("space-ger") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("space-uk") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("space-mx") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("space-it") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("space-fr") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("space-ca") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("space-sp") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("space-au") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("give-away") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("give-away-ger") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("give-away-uk") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("give-away-mx") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("give-away-it") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("give-away-fr") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("give-away-ca") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("give-away-sp") > -1 &&
              window.location.href.includes("/0") === false) ||
            (window.location.href.indexOf("give-away-au") > -1 &&
              window.location.href.includes("/0") === false) ||
            window.location.href.indexOf("etsy") > -1 ||
            window.location.href.indexOf("listings") > -1 ||
            window.location.href.indexOf("shops") > -1 ||
            window.location.href.indexOf("fav-keywords") > -1 ||
            window.location.href.indexOf("support") > -1 ||
            window.location.href.indexOf("developer") > -1 ||
            window.location.href.indexOf("blog") > -1 ||
            window.location.href.indexOf("authors") > -1 ||
            window.location.href.indexOf("tags") > -1 ||
            window.location.href.indexOf("analiz") > -1 ? null : (
              <Dropdown
                nav
                isOpen={isCountry}
                toggle={(e) => countryToggle(e)}
                className="userdd"
              >
                <DropdownToggle caret nav>
                  {country === "USA" ? (
                    <img
                      style={{ width: "30px" }}
                      className="mr-2 mb-1"
                      src="/flags/United-States.png"
                    />
                  ) : null}
                  {country === "France" ? (
                    <img
                      style={{ width: "30px" }}
                      className="mr-2 mb-1"
                      src="/flags/France.png"
                    />
                  ) : null}
                  {country === "Germany" ? (
                    <img
                      style={{ width: "30px" }}
                      className="mr-2 mb-1"
                      src="/flags/Germany.png"
                    />
                  ) : null}
                  {country === "UK" ? (
                    <img
                      style={{ width: "30px" }}
                      className="mr-2 mb-1"
                      src="/flags/United-Kingdom.png"
                    />
                  ) : null}
                  {country === "Italy" ? (
                    <img
                      style={{ width: "30px", height: "25px" }}
                      className="mr-2 mb-1"
                      src="/flags/Italy.png"
                    />
                  ) : null}
                  {country === "Canada" ? (
                    <img
                      style={{ width: "30px", height: "40px" }}
                      className="mr-2 mb-1"
                      src="/flags/canada.png"
                    />
                  ) : null}
                  {country === "Spain" ? (
                    <img
                      style={{ width: "30px", height: "25px" }}
                      className="mr-2 mb-1"
                      src="/flags/spain.png"
                    />
                  ) : null}
                  {country === "Australia" ? (
                    <img
                      style={{ width: "32px", height: "20px" }}
                      className="mr-2 mb-1"
                      src="/flags/australia.png"
                    />
                  ) : null}
                  {country === "Mexico" ? (
                    <img
                      style={{ width: "32px", height: "20px" }}
                      className="mr-2 mb-1"
                      src="/flags/mexico.png"
                    />
                  ) : null}
                  <span>{country}</span>
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem onClick={changeCountry}>
                    <img
                      style={{ width: "20px" }}
                      className="mr-2 mb-1"
                      src="/flags/United-States.png"
                    />
                    <p className="title">USA</p>
                  </DropdownItem>
                  <DropdownItem onClick={changeCountry}>
                    <img
                      style={{ width: "20px" }}
                      className="mr-2 mb-1"
                      src="/flags/Germany.png"
                    />
                    <p className="title">Germany</p>
                  </DropdownItem>
                  <DropdownItem onClick={changeCountry}>
                    <img
                      style={{ width: "20px" }}
                      className="mr-2 mb-1"
                      src="/flags/United-Kingdom.png"
                    />
                    <p className="title">UK</p>
                  </DropdownItem>
                  <DropdownItem onClick={changeCountry}>
                    <img
                      style={{ width: "20px" }}
                      className="mr-2 mb-1"
                      src="/flags/France.png"
                    />
                    <p className="title">France</p>
                  </DropdownItem>
                  <DropdownItem onClick={changeCountry}>
                    <img
                      style={{ width: "20px" }}
                      className="mr-2 mb-1"
                      src="/flags/canada.png"
                    />
                    <p className="title">Canada</p>
                  </DropdownItem>
                  <DropdownItem onClick={changeCountry}>
                    <img
                      style={{ width: "20px" }}
                      className="mr-2 mb-1"
                      src="/flags/spain.png"
                    />
                    <p className="title">Spain</p>
                  </DropdownItem>
                  <DropdownItem onClick={changeCountry}>
                    <img
                      style={{ width: "20px" }}
                      className="mr-2 mb-1"
                      src="/flags/Italy.png"
                    />
                    <p className="title">Italy</p>
                  </DropdownItem>
                  <DropdownItem onClick={changeCountry}>
                    <img
                      style={{ width: "20px", height: "13px" }}
                      className="mr-2 mb-1"
                      src="/flags/australia.png"
                    />
                    <p className="title">Australia</p>
                  </DropdownItem>
                  <DropdownItem onClick={changeCountry}>
                    <img
                      style={{ width: "20px", height: "13px" }}
                      className="mr-2 mb-1"
                      src="/flags/mexico.png"
                    />
                    <p className="title">Mexico</p>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
