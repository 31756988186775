import React from "react";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
const Pagination = ({ page, setPage }) => {
  const pageForward = (e) => {
    e.preventDefault();
    setPage((prev) => prev + 1);
    window.scrollTo(0, 0)
  };
  const pageBackward = (e) => {
    e.preventDefault();
    if (page > 1) setPage((prev) => prev - 1);
    window.scrollTo(0, 0)
  };

  return (
    <div className="d-flex justify-content-center align-items-center w-100">
      <div style={{ marginRight: "10px" }}>
        <FaArrowLeft
          id="back"
          onClick={pageBackward}
          style={{ fontSize: "1.5rem", color: "#01123C", cursor: "pointer" }}
        />
      </div>
      <div
        style={{
          width: "30px",
          textAlign: "center",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: "black",
          }}
        >
          {page}
        </span>
      </div>
      <div style={{ marginLeft: "10px" }}>
        <FaArrowRight
          id="forward"
          onClick={pageForward}
          style={{ fontSize: "1.5rem", color: "#01123C", cursor: "pointer" }}
        />
      </div>
    </div>
  );
};

export default Pagination;
