import React from "react";
import { Table } from "reactstrap";
import { NavLink } from "react-router-dom";
import Pagination from "../shared/pagination";

export default function GiftMemberList({
  giftMembers,
  toggleEndModal,
  page,
  getGiftMembers,
  setPage,
}) {
  React.useEffect(() => {}, []);

  return (
    <div>
      <Table striped>
        <thead>
          <tr>
            <th>Id</th>
            <th>Email</th>
            <th>İsim </th>
            <th>Soyisim</th>
            <th>Paket</th>
            <th>Durum</th>
            <th>Başlangıç Tarihi</th>
            <th>Bitiş Tarihi</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {giftMembers?.map((member) => {
            return (
              <tr>
                <th>{member.user_info_id}</th>
                <td>
                  <NavLink to={`/user-details/${member.user_info_id}`}>
                    <span style={{ color: "blue" }}>{member.email}</span>
                  </NavLink>
                </td>
                <td>{member.isim}</td>
                <td>{member.soyad}</td>
                <td>{member.name}</td>
                <td>
                  {member.is_active ? (
                    <span style={{ color: "green" }}>Aktif</span>
                  ) : (
                    <span style={{ color: "red" }}>Pasif</span>
                  )}
                </td>
                <td>{new Date(member.start_date).toLocaleString()}</td>
                <td>
                  {member.cancel_date === null
                    ? new Date(member.end_date).toLocaleString()
                    : new Date(member.cancel_date).toLocaleString()}
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-danger mx-1 my-1"
                    style={{
                      fontSize: "0.8rem",
                      display: !member.is_active ? "none" : "",
                    }}
                    onClick={() => {
                      toggleEndModal(member.id);
                      console.log("member id >>>", member.id);
                    }}
                  >
                    Sonlandır
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Pagination page={page} setPage={setPage} />
    </div>
  );
}
