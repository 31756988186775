import React, { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Navigate, Outlet } from "react-router-dom";
// import Login from "../pages/login";
import Loading from "../components/tools/loading";
// import Users from "../pages/users";
import NotAuthorized from "../components/notAuthorized";

function DevRoute({ children }) {
  const { user, isLoading } = useContext(AuthContext);

  if (!isLoading && !user) {
    return <Navigate to="/login" />;
  } else if (isLoading) {
    return <Loading />;
  } else if (
    (user && user?.role === "dwtqXfOhfh") ||
    (user && user?.role === "devRxvn")
  ) {
    return <Outlet />;
  } else {
    return <NotAuthorized />;
  }
}

export default DevRoute;
