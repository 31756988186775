import React from 'react'
import { Row, Col, Table, Progress } from "reactstrap";

export default function StatsCard(props) {
  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
      <div className="tile-progress inverted subCard">
        <div>
          {
            props.component == 'subscription' ?
              <>
                <h3 className='subCardItem'>{props.title}</h3>
                <h3 className='subCardItem'>{props.count}</h3>
                <h4 className='subCardItem'>{props.month}</h4>
              </>
              :
              <>
                <h2>{props.count}</h2>
                <p>{props.title}</p>
                <Progress color={props.progressColor} value={props.value} />
                <span>{props.value}% {props.subTitle}</span>
              </>
          }


        </div>
      </div>
    </div>
  )
}

