import React, { useEffect, useRef, useState } from "react";

import { Modal, ModalBody } from "reactstrap";
import createChannel from "../utils/createChannel";
import StatsTimeline from "./shared/statsTimeline";
import UserService from "../services/UserService.js";

export default function PageStats(props) {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;

  const [pages, setPages] = useState([]);
  const [modal, setModal] = useState(false);
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [selectedPage, setSelectedPage] = useState("");

  const service = new UserService(apiReq);

  const toggleModal = () => {
    setLabels([]);
    setData([]);
    setSelectedPage("");
    setModal(!modal);
  };
  const getStatistics = () => {
    service
      .getPageStats()
      .then((res) => {
        let pages = res.data.data;
        const compare = (a, b) => {
          if (a.amount > b.amount) {
            return -1;
          }
          if (a.amount < b.amount) {
            return 1;
          }
          return 0;
        };
        pages.sort(compare);
        setPages(pages);
        console.log(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const handleStatsData = (pageId) => {
    toggleModal();
    service
      .getSpecificPageStats(pageId)
      .then((res) => {
        const tempLabels = res.data.data.map((item) => item.month);
        const tempData = res.data.data.map((item) => item.amount);
        setSelectedPage(res.data.data[1].pageName);
        setLabels(tempLabels);
        setData(tempData);
        console.log("page stats", res.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getStatistics();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <table className="table" style={{ width: "100%" }}>
        <thead className="thead-dark">
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Page</th>
            <th scope="col">Traffic</th>
          </tr>
        </thead>
        <tbody>
          {pages.map((page) => {
            return (
              <tr>
                <td>{page.pageId}</td>
                <td>
                  <span
                    className="stats-lines"
                    onClick={() => handleStatsData(page.pageId)}
                  >
                    {page.page.page_name}
                  </span>
                </td>
                <td>{page.amount}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal isOpen={modal} toggle={toggleModal}>
        {/* <ModalHeader toggle={toggleModal}>{selectedPage}</ModalHeader> */}
        <ModalBody style={{ color: "red", fontSize: "1.2rem" }}>
          <StatsTimeline
            labels={labels}
            data={data}
            selectedPage={selectedPage}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}
