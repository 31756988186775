import React, { useRef, useState } from "react";

import { Input, Label, Form, Row, Col, FormGroup } from "reactstrap";
import UserService from "../services/UserService";
import createChannel from "../utils/createChannel";

export default function DbManagement() {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;

  const [inputValues, setInputValues] = useState({
    usadb: "",
    alfadb: "",
    betadb: "",
    gamadb: "",
    etsydb: "",
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleDbConnection = () => {
    const service = new UserService(apiReq);
    service
      .getConnectedDb()
      .then((res) => {
        // console.log("RESPONSE ---------->>", res.data)
        const { usadb, alfadb, betadb, gamadb, etsydb } = res.data.data;

        setInputValues({
          usadb: usadb,
          alfadb: alfadb,
          betadb: betadb,
          gamadb: gamadb,
          etsydb: etsydb,
        });
      })
      .catch((err) => console.log("errror", err));
  };

  React.useEffect(() => {
    handleDbConnection();
  }, []);

  return (
    <div className="container pt-4">
      <h2 className="text-center text-danger">Şu anda bağlı olan dbler:</h2>
      <div className="col-12 d-flex justify-content-center">
        <Form onSubmit={handleDbConnection}>
          <Row>
            <Col xs="3">
              <FormGroup>
                <Label for="usadb">usadb</Label>
                <Input
                  // onChange={handleInputChange}
                  value={inputValues.usadb}
                  type="text"
                  id="usadb"
                  name="usadb"
                ></Input>
              </FormGroup>
            </Col>
            <Col xs="3">
              <FormGroup>
                <Label for="alfadb">alfadb</Label>
                <Input
                  // onChange={handleInputChange}
                  value={inputValues.alfadb}
                  type="text"
                  id="alfadb"
                  name="alfadb"
                ></Input>
              </FormGroup>
            </Col>
            <Col xs="3">
              <FormGroup>
                <Label for="betadb">betadb</Label>
                <Input
                  // onChange={handleInputChange}
                  value={inputValues.betadb}
                  type="text"
                  id="betadb"
                  name="betadb"
                ></Input>
              </FormGroup>
            </Col>
            <Col xs="3">
              <FormGroup>
                <Label for="gamadb">gamadb</Label>
                <Input
                  // onChange={handleInputChange}
                  value={inputValues.gamadb}
                  type="text"
                  id="gamadb"
                  name="gamadb"
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          {/*<Row>
            <Col xs="12">
              <Button
                type="submit"
                className="btn opacity register mb-3 mt-5 p-2"
                disabled={!inputValues.dataDate || !inputValues.country || !inputValues.importOption || loading === true}
              >
                Import
              </Button>
            </Col>
          </Row>*/}
        </Form>
      </div>
    </div>
  );
}
