import React, { useContext } from "react";

import AuthContext from "../context/AuthContext";
import { Navigate, Outlet } from "react-router-dom";
// import Login from "../pages/login";
import Users from "../pages/users";
import Loading from "../components/tools/loading";
import NotAuthorized from "../components/notAuthorized";

function AdminRoute({ children }) {
  const { user, isLoading } = useContext(AuthContext);

  if (!isLoading && !user) {
    return <Navigate to="/login" />;
  } else if (user && user?.role === "dwtqXfOhfh") {
    // navigate("/users");
    return <Outlet />;
  } else if (isLoading) {
    return <Loading />;
  } else if (user && user?.role === "devRxvn") {
    return <Users />;
  } else {
    return <NotAuthorized />;
  }
}

export default AdminRoute;
