import React, { useRef } from "react";

import AdminDashboardLayout from "../layouts/adminDashboard";
import Breadcrumb from "../components/shared/breadcrumb";
import Payment from "../components/payment";
// import * as FileSaver from "file-saver";
// import * as XLSX from "xlsx";
import UserService from "../services/UserService";
import createChannel from "../utils/createChannel";

export default function Developer(props) {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;

  const service = new UserService(apiReq);
  let handleDeployProject = (e) => {
    e.preventDefault();
    service.deploy().then((res) => console.log(res.data.data));
  };

  const handleExportYunusEmre = () => {
    // const exportYEmre = new UserProxy();
    // exportYEmre.exportYEmre().then((res) => {
    //   console.log(res.data);
    // });
  };

  const handleExportVerifiedNumber = () => {
    //APIDE YERI YOK
    // const verifiedPhoneNumber = new UserProxy();
    // verifiedPhoneNumber.verifiedPhoneNumber().then((res) => {
    //   const newData = [{}];
    //   // console.log("props favKeyword", res.data);
    //   const array = res.data;
    //   for (let i = 0; i < array.length; i++) {
    //     let tempObj = {
    //       SIRA: i + 1,
    //       GSM_NO: array[i].gsm_number,
    //       AD: array[i].isim,
    //       SOYAD: array[i].soyad,
    //       EMAIL: array[i].email,
    //       "DOĞRULAMA TARİHİ": array[i].verification_date,
    //     };
    //     newData.push(tempObj);
    //   }
    //   const ws = XLSX.utils.json_to_sheet(newData);
    //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    //   const data = new Blob([excelBuffer], {
    //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    //   });
    //   FileSaver.saveAs(data, "15 Aralık Telefon Doğrulama 15-12-21.xlsx");
    //   console.log(res.data);
    // });
  };

  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Developer Tools" />
        <div className="text-center my-5">
          <button className="btn btn-primary" onClick={handleDeployProject}>
            Deploy
          </button>
          <button className="btn btn-primary" onClick={handleExportYunusEmre}>
            Export (Y.Emre)
          </button>
          <button
            className="btn btn-primary"
            onClick={handleExportVerifiedNumber}
          >
            Telefon No Doğrulayanlar
          </button>
        </div>
        <Payment />
      </AdminDashboardLayout>
    </div>
  );
}
