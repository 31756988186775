import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";

export default function AffiliateDetailsCouponTable(props) {
  const [affiliateShare, setAffiliateShare] = useState(0);
  const [usage, setUsage] = useState(0);
  const [month, setMonth] = useState(new Date().getMonth());

  const handleMonthChange = (e) => {
    const { value } = e.target;
    setMonth(value);
    filterCouponUsage(value);
  };
  const filterCouponUsage = (month) => {
    let usage = props.coupon.usage.filter(
      (el) => new Date(el.date).getMonth() == month
    );
    let totalMoney = 0;
    if (usage.length > 0) {
      usage.map((el) => {
        totalMoney += el.affiliateShare;
      });
    }
    setAffiliateShare(totalMoney);
    setUsage(usage.length);
  };
  useEffect(() => {
    filterCouponUsage(new Date().getMonth());
  }, []);
  return (
    <tr>
      <th scope="row">{props.no}</th>
      <td>{props.coupon.code}</td>
      <td>
        {props.coupon.is_valid ? (
          <span style={{ color: "green" }}>Active</span>
        ) : (
          <span style={{ color: "red" }}>Passive</span>
        )}
      </td>
      <td>{props.coupon.plan_name}</td>
      <td>{affiliateShare} TL</td>
      <td>{usage}</td>
      <td>{props.coupon.userInfoId}</td>
      <td>
        <Input
          style={{ height: "20px !important" }}
          className="p-1 m-0"
          type="select"
          name={props.coupon.code}
          id={props.coupon.code}
          bsSize="sm"
          value={month}
          onChange={handleMonthChange}
        >
          <option value="0">OCAK</option>
          <option value="1">ŞUBAT</option>
          <option value="2">MART</option>
          <option value="3">NİSAN</option>
          <option value="4">MAYIS</option>
          <option value="5">HAZIRAN</option>
          <option value="6">TEMMUZ</option>
          <option value="7">AĞUSTOS</option>
          <option value="8">EYLÜL</option>
          <option value="9">EKİM</option>
          <option value="10">KASIM</option>
          <option value="11">ARALIK</option>
        </Input>
      </td>
    </tr>
  );
}
