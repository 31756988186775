import React from "react";
import AdminDashboardLayout from "../layouts/adminDashboard";
import BillingDetails from "../components/billingDetails";
import Breadcrumb from "../components/shared/breadcrumb";

export default function BillingPanel(props) {
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Fatura Paneli" />
        <BillingDetails />
      </AdminDashboardLayout>
    </div>
  );
}
