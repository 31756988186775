import React from "react";
import AdminDashboardLayout from "../layouts/adminDashboard";
import Breadcrumb from "../components/shared/breadcrumb";
import DataImport from "../components/dataImport";
import DbManagement from "../components/dbManagement";
import BackupDb from "../components/backupDb";
import ChangeDb from "../components/changeDb";
// import EnvironmentManagement from "../components/EnvironmentManagement"

export default function DataImports(props) {
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Veri Girişi" />
        {/* <EnvironmentManagement /> */}
        <DbManagement />
        <ChangeDb />
        <BackupDb />
        <DataImport />
      </AdminDashboardLayout>
    </div>
  );
}
