import React from "react";
import AdminDashboardLayout from "../layouts/adminDashboard";
import LostMember from "../components/lostMember";
import Breadcrumb from "../components/shared/breadcrumb";

export default function LostMembers(props) {
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Kaybedilen Aboneler" />
        <LostMember />
      </AdminDashboardLayout>
    </div>
  );
}
