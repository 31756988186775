import React, { useState } from "react";
import AdminDashboardLayout from "../layouts/adminDashboard";
import Breadcrumb from "../components/shared/breadcrumb";
import ProductURL from "../components/productURL";

export default function ProductSales(props) {
  const [inputs, setInputs] = useState({
    userId: "",
    plan: 1,
    channel: "Stripe",
    packageId: 2,
    discount: 0,
    salesURL: "",
  });

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleCreateSalesURL = (e) => {
    e.preventDefault();
    // let createSalesURL = new UserProxy();
    // createSalesURL
    //     .createSalesURL(inputs.userId, inputs.packageId, inputs.plan, inputs.channel, inputs.discount)
    //     .then((res) => {
    //         // console.log(res.data)
    //         setInputs({ ...inputs, salesURL: res.data })
    //     }).catch((err) => {
    //         alert(err.response.data.message)
    //     })
  };

  React.useEffect(() => {
    // if (localStorage.getItem("token") != null) {
    //   props.checkAdminAccess();
    // }
  }, []);
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Ürün satış" />
        <ProductURL
          handleCreateSalesURL={handleCreateSalesURL}
          handleInputChange={handleInputChange}
          userId={inputs.userId}
          packageId={inputs.packageId}
          plan={inputs.plan}
          channel={inputs.channel}
          discount={inputs.discount}
          salesURL={inputs.salesURL}
        />
      </AdminDashboardLayout>
    </div>
  );
}
