import React, { useState, useRef, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import Breadcrumb from "../components/shared/breadcrumb";
import AdminDashboardLayout from "../layouts/adminDashboard";
import GiftMemberAdd from "../components/giftMember/giftMemberAdd";
import GiftMemberList from "../components/giftMember/giftMemberList";
import SearchComponent from "../components/shared/searchComponent";

import createChannel from "../utils/createChannel";
import UserService from "../services/UserService";

import "react-toastify/dist/ReactToastify.css";
import alertNotification from "../utils/alertNotification";

export default function Members(props) {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;

  // const successAlert = useRef();
  // const successAlertAdd = useRef();
  // const errorAlert = useRef();
  // const errorAlertAdd = useRef();
  const [giftMembers, setGiftMembers] = useState([]);
  const [inputValues, setInputValues] = useState({
    searchData: null,
    startDate: Date.now(),
    endDate: Date.now() + 86400000 * 30,
    userId: null,
    packageId: 2,
  });
  const [endModal, setEndModal] = useState(false);
  const [giftId, setGiftId] = useState();
  const [page, setPage] = useState(1);

  const toggleEndModal = (id) => {
    setGiftId(id);
    setEndModal(!endModal);
  };

  const service = new UserService(apiReq);

  const getGiftMembers = (e) => {
    if (e) {
      e.preventDefault();
    }

    service
      .getGiftMembers(inputValues.searchData, page)
      .then((res) => setGiftMembers(res.data.data))
      .catch((err) => console.log(err));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleAddGiftMember = (e) => {
    e.preventDefault();

    service
      .addGiftMember(inputValues)
      .then((res) => {
        alertNotification("success", "Hediye tanımlama başarılı");
        getGiftMembers();
      })
      .cathc((err) => alertNotification("error", "Hata oluştu"));
  };

  const handleEndGiftMember = () => {
    service
      .pasifyGiftMember(giftId)
      .then((res) => {
        alertNotification("success", "Hediye sonlandırıldı");
        setEndModal(false);
        getGiftMembers();
      })
      .cathc((err) => {
        alertNotification("error", "Hata oluştu");
      });
  };
  useEffect(() => {
    getGiftMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Hediye Abonelik" />

        <ToastContainer />
        <SearchComponent
          handleInputChange={handleInputChange}
          getData={getGiftMembers}
          inputValues={inputValues}
        />
        <GiftMemberAdd
          handleInputChange={handleInputChange}
          handleAddGiftMember={handleAddGiftMember}
          inputValues={inputValues}
        />
        <h5 className="text-center" style={{ color: "red" }}>
          Tarih seçimi yapmamamız durumunda şu andan başlayarak 30 günlük paket
          tanımlanır!
        </h5>

        <Modal isOpen={endModal} toggle={toggleEndModal}>
          <ModalHeader toggle={toggleEndModal}>
            Hediye Abonelik Sonlandırma
          </ModalHeader>
          <ModalBody style={{ color: "red", fontSize: "1.2rem" }}>
            Hediye Abonelik sonlandırılacaktır. Onaylıyor musunuz?
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleEndGiftMember}>Onayla</Button>{" "}
            <Button color="primary" onClick={toggleEndModal}>
              İptal Et
            </Button>
          </ModalFooter>
        </Modal>

        <GiftMemberList
          giftMembers={giftMembers}
          toggleEndModal={toggleEndModal}
          getGiftMembers={getGiftMembers}
          page={page}
          setPage={setPage}
        />
      </AdminDashboardLayout>
    </div>
  );
}
