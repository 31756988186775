import React, { useState, useRef } from "react";
import AdminDashboardLayout from "../layouts/adminDashboard";
import Breadcrumb from "../components/shared/breadcrumb";
import { useParams } from "react-router-dom";
import { Card, Row, Col, CardBody, CardTitle, Table } from "reactstrap";
import { ToastContainer } from "react-toastify";
import UserService from "../services/UserService";
import createChannel from "../utils/createChannel";
import alertNotification from "../utils/alertNotification";
export default function UserDetails(props) {
  const channel = useRef(createChannel());
  const apiRequest = channel.current.request;

  const params = useParams();
  const [userData, setUserData] = useState(null);
  // const [verification, setVerification] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  const getUserDetails = () => {
    const service = new UserService(apiRequest);

    service
      .getUserDetails(params.id)
      .then((res) => setUserData(res.data.data))
      .catch((err) => console.log(err));
  };

  const verifyUser = () => {
    const service = new UserService(apiRequest);
    service
      .updateUserVerification(params.id)
      .then((res) => {
        alertNotification("success", "Kullanıcı aktivasyonu başarılı");
        getUserDetails();
      })
      .catch((err) => {
        alertNotification("error", "Hata oluştu");
      });
  };

  const resetPassword = () => {
    const service = new UserService(apiRequest);

    service
      .resetPasswordbyAdmin(userData.id)
      .then((res) => {
        console.log("resss", res);
        setNewPassword(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {userData != null && (
        <AdminDashboardLayout>
          <ToastContainer />
          <Breadcrumb title={userData.isim + " " + userData.soyad} />
          <Row className="my-4 mx-4">
            <Col className="col-6">
              <Card>
                <CardTitle className="text-center mt-4">
                  Kullanıcı Bilgileri
                </CardTitle>
                <CardBody>
                  <h5>Kullanıcı ID: {userData.id}</h5>
                  <hr />
                  <h5>İsim-Soyisim: {userData.isim + " " + userData.soyad}</h5>
                  <hr />
                  <h5>Email: {userData.email}</h5>
                  <hr />
                  <h5>
                    Tel Num: {userData.gsm_number ? userData.gsm_number : "-"}
                  </h5>
                  <hr />
                  <h5>
                    Verify:{" "}
                    {userData.verify ? (
                      <span style={{ color: "green" }}>Evet </span>
                    ) : (
                      <span style={{ color: "red" }}>
                        Hayır{"      "}
                        <button
                          onClick={verifyUser}
                          style={{
                            padding: "5px",
                            backgroundColor: "#007bff",
                            color: "white",
                            border: "none",
                            borderRadius: "10px",
                          }}
                        >
                          Verify
                        </button>
                      </span>
                    )}
                  </h5>
                  <hr />
                  <h5>Trafik: {userData.user_stats}</h5>
                  <hr />
                  <h5>
                    Üyelik Başlama:{" "}
                    {new Date(userData.createdAt).toLocaleString()}
                  </h5>
                  <hr />
                  <h5>
                    Son Giriş: {new Date(userData.updatedAt).toLocaleString()}
                  </h5>
                  <hr />
                  <h5>Customer Reference: {userData.customer_referance}</h5>
                  <hr />
                  <button
                    onClick={resetPassword}
                    style={{
                      padding: "5px",
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "none",
                      borderRadius: "10px",
                    }}
                  >
                    Şifre Sıfırla
                  </button>
                  {newPassword !== "" && <h5>Yeni Şifre: {newPassword}</h5>}
                </CardBody>
              </Card>
            </Col>
            <Col className="col-6">
              <Card>
                <CardTitle className="text-center mt-4">
                  Fatura Adres Bilgileri
                </CardTitle>
                <CardBody>
                  {userData.user_bills.map((i) => {
                    return (
                      <div>
                        <h5>Fatura Adres ID: {i.id} </h5>
                        <hr />
                        <h5>
                          Fatura Tipi:{" "}
                          {i.is_corporate === true ? (
                            <span style={{ color: "green" }}>Kurumsal</span>
                          ) : (
                            <span style={{ color: "red" }}>Bireysel</span>
                          )}
                        </h5>
                        <hr />
                        <h5>İsim-Soyisim: {i.name + " " + i.surname}</h5>
                        <hr />
                        <h5>TC Kimlik: {i.identity_number}</h5>
                        <hr />
                        <h5>Telefon No: {i.gsm_number}</h5>
                        <hr />
                        <h5>
                          Adres: {i.registration_address} {i.city} / {i.country}{" "}
                          {i.zipCode}{" "}
                        </h5>
                        <hr />
                        <h5>Şirket Adı: {i.company_name}</h5>
                        <hr />
                        <h5>
                          Vergi No: {i.tax_number} Vergi Dairesi: {i.tax_office}
                        </h5>
                        <hr />
                      </div>
                    );
                  })}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="my-4 mx-4">
            <Col className="col-12">
              <Card>
                <CardTitle className="text-center mt-4">
                  Abonelik Bilgileri
                </CardTitle>
                <CardBody>
                  <Table striped>
                    <thead>
                      <tr>
                        <th>Paket</th>
                        <th>Plan</th>
                        <th>Satın Alma Tarihi</th>
                        <th>Deneme Bitiş</th>
                        <th>Paket Başlangıç </th>
                        <th>İptal Tarihi</th>
                        <th>Paket Bitiş</th>
                        <th>Durum</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.members.map((member) => {
                        return (
                          <tr>
                            <td>
                              {member.packageId === 2
                                ? "Web"
                                : member.packageId === 3
                                  ? "Extension"
                                  : "Full Paket"}
                            </td>
                            <td>
                              {member.packagePlanId === 2 ||
                                member.packagePlanId === 4 ||
                                member.packagePlanId === 6
                                ? "Aylık"
                                : "Yıllık"}
                            </td>
                            <td>
                              {new Date(member.createdAt).toLocaleString()}
                            </td>
                            <td>
                              {new Date(member.start_date).toLocaleString()}
                            </td>
                            <td>
                              {new Date(member.start_date).toLocaleString()}
                            </td>
                            <td>
                              {member.cancel_date
                                ? new Date(member.cancel_date).toLocaleString()
                                : "-"}
                            </td>
                            <td>
                              {member.end_date
                                ? new Date(member.end_date).toLocaleString()
                                : "-"}
                            </td>
                            <td>
                              {member.is_active ? (
                                <span style={{ color: "green" }}>Aktif</span>
                              ) : (
                                <span style={{ color: "red" }}>Pasif</span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="my-4 mx-4">
            <Col className="col-12">
              <Card>
                <CardTitle className="text-center mt-4">
                  Hediye Abonelik Bilgileri
                </CardTitle>
                <CardBody>
                  <Table striped>
                    <thead>
                      <tr>
                        <th>Paket</th>
                        <th>Başlangıç Tarihi</th>
                        <th>Bitiş Tarihi</th>
                        <th>İptal Tarihi</th>
                        <th>Durum</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.gift_members.map((member) => {
                        return (
                          <tr>
                            <td>
                              {member.packageId === 2
                                ? "Web"
                                : member.packageId === 3
                                  ? "Extension"
                                  : "Full Paket"}
                            </td>
                            <td>
                              {new Date(member.start_date).toLocaleString()}
                            </td>
                            <td>
                              {new Date(member.end_date).toLocaleString()}
                            </td>
                            <td>
                              {member.cancel_date
                                ? new Date(member.cancel_date).toLocaleString()
                                : "-"}
                            </td>
                            <td>
                              {member.is_active ? (
                                <span style={{ color: "green" }}>Aktif</span>
                              ) : (
                                <span style={{ color: "red" }}>Pasif</span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="my-4 mx-4">
            <Col className="col-12">
              <Card>
                <CardTitle className="text-center mt-4">Faturalar</CardTitle>
                <CardBody>
                  <Table striped>
                    <thead>
                      <tr>
                        <th>Fatura Id</th>
                        <th>Paket/Hizmet</th>
                        <th>Miktar</th>
                        <th>Fatura Tarihi</th>
                        <th>Oluşturma Tarihi</th>
                        <th>Durum</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.orders.map((bill) => {
                        return (
                          <tr>
                            <td>{bill.id}</td>
                            <td>
                              {bill.package
                                ? `${bill.package.name} ${bill.package_plan.duration === 1
                                  ? "Aylık"
                                  : "Yıllık"
                                }`
                                : bill.service && bill.service.name}
                            </td>
                            <td>
                              {bill.paid_price} {bill.currency_code}
                            </td>
                            <td>
                              {new Date(bill.billing_date).toLocaleString()}
                            </td>
                            <td>{new Date(bill.createdAt).toLocaleString()}</td>
                            <td>
                              <span
                                style={{
                                  color:
                                    bill.payment_status === "SUCCESS"
                                      ? "green"
                                      : bill.payment_status === "UNPAID"
                                        ? "red"
                                        : "blue",
                                }}
                              >
                                {bill.payment_status}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="my-4 mx-4">
            <Col className="col-12">
              <Card>
                <CardTitle className="text-center mt-4">
                  Ödeme Kayıtları
                </CardTitle>
                <CardBody>
                  <Table striped>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Miktar</th>
                        <th>Ödeme</th>
                        <th>Hata</th>
                        <th>Tarih</th>
                        <th>İade</th>
                        <th>Fiş</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.payment_logs.map((i) => {
                        return (
                          <tr>
                            <td>{i.id}</td>
                            <td>
                              {i.amount} {i.currency}{" "}
                            </td>
                            <td>
                              {i.completed ? (
                                <span style={{ color: "green" }}>Başarılı</span>
                              ) : (
                                <span style={{ color: "red" }}>Başarısız</span>
                              )}
                            </td>
                            <td>
                              {i.error_message
                                ? `${i.error_type} ${i.error_code} ${i.error_message}`
                                : "-"}
                            </td>
                            <td>{new Date(i.createdAt).toLocaleString()}</td>
                            <td>
                              {i.refunded ? (
                                <span style={{ color: "red" }}>
                                  İade Edildi
                                </span>
                              ) : (
                                <span style={{ color: "green" }}>Hayır</span>
                              )}
                            </td>
                            <td>
                              {i.receipt_url ? (
                                <a
                                  href={i.receipt_url}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  receipt
                                </a>
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </AdminDashboardLayout>
      )}
    </div>
  );
}
