import React, { useContext } from "react";
import { Link } from "react-router-dom";

var BASEDIR = process.env.REACT_APP_BASEDIR;
const Navigation = () => {
  return (
    <nav className="mainmenu__nav">
      <ul className="mainmenu">
        {/*<li className="drop">
          <Link to={process.env.PUBLIC_URL + "/"}>Ana Sayfa</Link>
  </li>*/}
        <li className="drop">
          <Link to={process.env.PUBLIC_URL + "/nasil-calisir"}>
            Nasıl Çalışır
          </Link>
        </li>
        <li className="drop">
          <Link to={process.env.PUBLIC_URL + "/dashboard-home"}>Araçlar</Link>
        </li>
        <li className="drop">
          <Link to={process.env.PUBLIC_URL + "/select-package"}>Paketler</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/iletisim"}>İletişim</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/free-content"}>Ücretsiz</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/select-service"}>
            Servis Seç
          </Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/blogs"}>Blog</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
