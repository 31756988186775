import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import AdminDashboardLayout from "../layouts/adminDashboard";

function NotAuthorized() {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  const reLog = () => {
    logout();
    navigate("/login");
  };
  return (
    <>
      <h1>Bu sayfaya erişim izniniz bulunmamaktadır</h1>
      <button onClick={reLog}>Giriş Yap</button>
    </>
  );
}

export default NotAuthorized;
