import React, { useRef, useState } from "react";

import { NavLink } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
// import { ToastContainer, toast } from "react-toastify";
import Pagination from "../components/shared/pagination";
import createChannel from "../utils/createChannel";
import UserService from "../services/UserService";

export default function BillingDetails() {
  const channel = useRef(createChannel());
  const apiRequest = channel.current.request;

  const [billingDetails, setBillingDetails] = useState([]);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [page, setPage] = useState(1);
  const [billId, setBillId] = useState(null);

  const toggle1 = (e) => {
    setBillId(e.target.id);
    setModal1(!modal1);
  };
  const toggle2 = (e) => {
    setBillId(e.target.id);
    setModal2(!modal2);
  };
  const service = new UserService(apiRequest);

  const handleCancelBill = () => {
    // const cancelBill = new UserProxy();
    // cancelBill
    //   .cancelBill(billId)
    //   .then((res) => {
    //     if (res.data === true) {
    //       getBillingDetails();
    //       let optionsSuccess = {};
    //       optionsSuccess = {
    //         place: "tc",
    //         message: (
    //           <div className="notification-msg">
    //             <div>İptal Başarıyla Sağlandı.</div>
    //           </div>
    //         ),
    //         type: "success",
    //         icon: "",
    //         autoDismiss: 7,
    //       };
    //       successAlert.current.notificationAlert(optionsSuccess);
    //     }
    //   })
    //   .catch((err) => {
    //     let optionsError = {};
    //     optionsError = {
    //       place: "tc",
    //       message: (
    //         <div className="notification-msg">
    //           <div>Bir Hata Oluştu!</div>
    //         </div>
    //       ),
    //       type: "danger",
    //       icon: "",
    //       autoDismiss: 7,
    //     };
    //     errorAlert.current.notificationAlert(optionsError);
    //   });
    // setModal1(!modal1);
  };

  const handleRetryPayment = () => {
    // let retryPayment = new UserProxy();
    // retryPayment
    //   .retryPayment(billId)
    //   .then((res) => {
    //     if (res.data.message === "success") {
    //       getBillingDetails();
    //       var retrySuccess = {};
    //       retrySuccess = {
    //         place: "tc",
    //         message: (
    //           <div className="notification-msg">
    //             <div>Yeniden ödeme denemesi yapılmıştır!.</div>
    //           </div>
    //         ),
    //         type: "success",
    //         icon: "",
    //         autoDismiss: 7,
    //       };
    //       retryPaymentSuccess.current.notificationAlert(retrySuccess);
    //     } else {
    //       var retryError = {};
    //       retryError = {
    //         place: "tc",
    //         message: (
    //           <div className="notification-msg">
    //             <div>Bir hata oluştu.</div>
    //           </div>
    //         ),
    //         type: "danger",
    //         icon: "",
    //         autoDismiss: 7,
    //       };
    //       retryPaymentError.current.notificationAlert(retryError);
    //     }
    //   })
    //   .catch((err) => {
    //     var retryError = {};
    //     retryError = {
    //       place: "tc",
    //       message: (
    //         <div className="notification-msg">
    //           <div>{err.response.data.message}</div>
    //         </div>
    //       ),
    //       type: "danger",
    //       icon: "",
    //       autoDismiss: 7,
    //     };
    //     retryPaymentError.current.notificationAlert(retryError);
    //   });
    // setModal2(!modal2);
  };

  function getBillingDetails(e) {
    if (e) {
      e.preventDefault();
    }

    service
      .getBillingInfo(page)
      .then((res) => setBillingDetails(res.data.data))
      .catch((err) => console.log(err));
  }

  React.useEffect(() => {
    getBillingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  return (
    <div>
      <Modal isOpen={modal1} toggle={toggle1}>
        <ModalHeader toggle={toggle1}>Fatura İptal</ModalHeader>
        <ModalBody style={{ color: "red", fontSize: "1.2rem" }}>
          Fatura iptal edilecektir. Onaylıyor musunuz?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCancelBill}>
            Onayla
          </Button>{" "}
          <Button color="secondary" onClick={toggle1}>
            İptal Et
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal2} toggle={toggle2}>
        <ModalHeader toggle={toggle2}>Tekrar Ödeme</ModalHeader>
        <ModalBody style={{ color: "red", fontSize: "1.2rem" }}>
          Tekrar ödeme talep edilecektir. Onaylıyor musunuz?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleRetryPayment}>
            Onayla
          </Button>{" "}
          <Button color="secondary" onClick={toggle2}>
            İptal Et
          </Button>
        </ModalFooter>
      </Modal>
      <Table striped>
        <thead>
          <tr>
            <th>Id</th>
            <th>Email</th>
            <th>İsim </th>
            <th>Soyisim</th>
            <th>Paket/Ürün</th>
            <th>Miktar</th>
            <th>Fatura Tarihi</th>
            <th>Durum</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {billingDetails.map((i) => {
            return (
              <tr>
                <th>{i.id}</th>
                <td>
                  <NavLink to={`/user-details/${i.userInfoId}`}>
                    <span style={{ color: "blue" }}>
                      {i.user_info === null ? "-" : i.user_info.email}
                    </span>
                  </NavLink>
                </td>
                <td>{i.user_info === null ? "-" : i.user_info.isim}</td>
                <td>{i.user_info === null ? "-" : i.user_info.soyad}</td>
                <td>
                  {i.package
                    ? `${i.package.name} ${i.package_plan.duration === 1 ? "Aylık" : "Yıllık"
                    }`
                    : i.service && i.service.name}{" "}
                  { }
                </td>
                <td>
                  {i.paid_price} {i.currency_code}
                </td>
                <td>{new Date(i.billing_date).toLocaleString()}</td>
                <td>
                  <span
                    style={{
                      color:
                        i.payment_status === "SUCCESS"
                          ? "green"
                          : i.payment_status === "UNPAID"
                            ? "red"
                            : "blue",
                    }}
                  >
                    {i.payment_status}
                  </span>
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-success mx-1 my-1"
                    style={{
                      fontSize: "0.8rem",
                      display: i.payment_status === "UNPAID" ? "" : "none",
                    }}
                    id={i.id}
                    onClick={toggle2}
                  >
                    Ödeme Dene
                  </button>
                  <button
                    className="btn btn-sm btn-danger mx-1 my-1"
                    style={{
                      fontSize: "0.8rem",
                      display: i.payment_status === "SUCCESS" ? "" : "none",
                    }}
                    id={i.id}
                    onClick={toggle1}
                  >
                    İptal Et
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Pagination page={page} setpage={setPage} />
    </div>
  );
}
