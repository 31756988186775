import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import UserService from "../services/UserService";
import createChannel from "../utils/createChannel";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

export default function MonthlyIncomeNew(props) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;

  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);

  const service = new UserService(apiReq);

  const getMonthlySummary = () => {
    service
      .getMonthlyRevenue()
      .then((res) => {
        const label = res.data.data.map((x) => x.month);
        const dat = res.data.data.map((x) => x.revenue);

        setData(dat);
        setLabels(label);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getMonthlySummary();
  }, []);

  const data312 = {
    labels: labels,
    datasets: [
      {
        label: "Revenue",
        fill: true,
        lineTension: 0.2,
        backgroundColor: "rgba(38, 166, 154,.8)",
        borderColor: "rgba(38, 166, 154,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#fafafa",
        pointBackgroundColor: "rgba(38, 166, 154,1)",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(38, 166, 154,1)",
        pointHoverBorderColor: "#eeeeee",
        pointHoverBorderWidth: 1,
        pointRadius: 4,
        pointHitRadius: 10,
        data: data,
      },
    ],
  };

  const options312 = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      bodySpacing: 4,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
      xPadding: 10,
      yPadding: 10,
      caretPadding: 10,
    },
    responsive: 1,
    scales: {
      yAxis: [
        {
          categoryPercentage: 0.8,
          barPercentage: 0.6,
          maxBarThickness: 12,
          display: 1,
          gridLines: {
            color: "rgba(0, 0, 0, 0.01)",
          },
          ticks: {
            display: true,
          },
        },
      ],
      xAxis: [
        {
          display: 1,
          gridLines: {
            color: "rgba(0, 0, 0, 0.01)",
          },
          ticks: {
            display: true,
          },
        },
      ],
    },
    layout: {
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
    },
  };

  return (
    <div>
      <section className="box ">
        <header className="panel_header">
          <h2 className="title float-left">Gelir Grafiği</h2>
        </header>
        <div className="content-body">
          <div className="row">
            <div className="col-10">
              <div className="chart-container">
                {labels.length !== 0 && data.length !== 0 && (
                  <Line data={data312} options={options312} height={300} />
                )}
              </div>
            </div>
            {data.length !== 0 && (
              <div className="col-2">
                <h3>Cari:</h3>
                <h4>{data[11]} TL</h4>
                <h3>Toplam:</h3>
                <h4>
                  {Math.ceil(data.reduce((a, b) => a + b, 0) * 100) / 100} TL
                </h4>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
