import React from 'react'

import {
    Chart as ChartJS,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

function SubDoughnutCharts({ active, passive }) {
    ChartJS.register(
        ArcElement,
        LineElement,
        BarElement,
        PointElement,
        BarController,
        BubbleController,
        DoughnutController,
        LineController,
        PieController,
        PolarAreaController,
        RadarController,
        ScatterController,
        CategoryScale,
        LinearScale,
        LogarithmicScale,
        RadialLinearScale,
        TimeScale,
        TimeSeriesScale,
        Decimation,
        Filler,
        Legend,
        Title,
        Tooltip,
        SubTitle
    );


    const dataActive = {
        labels: ['Yearly', 'Monthly'],
        datasets: [
            {
                label: "Subscription",
                fill: true,
                lineTension: 0.2,
                backgroundColor: ["rgb(86,203,255)", "rgb(9,44,95)",],
                borderColor: "black",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#fafafa",
                pointBackgroundColor: ["rgb(86,203,255)", "rgb(9,44,95)",],
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: ["rgb(86,203,255)", "rgb(9,44,95)",],
                pointHoverBorderColor: ["rgb(86,203,255)", "rgb(9,44,95)",],
                pointHoverBorderWidth: 1,
                pointRadius: 4,
                pointHitRadius: 10,
                data: active,
            }
        ],
    };

    const dataPassive = {
        labels: ['Yearly', 'Monthly'],
        datasets: [
            {
                label: "Subscription",
                fill: true,
                lineTension: 0.2,
                backgroundColor: ["rgb(86,203,255)", "rgb(9,44,95)",],
                borderColor: "black",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#fafafa",
                pointBackgroundColor: ["rgb(86,203,255)", "rgb(9,44,95)",],
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: ["rgb(86,203,255)", "rgb(9,44,95)",],
                pointHoverBorderColor: ["rgb(86,203,255)", "rgb(9,44,95)",],
                pointHoverBorderWidth: 1,
                pointRadius: 4,
                pointHitRadius: 10,
                data: passive,
            }
        ],
    };

    return (
        < >
            <div className='tile-progress inverted  subsDoughnutGraph'>
                <div className='doughnutTitles'>
                    <h3>Aktif Üyelerin Aylık/Yıllık Dağılımı</h3>
                    <h5>Yearly:{active[0]}</h5>
                    <h5>Monthly:{active[1]}</h5>
                </div>
                <Doughnut data={dataActive} />
            </div>
            <div className='tile-progress inverted  subsDoughnutGraph'>
                <div className='doughnutTitles'>
                    <h3>İptal Edilen Aboneliklerin Aylık/Yıllık Dağılımı</h3>
                    <h5>Yearly:{passive[0]}</h5>
                    <h5>Monthly:{passive[1]}</h5>
                </div>
                <Doughnut data={dataPassive} />
            </div>
        </>
    )
}

export default SubDoughnutCharts;