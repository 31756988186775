import PropTypes from "prop-types";
import React from "react";

import AdminSidebar from "../components/adminSidebar";
import Header from "../components/dashboardComponents/Header/Header";
import Footer from "../components/dashboardComponents/Footer/Footer";

const adminRoutes = [
  { path: "/", name: "İstatistikler", icon: "pie-chart" },
  { path: "/users", name: "Kullanıcılar", icon: "user" },
  { path: "/members", name: "Aboneler", icon: "credit-card" },
  { path: "/billing-panel", name: "Fatura Paneli", icon: "notebook" },
  { path: "/affiliates", name: "Affiliates", icon: "user" },
  { path: "/coupons", name: "Coupons", icon: "handbag" },
  { path: "/add-discounted-plan", name: "Plan Ekle", icon: "handbag" },
  { path: "/payment-logs", name: "Ödeme Kayıtları", icon: "basket" },
  { path: "/error-logs", name: "Hata Logları", icon: "exclamation" },
  { path: "/extension-error-logs", name: "Extension Hata Logları", icon: 'exclamation' },
  { path: "/lost-members", name: "Kaybedilen Aboneler", icon: "question" },
  { path: "/product-sales", name: "Ürün Satış", icon: "handbag" },
  { path: "/service-panel", name: "Hizmetler Paneli", icon: "layers" },
  { path: "/gift-members", name: "Hediye Abonelik", icon: "present" },
  { path: "/extension-category", name: "Kategori ve BSR", icon: "notebook" },
  { path: "/email-send/0", name: "Email Gönder", icon: "envelope" },
  { path: "/data-import", name: "Veri Girişi", icon: "plus" },
  { path: "/developer", name: "Developer", icon: "handbag" },
  { path: "/subscription", name: "Subscriptions", icon: "handbag" },
  {
    path: "#",
    name: "Tags",
    icon: "notebook",
    type: "dropdown",
    child: [
      { path: "/tags-subscription", name: "Abonelik İzleri", icon: "plus" },
      { path: "/tags-user", name: "Kullanıcı İzleri", icon: "plus" },
      { path: "/tags-register", name: "Kayıt İzleri", icon: "plus" },
    ],
  },
  { path: "/admin-support", name: "Destek", icon: "pencil" },
  { path: "/suggestion-complaints", name: "Öneri/Şikayet", icon: "pencil" },
];

const devRoutes = [
  { path: "/users", name: "Kullanıcılar", icon: "user" },
  { path: "/members", name: "Aboneler", icon: "credit-card" },
  { path: "/billing-panel", name: "Fatura Paneli", icon: "notebook" },
  { path: "/affiliates", name: "Affiliates", icon: "user" },
  { path: "/coupons", name: "Coupons", icon: "handbag" },
  { path: "/add-discounted-plan", name: "Plan Ekle", icon: "handbag" },
  { path: "/payment-logs", name: "Ödeme Kayıtları", icon: "basket" },
  { path: "/error-logs", name: "Hata Logları", icon: "exclamation" },
  { path: "/extension-error-logs", name: "Extension Hata Logları", icon: 'exclamation' },
  { path: "/lost-members", name: "Kaybedilen Aboneler", icon: "question" },
  { path: "/product-sales", name: "Ürün Satış", icon: "handbag" },
  { path: "/service-panel", name: "Hizmetler Paneli", icon: "layers" },
  { path: "/gift-members", name: "Hediye Abonelik", icon: "present" },
  { path: "/extension-category", name: "Kategori ve BSR", icon: "notebook" },
  { path: "/email-send/0", name: "Email Gönder", icon: "envelope" },
  { path: "/data-import", name: "Veri Girişi", icon: "plus" },
  { path: "/developer", name: "Developer", icon: "handbag" },
  {
    path: "#",
    name: "Tags",
    icon: "notebook",
    type: "dropdown",
    child: [
      { path: "/tags-subscription", name: "Abonelik İzleri", icon: "plus" },
      { path: "/tags-user", name: "Kullanıcı İzleri", icon: "plus" },
      { path: "/tags-register", name: "Kayıt İzleri", icon: "plus" },
    ],
  },
  { path: "/admin-support", name: "Destek", icon: "pencil" },
  { path: "/suggestion-complaints", name: "Öneri/Şikayet", icon: "pencil" },

];

const crmRoutes = [
  { path: "/users", name: "Kullanıcılar", icon: "user" },
  { path: "/members", name: "Aboneler", icon: "credit-card" },
  { path: "/billing-panel", name: "Fatura Paneli", icon: "notebook" },

  { path: "/lost-members", name: "Kaybedilen Aboneler", icon: "question" },
  { path: "/product-sales", name: "Ürün Satış", icon: "handbag" },
  { path: "/service-panel", name: "Hizmetler Paneli", icon: "layers" },
  { path: "/gift-members", name: "Hediye Abonelik", icon: "present" },

  { path: "/email-send/0", name: "Email Gönder", icon: "envelope" },

  { path: "/admin-support", name: "Destek", icon: "pencil" },
  { path: "/suggestion-complaints", name: "Öneri/Şikayet", icon: "pencil" },
];

const salesRoutes = [
  { path: "/users", name: "Kullanıcılar", icon: "user" },
  { path: "/members", name: "Aboneler", icon: "credit-card" },
  { path: "/payment-logs", name: "Ödeme Kayıtları", icon: "basket" },
  { path: "/coupons", name: "Coupons", icon: "handbag" },
];

const AdminDashboardLayout = ({ children, theme }, props) => {
  return (
    <div
      className="wrapper"
      data-menu="teal"
      data-menutype="dark"
      data-topbar="transparent"
      data-topbartype="dark"
      style={{ minHeight: "100vh" }}
    >
      <Header {...props} navtype="mini" />

      <AdminSidebar
        {...props}
        routes={{
          adminRoutes: adminRoutes,
          devRoutes: devRoutes,
          crmRoutes: crmRoutes,
          salesRoutes: salesRoutes
        }}
      />
      <div className="main-panel">
        <div style={{ minHeight: "90vh" }}>{children}</div>
        <Footer fluid />
      </div>
    </div>
  );
};

AdminDashboardLayout.propTypes = {
  children: PropTypes.any,
  theme: PropTypes.string,
};

export default AdminDashboardLayout;
