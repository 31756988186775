const breadCrumbs = [
  { title1: "NASIL ÇALIŞIR" },
  { title2: "ÖDEME" },
  { title3: "EĞİTİM" },
  { title4: "SATIN AL" },
  { title5: "İLETİŞİM" },
  { title6: "HESABIM" },
  { title7: "BLOG" },
];

export default breadCrumbs;
