import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Breadcrumb from "../components/shared/breadcrumb";
import Pagination from "../components/shared/pagination";

import createChannel from "../utils/createChannel";

import UserService from "../services/UserService";

import AdminDashboardLayout from "../layouts/adminDashboard";
// import { set } from "immutable";

function SuggestionComplaints() {
  // create a new axios instance
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;
  const service = new UserService(apiReq);

  // declare initial states
  const [page, setPage] = useState(1);
  const [user, setUser] = useState([]);
  const [modal, setModal] = useState(false);
  // const [status, setStatus] = useState(false);
  const [isId, setIsId] = useState(null);
  const [view, setView] = useState(false);
  const [theme, setTheme] = useState("");
  const [file, setFile] = useState("");
  const [reply, setReply] = useState("");
  const [isAnswered, setIsAnswered] = useState("");

  const fileType = file?.substring(5, 10);

  // get users inquiries from db
  // const getUserDetails = (e) => {
  //   e.preventDefault();

  //   if (e) {
  //     e.preventDefault();
  //   }
  // };

  const getUserInquiry = () => {
    service
      .getUserInquiry(page)
      .then((res) => {
        setUser(res.data.data);
      })
      .catch((err) => {
        console.log("ERROR 💥💥💥:>>", err.response.data.message);
        toast.error(err.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const toggle = (e) => {
    setModal(!modal);
    setIsId(e.target.id);
    // setStatus(true);
  };

  const updateStatusToggle = () => {
    setModal(!modal);
  };

  const viewToggle = (e) => {
    setView(!view);
    setTheme(e.target.value);
    setFile(e.target.id);
  };

  const handleUpdateStatus = () => {
    service
      .updateSuggestionInquiryStatus(isId)
      .then((res) => {
        // setIsAnswered(true)
        getUserInquiry();
      })
      .catch((err) => {
        console.log("ERROR 💥💥💥:>>", err.response.data.message);
        toast.error(err.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    updateStatusToggle();
  };

  const getEmail = (email, file, content, answered) => {
    setView(!view);
    setTheme(content);
    setFile(file);
    setReply(email);
    setIsAnswered(answered);
  };

  useEffect(() => {
    getUserInquiry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div>
      <AdminDashboardLayout>
        <Breadcrumb title="Öneri/Şikayet" />
        <ToastContainer />
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            Destek/Şikayet Durum Güncelleme
          </ModalHeader>
          <ModalBody style={{ color: "red", fontSize: "1.2rem" }}>
            İstek cevaplandı olarak işaretlenecektir. Onaylıyor musunuz?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleUpdateStatus}>
              Onayla
            </Button>
            <Button color="secondary" onClick={toggle}>
              İptal Et
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={view} toggle={viewToggle}>
          <ModalHeader toggle={viewToggle}>Öneri/Şikayet</ModalHeader>
          <ModalBody>
            {file ? (
              fileType === "image" ? (
                <figure>
                  <img src={file} alt="pic" />
                </figure>
              ) : (
                <figure>
                  <video controls src={file} width="452px" height="360px" />
                </figure>
              )
            ) : (
              ""
            )}
          </ModalBody>
          <ModalBody style={{ fontSize: "1.1rem" }}>{theme}</ModalBody>

          <ModalFooter>
            {isAnswered === "cevaplandı" ? null : (
              <NavLink to={`/email-send/${reply}`} target="_blank">
                <Button color="primary">Cevapla</Button>
              </NavLink>
            )}

            {/* <Button color="secondary" onClick={toggle}>
            İptal Et
          </Button> */}
          </ModalFooter>
        </Modal>

        <Table striped>
          <thead>
            <tr>
              <th>ID</th>
              <th>EMAIL</th>
              <th>AD-SOYAD</th>
              <th>PLATFORM</th>
              <th>KONU</th>
              <th>ÖNERİ-ŞİKAYET</th>
              <th>DURUM</th>
              <th>İŞLEM</th>
              <th>SONUÇ</th>
            </tr>
          </thead>
          <tbody>
            {user.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>
                    <NavLink
                      to={`/user-details/${item.userInfoId}`}
                      target="_blank"
                    >
                      <span style={{ color: "blue" }}>
                        {item.user_info === null
                          ? item.email
                          : item.user_info.email}
                      </span>
                    </NavLink>
                  </td>
                  <td>
                    <span>
                      {item.user_info === null ? "---" : item.user_info.isim}{" "}
                    </span>
                    <span>
                      {item.user_info === null ? "---" : item.user_info.soyad}
                    </span>
                  </td>
                  <td>{item.platform}</td>
                  <td>
                    {item.topic === "Şikayet" ? (
                      <span style={{ color: "red" }}>{item.topic}</span>
                    ) : (
                      <span>{item.topic}</span>
                    )}
                  </td>

                  <td>
                    <button
                      className="btn btn-sm btn-warning mx-1 my-1"
                      value={item.content}
                      id={item.file}
                      // disabled={item.answered === "cevaplandı"}
                      onClick={() =>
                        getEmail(
                          item.email,
                          item.file,
                          item.content,
                          item.answered
                        )
                      }
                    >
                      Görüntüle
                    </button>
                  </td>
                  <td>
                    {item.answered === "cevaplandı" ? (
                      <span style={{ color: "green" }}>Cevaplandı</span>
                    ) : (
                      <span style={{ color: "red" }}>Cevaplanmadı</span>
                    )}
                  </td>

                  <td>
                    <button
                      className="btn btn-sm btn-info mx-1 my-1"
                      disabled={item.answered === "cevaplandı"}
                    // disabled={true}
                    >
                      <NavLink
                        to={`/email-send/${item.email}`}
                        target="_blank"
                      // supportEmail={item.email}
                      >
                        Cevapla
                      </NavLink>
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-success mx-1 my-1"
                      onClick={toggle}
                      id={item.id}
                      value={item.answered}
                      disabled={item.answered === "cevaplandı"}
                    >
                      Bitir
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="text-center" style={{ marginLeft: "45%" }}>
          <Pagination page={page} setPage={setPage} />
        </div>
      </AdminDashboardLayout>
    </div>
  );
}

export default SuggestionComplaints;
