import React, { useRef } from "react";

import { Table } from "reactstrap";
import { useState, useEffect } from "react";
import Pagination from "./shared/paginationSubsTag";
import { Input, Label, Form, Row, Col, Button, FormGroup } from "reactstrap";
import createChannel from "../utils/createChannel";
import UserService from "../services/UserService";

export default function SubscriptionTag() {
  const channel = useRef(createChannel());
  const apiReq = channel.current.request;

  const [subsTag, setSubsTag] = useState([]);
  const [page, setPage] = useState(1);
  const [plan, setPlan] = useState(false);
  const [date, setDate] = useState();
  const [duration, setDuration] = useState();

  const service = new UserService(apiReq);

  function getSubscriptionTag(e) {
    if (e) {
      e.preventDefault();
    }

    service
      .getSubscriptionTags(page, date, duration)
      .then((res) => {
        if (duration === "yıllık") {
          setPlan(true);
        } else if (duration === "aylık") {
          setPlan(false);
        }
        setSubsTag(res.data.data);
      })
      .catch((err) => console.log(err));
    // let subsTag = new UserProxy();
    // subsTag.getSubscriptionTag(newPage, date, plan, duration).then((res) => {
    //   if (duration == "yıllık") {
    //     setPlan(true);
    //   } else if (duration == "aylık") {
    //     setPlan(false);
    //   }
    //   setSubsTag(res.data);
    // });
  }

  const handleInputChange = (e) => {
    e.preventDefault();
    setDuration(e.target.value);
    // getSubscriptionTag();
  };

  const handleDateChange = (e) => {
    e.preventDefault();
    setDate(e.target.value);
    // getSubscriptionTag();
  };

  useEffect(() => {
    getSubscriptionTag();
  }, [page]);

  return (
    <div>
      <div className="container pt-2">
        <h3 className="text-center text-danger">
          İncelemek İstediğiniz Verileri Seçiniz
        </h3>
        <div className="col-12 d-flex justify-content-center">
          <Form onSubmit={getSubscriptionTag}>
            <Row>
              <Col xs="3">
                <FormGroup>
                  <Label for="plan">Plan</Label>
                  <Input
                    type="select"
                    name="plan"
                    id="plan"
                    value={duration}
                    onChange={handleInputChange}
                    required
                  >
                    <option>Seçiniz</option>
                    <option value={"aylık"}>Aylık</option>
                    <option value={"yıllık"}>Yıllık</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs="5">
                <FormGroup>
                  <Label for="date">Tarih</Label>
                  <Input
                    type="select"
                    name="date"
                    id="date"
                    value={date}
                    onChange={handleDateChange}
                    required
                  >
                    <option>Seçiniz</option>
                    <option value={1}>Satın Alma Tarihi</option>
                    <option value={2}>İptale Tarihi</option>
                    <option value={3}>Vazgeçme Tarihi</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs="4">
                <Label>Filtre</Label>
                <Button type="submit" className="btn opacity register p-3">
                  Filtrele
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div>
        {plan == false ? (
          <Table striped>
            <thead>
              <tr>
                <th>ID</th>
                <th>EMAIL</th>
                <th>AD-SOYAD</th>
                <th>AYLIK SATIN ALMA DENEMESİ</th>
                <th>TARİH</th>
                <th>AYLIK İPTAL</th>
                <th>TARİH</th>
                <th>AYLIK VAZGEÇEN</th>
                <th>TARİH</th>
              </tr>
            </thead>
            <tbody>
              {subsTag.map((i) => {
                return (
                  <tr>
                    <td>{i.id}</td>
                    <td>{i.user_info.email}</td>
                    <td>
                      {i.user_info.isim} {i.user_info.soyad}
                    </td>
                    <td>
                      {i.monthly_subs_failure == false ? (
                        <span style={{ color: "green" }}>Başarılı</span>
                      ) : i.monthly_subs_failure == true ? (
                        <span style={{ color: "red" }}>Başarısız</span>
                      ) : (
                        "---"
                      )}
                    </td>
                    <td>
                      {i.monthly_subs_failure_date != null
                        ? new Date(i.monthly_subs_failure_date).toLocaleString()
                        : "---"}
                    </td>
                    <td>
                      {i.monthly_subs_canceled == true ? (
                        <span style={{ color: "red" }}>Evet</span>
                      ) : (
                        "---"
                      )}
                    </td>
                    <td>
                      {i.monthly_subs_canceled_date != null
                        ? new Date(
                            i.monthly_subs_canceled_date
                          ).toLocaleString()
                        : "---"}
                    </td>
                    <td>
                      {i.monthly_order_summary_givup == true ? (
                        <span style={{ color: "red" }}>Evet</span>
                      ) : (
                        "---"
                      )}
                    </td>
                    <td>
                      {i.monthly_order_summary_givup_date != null
                        ? new Date(
                            i.monthly_order_summary_givup_date
                          ).toLocaleString()
                        : "---"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <Table striped>
            <thead>
              <tr>
                <th>ID</th>
                <th>EMAIL</th>
                <th>AD-SOYAD</th>
                <th>YILLIK SATIN ALMA DENEMESİ</th>
                <th>TARİH</th>
                <th>YILLIK İPTAL</th>
                <th>TARİH</th>
                <th>YILLIK VAZGEÇEN</th>
                <th>TARİH</th>
              </tr>
            </thead>
            <tbody>
              {subsTag.map((i) => {
                return (
                  <tr>
                    <td>{i.id}</td>
                    <td>{i.user_info.email}</td>
                    <td>
                      {i.user_info.isim} {i.user_info.soyad}
                    </td>
                    <td>
                      {i.yearly_subs_failure == false ? (
                        <span style={{ color: "green" }}>Başarılı</span>
                      ) : i.yearly_subs_failure == true ? (
                        <span style={{ color: "red" }}>Başarısız</span>
                      ) : (
                        "---"
                      )}
                    </td>
                    <td>
                      {i.yearly_subs_failure_date != null
                        ? new Date(i.yearly_subs_failure_date).toLocaleString()
                        : "---"}
                    </td>
                    <td>
                      {i.yearly_subs_canceled == true ? (
                        <span style={{ color: "red" }}>Evet</span>
                      ) : (
                        "---"
                      )}
                    </td>
                    <td>
                      {i.yearly_subs_canceled_date != null
                        ? new Date(i.yearly_subs_canceled_date).toLocaleString()
                        : "---"}
                    </td>
                    <td>
                      {i.yearly_order_summary_givup == true ? (
                        <span style={{ color: "red" }}>Evet</span>
                      ) : (
                        "---"
                      )}
                    </td>
                    <td>
                      {i.yearly_order_summary_givup_date != null
                        ? new Date(
                            i.yearly_order_summary_givup_date
                          ).toLocaleString()
                        : "---"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>

      <Pagination page={page} setPage={setPage} />
    </div>
  );
}
