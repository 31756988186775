import React, { useState } from "react";

import { Input, Label, Form, Row, Col, Button, FormGroup } from "reactstrap";

export default function ChangeDb() {
  const [inputValues, setInputValues] = useState({
    udb: "",
    adb: "",
    bdb: "",
    gdb: "",
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleChangeDb = (e) => {
    e.preventDefault();
    setLoading(true);
    ///ROOT BULUNAMADI!!!
    // let changeDb = new UserProxy();
    // changeDb
    //   .changeDb(
    //     inputValues.udb,
    //     inputValues.adb,
    //     inputValues.bdb,
    //     inputValues.gdb
    //   )
    //   .then((res) => {
    //     setLoading(false);
    //     alert(res.data.message);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     alert(err.response.data.message);
    //   });
  };

  React.useEffect(() => { }, []);

  return (
    <div className="container pt-4">
      <h2 className="text-center text-danger">Change Database</h2>
      <div className="col-12 d-flex justify-content-center">
        <Form onSubmit={handleChangeDb}>
          <Row>
            <Col xs="6">
              <FormGroup>
                <Label for="udb">usadb</Label>
                <Input
                  type="select"
                  name="udb"
                  id="udb"
                  value={inputValues.udb}
                  onChange={handleInputChange}
                  required
                >
                  <option value={""} selected>
                    Seçiniz
                  </option>
                  <option value={"usadb1"}>usadb1</option>
                  <option value={"usadb2"}>usadb2</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Label for="adb">alfadb</Label>
                <Input
                  type="select"
                  name="adb"
                  id="adb"
                  value={inputValues.adb}
                  onChange={handleInputChange}
                  required
                >
                  <option value={""} selected>
                    Seçiniz
                  </option>
                  <option value={"alfadb1"}>alfadb1</option>
                  <option value={"alfadb2"}>alfadb2</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Label for="bdb">betadb</Label>
                <Input
                  type="select"
                  name="bdb"
                  id="bdb"
                  value={inputValues.bdb}
                  onChange={handleInputChange}
                  required
                >
                  <option value={""} selected>
                    Seçiniz
                  </option>
                  <option value={"betadb1"}>betadb1</option>
                  <option value={"betadb2"}>betadb2</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Label for="gdb">gamadb</Label>
                <Input
                  type="select"
                  name="gdb"
                  id="gdb"
                  value={inputValues.gdb}
                  onChange={handleInputChange}
                  required
                >
                  <option value={""} selected>
                    Seçiniz
                  </option>
                  <option value={"gamadb1"}>gamadb1</option>
                  <option value={"gamadb2"}>gamadb2</option>
                </Input>
              </FormGroup>
            </Col>

            <Col xs="12">
              <Button
                type="submit"
                className="btn opacity register mb-3 p-2"
                disabled={
                  !inputValues.udb ||
                  !inputValues.adb ||
                  !inputValues.bdb ||
                  !inputValues.gdb ||
                  loading === true
                }
              >
                Change
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {loading === true && (
        <div className="d-flex justify-content-center flex-column">
          <div className="w-100 d-flex justify-content-center">
            <img
              src="/spinner.gif"
              className=""
              alt="Loading"
              style={{ width: "100px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
