import AdminDashboardLayout from "../layouts/adminDashboard";
import Breadcrumb from "../components/shared/breadcrumb";
import React, { useState } from "react";

import IyzicoCurrentPlans from "../components/iyzicoCurrentPlans";
import { Row, Col, Container, Form } from "reactstrap";

export default function DiscountedPlans(props) {
  const [planNames, setPlanNames] = useState([]);
  const [planValues, setPlanValues] = useState({
    planName: "",
    share: null,
    webAylık: "",
    webYıllık: "",
    extAylık: "",
    extYıllık: "",
    fullAylık: "",
    fullYıllık: "",
    price1: null,
    price2: null,
    price3: null,
    price4: null,
    price5: null,
    price6: null,
  });

  const handleCreatePlan = (e) => {
    if (planNames.find((el) => el.plan_name === planValues.planName)) {
      e.preventDefault();
      return alert(
        `Plan adı "${planValues.planName}" daha önce kullanılmış. Lütfen farklı bir plan adı giriniz.`
      );
    }

    // const createPlan = new UserProxy();
    // createPlan.createPlan(
    //   planValues.planName,
    //   planValues.share,
    //   planValues.webAylık,
    //   planValues.webYıllık,
    //   planValues.extAylık,
    //   planValues.extYıllık,
    //   planValues.fullAylık,
    //   planValues.fullYıllık,
    //   planValues.price1,
    //   planValues.price2,
    //   planValues.price3,
    //   planValues.price4,
    //   planValues.price5,
    //   planValues.price6
    // );
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setPlanValues({ ...planValues, [name]: value });
  };

  // const getPlanNames = () => {
  //   const getPlanNamesForCreatePlan = new UserProxy();
  //   getPlanNamesForCreatePlan.getPlanNameForCreatePlan().then((res) => {
  //     setPlanNames(res.data);
  //   });
  // };

  // useEffect(() => {
  //   if (localStorage.getItem("token") != null) {
  //     props.checkAdminAccess();
  //     getPlanNames();
  //   }
  // }, []);

  return (
    <AdminDashboardLayout>
      <Breadcrumb title="Plan Ekle" />
      <Container fluid="lg">
        <Row>
          <Col xs="6">
            <Container fluid="lg" className="bg-light border">
              <h1 className="text-center">Kuponlar İçin Plan Ekle</h1>
              <Form onSubmit={handleCreatePlan}>
                <div className="form-group">
                  <Row>
                    <Col>
                      <label htmlFor="planName">PLAN ADI</label>
                      <input
                        value={planValues.planName}
                        onChange={handleChange}
                        type="text"
                        className="form-control form-control-sm"
                        id="planName"
                        name="planName"
                        placeholder="Enter plan name"
                      />
                    </Col>
                    <Col>
                      <label htmlFor="share">İNDİRİM ORANI</label>
                      <input
                        value={planValues.share}
                        onChange={handleChange}
                        type="number"
                        min="0"
                        max="1"
                        step="0.01"
                        className="form-control form-control-sm"
                        id="share"
                        name="share"
                        placeholder="Enter discount rate"
                      />
                    </Col>
                  </Row>
                </div>

                <div className="form-group">
                  <Row>
                    <Col>
                      <label htmlFor="webAylık">WEB AYLIK</label>
                      <input
                        value={planValues.webAylık}
                        onChange={handleChange}
                        type="text"
                        className="form-control form-control-sm"
                        id="webAylık"
                        name="webAylık"
                        placeholder="Enter plan reference from iyzico for web-monthly product"
                      />
                    </Col>
                    <Col>
                      <label htmlFor="price1">WEB AYLIK ÜCRETİ</label>
                      <input
                        value={planValues.price1}
                        onChange={handleChange}
                        type="number"
                        step="0.01"
                        className="form-control form-control-sm"
                        id="price1"
                        name="price1"
                        placeholder="Enter plan price for web-monthly product"
                      />
                    </Col>
                  </Row>
                </div>

                <div className="form-group">
                  <Row>
                    <Col>
                      <label htmlFor="webYıllık">WEB YILLIK</label>
                      <input
                        value={planValues.webYıllık}
                        onChange={handleChange}
                        type="text"
                        className="form-control form-control-sm"
                        id="webYıllık"
                        name="webYıllık"
                        placeholder="Enter plan reference from iyzico for web-annually product"
                      />
                    </Col>
                    <Col>
                      <label htmlFor="price2">WEB YILLIK ÜCRETİ</label>
                      <input
                        value={planValues.price2}
                        onChange={handleChange}
                        type="number"
                        step="0.01"
                        className="form-control form-control-sm"
                        id="price2"
                        name="price2"
                        placeholder="Enter plan price for web-annually product"
                      />
                    </Col>
                  </Row>
                </div>

                <div className="form-group">
                  <Row>
                    <Col>
                      <label htmlFor="extAylık">EXTENSION AYLIK</label>
                      <input
                        value={planValues.extAylık}
                        onChange={handleChange}
                        type="text"
                        className="form-control form-control-sm"
                        id="extAylık"
                        name="extAylık"
                        placeholder="Enter plan reference from iyzico for extension-monthly product"
                      />
                    </Col>
                    <Col>
                      <label htmlFor="price3">EXTENSION AYLIK ÜCRETİ</label>
                      <input
                        value={planValues.price3}
                        onChange={handleChange}
                        type="number"
                        step="0.01"
                        className="form-control form-control-sm"
                        id="price3"
                        name="price3"
                        placeholder="Enter plan price for extension-monthly product"
                      />
                    </Col>
                  </Row>
                </div>

                <div className="form-group">
                  <Row>
                    <Col>
                      <label htmlFor="extYıllık">EXTENSION YILLIK</label>
                      <input
                        value={planValues.extYıllık}
                        onChange={handleChange}
                        type="text"
                        className="form-control form-control-sm"
                        id="extYıllık"
                        name="extYıllık"
                        placeholder="Enter plan reference from iyzico for extension-annually product"
                      />
                    </Col>
                    <Col>
                      <label htmlFor="price4">EXTENSION YILLIK ÜCRETİ</label>
                      <input
                        value={planValues.price4}
                        onChange={handleChange}
                        type="number"
                        step="0.01"
                        className="form-control form-control-sm"
                        id="price4"
                        name="price4"
                        placeholder="Enter plan price for extension-annually product"
                      />
                    </Col>
                  </Row>
                </div>

                <div className="form-group">
                  <Row>
                    <Col>
                      <label htmlFor="fullAylık">FULL PAKET AYLIK</label>
                      <input
                        value={planValues.fullAylık}
                        onChange={handleChange}
                        type="text"
                        className="form-control form-control-sm"
                        id="fullAylık"
                        name="fullAylık"
                        placeholder="Enter plan reference from iyzico for full-monthly product"
                      />
                    </Col>
                    <Col>
                      <label htmlFor="price5">FULL PAKET AYLIK ÜCRETİ</label>
                      <input
                        value={planValues.price5}
                        onChange={handleChange}
                        type="number"
                        step="0.01"
                        className="form-control form-control-sm"
                        id="price5"
                        name="price5"
                        placeholder="Enter plan price for full-monthly product"
                      />
                    </Col>
                  </Row>
                </div>

                <div className="form-group">
                  <Row>
                    <Col>
                      <label htmlFor="fullYıllık">FULL PAKET YILLIK</label>
                      <input
                        value={planValues.fullYıllık}
                        onChange={handleChange}
                        type="text"
                        className="form-control form-control-sm"
                        id="fullYıllık"
                        name="fullYıllık"
                        placeholder="Enter plan reference from iyzico for full-annually product"
                      />
                    </Col>
                    <Col>
                      <label htmlFor="price6">FULL PAKET YILLIK Ücreti</label>
                      <input
                        value={planValues.price6}
                        onChange={handleChange}
                        type="number"
                        step="0.01"
                        className="form-control form-control-sm"
                        id="price6"
                        name="price6"
                        placeholder="Enter plan price for full-annually product"
                      />
                    </Col>
                  </Row>
                </div>

                <Row>
                  <Col className="text-center">
                    {" "}
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={
                        !planValues.planName ||
                        !planValues.share ||
                        !planValues.webAylık ||
                        !planValues.webAylık ||
                        !planValues.webYıllık ||
                        !planValues.extAylık ||
                        !planValues.extYıllık ||
                        !planValues.fullAylık ||
                        !planValues.fullYıllık ||
                        !planValues.price1 ||
                        !planValues.price2 ||
                        !planValues.price3 ||
                        !planValues.price4 ||
                        !planValues.price5 ||
                        !planValues.price6
                      }
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
              </Form>
              <br />
            </Container>
          </Col>
          <Col xs="6">
            <IyzicoCurrentPlans />
          </Col>
        </Row>
      </Container>
    </AdminDashboardLayout>
  );
}
